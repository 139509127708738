export class AxiosAbortControllerRegistry {
  private static abortControllers = new Map<string, AbortController>();

  static add(key: string, controller: AbortController) {
    this.abortControllers.set(key, controller);
  }

  static get(key: string) {
    return this.abortControllers.get(key);
  }

  static remove(key: string) {
    this.abortControllers.delete(key);
  }

  static abortAll() {
    this.abortControllers.forEach((controller) => {
      controller.abort();
    });
  }

  static clear() {
    this.abortControllers.clear();
  }
}
