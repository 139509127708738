import React from 'react';
import { useLocales } from '@rankacy/intl';
import { useLocation } from 'react-router';
import { BackgroundTasksIndicator } from './background-tasks-indicator';
import { useUploadDemos, useGetTmpDemos, useGetGeneratingHighlights } from './hooks';
import { BackgroundTaskItem } from './background-task-item';
import { fToNowWithoutPast } from '@rankacy/utils';
import { Typography } from '@mui/material';
import { RouterLink } from '@rankacy/routing';
import { paths } from '../../routes/paths';
import {
  AxiosAbortControllerRegistry,
  useDeleteHighlightApiHighlightsHighlightIdDeleteMutation,
  useDeleteUserMatchApiDemosDeletePostMutation,
} from '@rankacy/services';
import { useLeavePageConfirmation } from '@rankacy/hooks';

export const BackgroundTasks = () => {
  const { t } = useLocales();

  const location = useLocation();

  const { uploadingDemosWithProgress, isUploading, numberOfUploadingDemos, isSuccess } =
    useUploadDemos();

  const { processingDemos, processingDemosCount, queuedDemosCount, failedDemosCount, failedDemos } =
    useGetTmpDemos(isSuccess);

  useLeavePageConfirmation(isUploading);

  const [deleteMatch] = useDeleteUserMatchApiDemosDeletePostMutation();
  const [deleteGeneratingHighlight] = useDeleteHighlightApiHighlightsHighlightIdDeleteMutation();

  const { highlightsGenerating, highlightsGeneratingCount } = useGetGeneratingHighlights();

  const handleClearFailed = () => {
    failedDemos.forEach(({ id }) => {
      deleteMatch({ userMatchId: id });
    });
  };

  const handleAbortUploading = (hash: string) => {
    const abortController = AxiosAbortControllerRegistry.get(hash);

    if (abortController) {
      abortController.abort();
      AxiosAbortControllerRegistry.remove(hash);
    }
  };

  const renderUploading = isUploading ? (
    <>
      <Typography
        color="text.secondary"
        component={RouterLink}
        href={paths.dashboard.match.list}
        variant="caption"
      >
        {t('dem_upload')}
      </Typography>

      {React.Children.toArray(
        Object.entries(uploadingDemosWithProgress).map(([hash, { progress, name }]) => (
          <BackgroundTaskItem
            abortTask={() => handleAbortUploading(hash)}
            label={name}
            subLabel={t('percent_uploaded', { progress })}
            type="uploading"
          />
        ))
      )}
    </>
  ) : null;

  const renderProcessing = processingDemosCount ? (
    <>
      <Typography
        color="text.secondary"
        component={RouterLink}
        href={paths.dashboard.match.list}
        variant="caption"
      >
        {t('dem_processing')}
      </Typography>

      {React.Children.toArray(
        processingDemos.map(({ estimated_at, alias, id }) => (
          <BackgroundTaskItem
            abortTask={() => deleteMatch({ userMatchId: id })}
            label={alias}
            subLabel={fToNowWithoutPast(estimated_at, t('finishing'))}
            type="processing"
          />
        ))
      )}
    </>
  ) : null;

  const renderHighlightsGenerating = highlightsGeneratingCount ? (
    <>
      <Typography
        color="text.secondary"
        component={RouterLink}
        href={paths.dashboard.highlights.root}
        variant="caption"
      >
        {t('highlights_generation')}
      </Typography>

      {React.Children.toArray(
        highlightsGenerating.map(({ estimated_at, name, id }) => (
          <BackgroundTaskItem
            abortTask={() => deleteGeneratingHighlight({ highlightId: id })}
            label={name}
            subLabel={fToNowWithoutPast(estimated_at, `${t('finishing')} ...`)}
            type="processing"
          />
        ))
      )}
    </>
  ) : null;

  const renderTasksWithProgress =
    renderProcessing || renderHighlightsGenerating || renderUploading ? (
      <>
        {renderUploading}

        {renderProcessing}

        {renderHighlightsGenerating}
      </>
    ) : null;

  if (location.pathname.includes('highlights-editor')) {
    return null;
  }

  return (
    <BackgroundTasksIndicator
      activeBackgroundTasks={
        processingDemosCount +
        queuedDemosCount +
        highlightsGeneratingCount +
        failedDemosCount +
        numberOfUploadingDemos
      }
      failed={
        failedDemosCount
          ? { label: t('games_failed_count', { count: failedDemosCount }) }
          : undefined
      }
      queued={
        queuedDemosCount
          ? { label: t('games_queued_count', { count: queuedDemosCount }) }
          : undefined
      }
      tasksWithProgressComponent={renderTasksWithProgress}
      onClearFailed={handleClearFailed}
    />
  );
};
