import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { useBoolean } from '@rankacy/hooks';
import { CustomLink } from '@rankacy/routing';
import React from 'react';
import { paths } from '../../routes/paths';
import { Iconify } from '@rankacy/iconify';
import { useFeature } from '@rankacy/feature-flags';
import { useLocales } from '@rankacy/intl';
import { Trans } from 'react-i18next';

export const HighlightsIssueInfoDialog = () => {
  const { t } = useLocales();

  const highlightsIssues = useFeature('VITE_FEATURE_HIGHLIGHTS_ISSUES_DIALOG').on;

  const dialog = useBoolean(highlightsIssues);

  return (
    <Dialog disableEscapeKeyDown maxWidth="sm" open={dialog.value} onClose={dialog.onFalse}>
      <DialogContent
        sx={{
          background: `url('/assets/background/steam_connect_pro_dialog_overlay.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          py: { xs: 4, lg: 6 },
          px: { xs: 5, lg: 8 },
        }}
      >
        <Stack sx={{ gap: 2, justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <Iconify color="error.main" icon="mdi:tools" width={32} />

          <Typography color="text.primary" variant="h4">
            {t('highlights_issues')}
          </Typography>

          <Typography fontWeight="bold">
            <Trans
              components={{ span: <Typography component="span" fontWeight="normal" /> }}
              i18nKey="highlights_issues_desc"
            />
          </Typography>

          <Button color="error" size="large" variant="outlined" onClick={dialog.onFalse}>
            {t('understand')}
          </Button>

          <Typography color="text.secondary" component="span">
            {t('meanwhile_you_can')}{' '}
            <CustomLink
              isInsideText
              href={paths.dashboard.rankings.root}
              label={t('check_out_rankings')}
              labelSx={{
                fontWeight: 'bold',
                fontSize: ({ typography }) => typography.body1.fontSize,
              }}
              onClick={dialog.onFalse}
            />
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
