import { Dialog, DialogContent, Typography, IconButton, Button, Stack } from '@mui/material';
import { useBoolean, useLocalStorage } from '@rankacy/hooks';
import { useLocales } from '@rankacy/intl';
import { paths } from '../../routes/paths';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { Iconify } from '@rankacy/iconify';

export const GiveAwayDialog = () => {
  const { t } = useLocales();

  const [counter, setCounter] = useLocalStorage(GIVEAWAY_COUNTER_KEY, 0);

  const open = useBoolean(false);

  const navigate = useNavigate();

  const handleClick = () => {
    setCounter(MAX_GIVEAWAY_COUNTER);
    navigate(paths.register);
  };

  useEffect(() => {
    if (counter < MAX_GIVEAWAY_COUNTER) {
      open.onTrue();
      setCounter(counter + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open.value}
      sx={{
        '& .MuiDialog-paper': {
          border: 0,
        },
      }}
    >
      <DialogContent
        sx={{
          height: '100vh',
          minHeight: '312px',
          maxHeight: '600px',
          background: {
            xs: 'url(/assets/background/giveaway-bg-mobile.webp) bottom/cover no-repeat',
            sm: 'url(/assets/background/giveaway-bg.webp) top/cover no-repeat',
            lg: 'url(/assets/background/giveaway-bg.webp) center/cover no-repeat',
          },
          display: 'flex',
          alignItems: 'flex-end',
          pb: { xs: 3, md: 4 },
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={open.onFalse}
        >
          <Iconify icon="mdi:close" />
        </IconButton>

        <Stack sx={{ gap: { xs: 2, lg: 4 }, alignItems: 'flex-start' }}>
          <Typography
            sx={{ color: 'grey.800', fontWeight: 500, textTransform: 'none!important' }}
            variant="h1"
          >
            <Trans
              components={{
                br: <br />,
              }}
              i18nKey="giveaway_title"
            />
          </Typography>
          <Button size="large" variant="contained" onClick={handleClick}>
            {t('join_giveaway')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const GIVEAWAY_COUNTER_KEY = 'giveaway_counter';
const MAX_GIVEAWAY_COUNTER = 3;
