import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { Iconify } from '@rankacy/iconify';
import { m } from 'framer-motion';
import { getComparisonColor, getComparisonIcon } from './utils';
import { useLocales } from '@rankacy/intl';
import { pxToRem } from '@rankacy/theme';

type Props = {
  title: string;
  newScore: string | number;
  averageScore?: string | number;
  height?: number;
  comparison?: 'less' | 'bigger' | 'equal';
  renderEndComponent?: React.ReactNode;
  gapBetweenChartAndContent?: number;
};

export const MatchSingleStatisticWidget = ({
  title,
  newScore,
  averageScore,
  renderEndComponent,
  height,
  comparison,
  gapBetweenChartAndContent = 3,
}: Props) => {
  const { palette, typography } = useTheme();
  const { t } = useLocales();

  const comparisonIcon = getComparisonIcon(comparison);
  const comparisonColor = getComparisonColor(comparison);

  return (
    <Card
      animate={{ opacity: 1 }}
      component={m.div}
      initial={{ opacity: 0 }}
      sx={{ width: 1, height }}
      variant="outlined"
    >
      <CardContent
        sx={{
          height: 1,
          display: 'flex',
          gap: gapBetweenChartAndContent,
          justifyContent: 'space-between',
        }}
      >
        <Stack
          sx={{
            gap: pxToRem(12),
            minWidth: 'fit-content',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: palette.text.secondary,
              fontFamily: typography.h1.fontFamily,
            }}
          >
            {title}
          </Typography>

          <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 700,
                fontFamily: typography.h1.fontFamily,
              }}
            >
              {newScore}
            </Typography>

            {comparison && (
              <Iconify color={comparisonColor} flex="none" icon={comparisonIcon} width={24} />
            )}
          </Stack>

          {averageScore && (
            <Typography sx={{ color: palette.text.secondary, fontSize: 12 }}>
              {t('vs_average', { value: averageScore })}
            </Typography>
          )}
        </Stack>

        {renderEndComponent}
      </CardContent>
    </Card>
  );
};
