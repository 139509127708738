import { useMemo } from 'react';
import { paths } from '../../routes/paths';
import { useLocales } from '@rankacy/intl';
import { addItemToArrayConditionally } from '@rankacy/utils';
import { useFeature } from '@rankacy/feature-flags';
import { Iconify } from '@rankacy/iconify';
import { NavGroupProps, NavListProps } from '@rankacy/components';

export const icon = (name: string) => <Iconify icon={name} />;

export const ICONS = {
  dashboard: icon('mdi:view-dashboard'),
  automatic: icon('mdi:video'),
  matches: icon('mdi:sword-cross'),
  clan: icon('mdi:account-group'),
  challenges: icon('mdi:trophy'),
  rankings: icon('mdi:podium'),
  editor: icon('mdi:movie-edit'),
  strategyBoard: icon('mdi:view-dashboard'),
  rankacyMap: icon('mdi:steam'),
  feed: icon('mdi:home'),
};

export const useNavData = () => {
  const { t } = useLocales();

  const strategyBoardPageEnabled = useFeature('VITE_FEATURE_STRATEGY_BOARD').on;
  const socialsEnabled = useFeature('VITE_FEATURE_SOCIALS').on;
  const streetviewEnabled = useFeature('VITE_FEATURE_STREETVIEW').on;

  const config: NavGroupProps[] = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('general'),
        items: [
          {
            title: t('dashboard'),
            path: paths.dashboard.root,
            icon: ICONS.feed,
          },
          {
            title: t('matches'),
            path: paths.dashboard.match.root,
            icon: ICONS.matches,
          },

          {
            title: t('highlights_library'),
            path: paths.dashboard.highlights.root,
            icon: ICONS.automatic,
          },
          {
            title: t('highlights_creator'),
            path: paths.dashboard.highlightsEditor.root,
            icon: ICONS.editor,
          },

          ...addItemToArrayConditionally<NavListProps>(socialsEnabled, {
            title: t('socials'),
            path: paths.dashboard.socials.root,
            icon: ICONS.clan,
            children: [
              {
                title: t('account'),
                path: paths.dashboard.socials.account,
              },
            ],
          }),
        ],
      },
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('coaching'),
        items: [
          {
            title: t('rankings'),
            path: paths.dashboard.rankings.root,
            icon: ICONS.rankings,
          },
          {
            title: t('feed'),
            path: paths.dashboard.feed,
            icon: ICONS.dashboard,
          },
          ...addItemToArrayConditionally<NavListProps>(strategyBoardPageEnabled, {
            title: t('strategy_board'),
            path: paths.dashboard.board,
            icon: ICONS.strategyBoard,
          }),
          ...addItemToArrayConditionally<NavListProps>(streetviewEnabled, {
            title: t('streetview'),
            path: paths.dashboard.streetview,
            icon: ICONS.challenges,
          }),
        ],
      },
      {
        subheader: t('other'),
        items: [
          {
            title: t('rankacy_map'),
            path: 'https://steamcommunity.com/profiles/76561199653884492/myworkshopfiles/',
            icon: ICONS.rankacyMap,
          },
        ],
      },
    ],
    [t, strategyBoardPageEnabled, socialsEnabled, streetviewEnabled]
  );

  return config;
};
