import Link, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { Theme, SxProps } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { RouterLink } from './router-link';
import { LinkProps } from 'react-router-dom';

type Props = MuiLinkProps & {
  href: string;
  label: string;
  labelSx?: SxProps<Theme>;
  state?: LinkProps['state'];
  isInsideText?: boolean;
};

export const CustomLink = ({ href, label, labelSx, isInsideText = false, ...props }: Props) => (
  <Link color="primary.main" component={RouterLink} href={href} {...props}>
    <Typography
      component={isInsideText ? 'span' : 'div'}
      sx={{
        textAlign: 'left',
        fontWeight: 500,
        ...labelSx,
      }}
      variant="body2"
    >
      {label}
    </Typography>
  </Link>
);
