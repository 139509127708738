import React from 'react';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { TaskItem } from './styles';
import { CircularProgressWithDiscard } from '@rankacy/components';
import { Iconify } from '@rankacy/iconify';
import { t } from 'i18next';

type Props = {
  type: 'processing' | 'uploading' | 'failed' | 'queued';
  label: string;
  subLabel?: string;
  abortTask?: VoidFunction;
};

export const BackgroundTaskItem = ({ type, label, subLabel, abortTask }: Props) => {
  const renderEndElement =
    ((type === 'processing' || type === 'uploading') && (
      <CircularProgressWithDiscard color="inherit" onClick={abortTask} />
    )) ||
    (type === 'failed' && (
      <Tooltip arrow title={t('delete_all_failed')}>
        <IconButton
          color="error"
          sx={{
            p: 0,
            width: 30,
            height: 30,
          }}
          onClick={abortTask}
        >
          <Iconify icon="mdi:close" />
        </IconButton>
      </Tooltip>
    )) ||
    null;

  return (
    <TaskItem disabled={type === 'queued'} variant={(type === 'failed' && 'error') || 'info'}>
      <Stack
        sx={{
          flexDirection: 'column',
          alignItems: 'flext-start',
          overflow: 'hidden',
          flex: 1,
          mr: 1,
        }}
      >
        {subLabel && (
          <Typography noWrap variant="caption">
            {subLabel}
          </Typography>
        )}
        <Typography
          noWrap
          color={type === 'failed' ? 'grey.800' : 'text.secondary'}
          variant="body2"
        >
          {label}
        </Typography>
      </Stack>

      {renderEndElement}
    </TaskItem>
  );
};
