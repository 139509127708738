import React from 'react';
import { ChartWrapper } from './common/chart-wrapper';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { useTheme } from '@mui/material';

const START_ANGLE = 230;
const END_ANGLE = -40;

type Props = {
  height: number;
  label: string;
  percentage: number;
  average?: number;
};

export const CustomPiePercentageChart = ({ height, percentage, label, average }: Props) => {
  const { palette } = useTheme();

  const data = [
    {
      name: 'value',
      value: percentage,
      color: percentage > (average || 0) ? palette.primary.main : palette.error.main,
    },
    { name: 'rest', value: 100 - percentage, color: palette.grey[600] },
  ];

  const renderBackgroundLayer = (
    <Pie
      data={[{ value: 1 }]}
      dataKey="value"
      endAngle={END_ANGLE}
      innerRadius={50}
      outerRadius={60}
      startAngle={START_ANGLE - 10}
      stroke="none"
    >
      <Cell fill={palette.grey[600]} style={{ outline: 'none' }} />
    </Pie>
  );

  const renderPercentageLayer = (
    <Pie
      cornerRadius={10}
      data={data}
      dataKey="value"
      endAngle={END_ANGLE - 10}
      innerRadius={50}
      outerRadius={60}
      startAngle={START_ANGLE}
      stroke="none"
    >
      {data.map((entry, index) => (
        <Cell fill={entry.color} key={index} style={{ outline: 'none' }} />
      ))}

      <Label
        content={<CustomPiePercentageChartLabel label={label} progress={percentage} />}
        position="center"
      />
    </Pie>
  );

  const renderAverageLayer = average !== undefined && (
    <Pie
      data={[{ value: average }, { value: 1 }, { value: 100 - average - 1 }]}
      dataKey="value"
      endAngle={END_ANGLE}
      innerRadius={45}
      outerRadius={65}
      startAngle={START_ANGLE}
      stroke="none"
    >
      <Cell fill="transparent" style={{ outline: 'none' }} />
      <Cell fill={palette.grey[100]} style={{ outline: 'none' }} />
      <Cell fill="transparent" style={{ outline: 'none' }} />
    </Pie>
  );

  return (
    <ChartWrapper dataLength={2} height={height}>
      <PieChart>
        {renderBackgroundLayer}

        {renderPercentageLayer}

        {renderAverageLayer}
      </PieChart>
    </ChartWrapper>
  );
};

type LabelProps = {
  label: string;
  progress: number;
};

export const CustomPiePercentageChartLabel = ({ label, progress }: LabelProps) => {
  const { palette, typography } = useTheme();

  return (
    <>
      <text
        dominantBaseline="middle"
        dy={-18}
        fill={palette.text.secondary}
        style={{ fontSize: 14, color: palette.text.secondary, fontWeight: 500, userSelect: 'none' }}
        textAnchor="middle"
        x="50%"
        y="50%"
      >
        {label}
      </text>

      <text
        dominantBaseline="middle"
        dy={8}
        fill={palette.text.primary}
        style={{
          fontSize: 24,
          fontFamily: typography.h1.fontFamily,
          color: palette.text.primary,
          fontWeight: 700,
          userSelect: 'none',
        }}
        textAnchor="middle"
        x="50%"
        y="50%"
      >
        {`${progress}%`}
      </text>
    </>
  );
};
