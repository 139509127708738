import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetHighlightProcessingApiHighlightsProcessingGetQuery } from '@rankacy/services';

const _10_SECONDS = 10_000;

/**
 * useGetGeneratingHighlights hook manages the tracking of game highlights being generated.
 *
 * This hook uses polling to periodically check the status of game highlights generation.
 * It updates the state to reflect whether there are any highlights currently being generated
 * and invalidates relevant API cache if the count of generating highlights decreases.
 *
 * @returns {
 *   generatingCount: number - The count of highlights that are currently being generated.
 *   isDoneGenerating: boolean - Indicates if the highlights generation process is complete.
 *   remainingProcessingSeconds: number - The estimated time remaining for processing in seconds.
 * }
 */
export const useGetGeneratingHighlights = () => {
  const [checkingForChanges, setCheckingForChanges] = useState(false);

  const [lastCount, setLastCount] = useState(0);

  const dispatch = useDispatch();

  const { data } = useGetHighlightProcessingApiHighlightsProcessingGetQuery(undefined, {
    pollingInterval: checkingForChanges ? _10_SECONDS : undefined,
  });

  const highlightsGeneratedByUser = data?.results?.filter(({ is_premade }) => !is_premade) || [];

  const highlightsGeneratingCount = highlightsGeneratedByUser.length || 0;

  useEffect(() => {
    if (highlightsGeneratingCount > 0) {
      setCheckingForChanges(true);
    } else {
      setCheckingForChanges(false);
    }

    if (highlightsGeneratingCount < lastCount) {
      dispatch({
        type: `api/invalidateTags`,
        payload: ['highlights'],
      });
    }

    setLastCount(highlightsGeneratingCount);
  }, [highlightsGeneratingCount, lastCount, setCheckingForChanges, dispatch]);

  return {
    highlightsGeneratingCount,
    highlightsGenerating: highlightsGeneratedByUser,
    isDoneGenerating: highlightsGeneratingCount === 0,
  };
};
