import React, { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { Image } from '@rankacy/components';
import { pxToRem } from '@rankacy/theme';
import { useScroll, m, useTransform, useSpring } from 'framer-motion';

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
  scrollLeftOffset?: number;
  playerTopOffset?: number;
};

const PLAYER_IMAGE = '/assets/images/others/player.png';
const PLAYER_SIZE = 32;

export const PlayerScroll = ({
  containerRef,
  scrollLeftOffset = 40,
  playerTopOffset = 2,
}: Props) => {
  const { palette } = useTheme();
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    target: containerRef,
    offset: ['start center', 'end center'],
  });

  const smoothScrollYProgress = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 20,
    mass: 1,
  });

  const offsetY = useTransform(smoothScrollYProgress, [0, 1], [`${playerTopOffset}%`, '100%']);

  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < lastScrollY) {
        setIsScrollingUp(true);
      } else {
        setIsScrollingUp(false);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Box
      sx={{
        position: 'absolute',
        width: 2,
        left: scrollLeftOffset,
        height: 1,
        zIndex: -1,
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${palette.primary.main.replace(
          '#',
          '%23'
        )}' stroke-width='4' stroke-dasharray='6%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`,
      }}
    >
      <m.div
        animate={{
          rotate: isScrollingUp ? 180 : 0,
        }}
        style={{
          position: 'absolute',
          top: offsetY,
          left: -PLAYER_SIZE / 2 + 1,
          width: PLAYER_SIZE,
          height: PLAYER_SIZE,
          transformOrigin: 'center',
        }}
        transition={{
          type: 'spring',
          stiffness: 200,
          damping: 20,
        }}
      >
        <Image alt="Player" height={PLAYER_SIZE} src={PLAYER_IMAGE} width={PLAYER_SIZE} />
      </m.div>

      <Box
        sx={{
          position: 'absolute',
          bottom: isScrollingUp ? -29 : -20,
          left: '50%',
          transform: 'translateX(-50%)',
          width: pxToRem(17),
          height: pxToRem(17),
          borderRadius: '50%',
          bgcolor: 'primary.main',
        }}
      />
    </Box>
  );
};
