import { emptyApi as api } from './empty-api';
export const addTagTypes = [
  'demo-detail',
  'demos',
  'demo-detail-map-notes',
  'highlight-generation',
  'highlight-settings',
  'highlights',
  'clan-invitation',
  'clan-posts',
  'clan-user',
  'clan',
  'rankings',
  'user',
  'user-register',
  'user-password',
  'user-steam',
  'content-creator',
  'content-creator-supporter',
  'user-setting',
  'events',
  'events-dashboard',
  'events-discover',
  'system-admin-user',
  'system-admin-admin',
  'system-admin-ticket',
  'system-admin-highlight',
  'system-admin-demo',
  'system-admin-content-creator',
  'system-admin-content-creator-application',
  'system-admin-taxonomy',
  'system-admin-queue',
  'ticket',
  'user-helper',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getDemoHeaderApiGamesDemoIdHeaderGet: build.query<
        GetDemoHeaderApiGamesDemoIdHeaderGetApiResponse,
        GetDemoHeaderApiGamesDemoIdHeaderGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/games/${queryArg.demoId}/header`,
          params: { steam_id: queryArg.steamId, last_n_games: queryArg.lastNGames },
        }),
        providesTags: ['demo-detail'],
      }),
      getDemoDetailApiGamesDemoIdDetailGet: build.query<
        GetDemoDetailApiGamesDemoIdDetailGetApiResponse,
        GetDemoDetailApiGamesDemoIdDetailGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/games/${queryArg.demoId}/detail`,
          params: {
            steam_id: queryArg.steamId,
            map_name: queryArg.mapName,
            last_n_games: queryArg.lastNGames,
          },
        }),
        providesTags: ['demo-detail'],
      }),
      getDemoMyDeathApiGamesDemoIdDeathsGet: build.query<
        GetDemoMyDeathApiGamesDemoIdDeathsGetApiResponse,
        GetDemoMyDeathApiGamesDemoIdDeathsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/games/${queryArg.demoId}/deaths`,
          params: { steam_id: queryArg.steamId },
        }),
        providesTags: ['demo-detail'],
      }),
      getDemoDuelApiGamesDemoIdDuelsGet: build.query<
        GetDemoDuelApiGamesDemoIdDuelsGetApiResponse,
        GetDemoDuelApiGamesDemoIdDuelsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/games/${queryArg.demoId}/duels`,
          params: { steam_id: queryArg.steamId },
        }),
        providesTags: ['demo-detail'],
      }),
      getDemoPlayersApiGamesDemoIdPlayersGet: build.query<
        GetDemoPlayersApiGamesDemoIdPlayersGetApiResponse,
        GetDemoPlayersApiGamesDemoIdPlayersGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/games/${queryArg.demoId}/players` }),
        providesTags: ['demo-detail'],
      }),
      getDemoRoundApiGamesDemoIdRoundsGet: build.query<
        GetDemoRoundApiGamesDemoIdRoundsGetApiResponse,
        GetDemoRoundApiGamesDemoIdRoundsGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/games/${queryArg.demoId}/rounds` }),
        providesTags: ['demo-detail'],
      }),
      getDemoScoreboardApiGamesDemoIdScoreboardGet: build.query<
        GetDemoScoreboardApiGamesDemoIdScoreboardGetApiResponse,
        GetDemoScoreboardApiGamesDemoIdScoreboardGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/games/${queryArg.demoId}/scoreboard` }),
        providesTags: ['demo-detail'],
      }),
      getDemoPlayerRankingApiGamesRankingDemoIdGet: build.query<
        GetDemoPlayerRankingApiGamesRankingDemoIdGetApiResponse,
        GetDemoPlayerRankingApiGamesRankingDemoIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/games/ranking/${queryArg.demoId}`,
          params: { steam_id: queryArg.steamId },
        }),
        providesTags: ['demo-detail'],
      }),
      getDemoMapDetailApiGamesDemoIdMapGet: build.query<
        GetDemoMapDetailApiGamesDemoIdMapGetApiResponse,
        GetDemoMapDetailApiGamesDemoIdMapGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/games/${queryArg.demoId}/map` }),
        providesTags: ['demo-detail'],
      }),
      getDemoMapDetailRoundApiGamesDemoIdMapRoundNumberGet: build.query<
        GetDemoMapDetailRoundApiGamesDemoIdMapRoundNumberGetApiResponse,
        GetDemoMapDetailRoundApiGamesDemoIdMapRoundNumberGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/games/${queryArg.demoId}/map/${queryArg.roundNumber}` }),
        providesTags: ['demo-detail'],
      }),
      getDemoHashByDemoIdApiGamesDemoHashDemoIdGet: build.query<
        GetDemoHashByDemoIdApiGamesDemoHashDemoIdGetApiResponse,
        GetDemoHashByDemoIdApiGamesDemoHashDemoIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/games/demo-hash/${queryArg.demoId}/` }),
        providesTags: ['demo-detail'],
      }),
      getTutorialGamesApiGamesTutorialGamesGet: build.query<
        GetTutorialGamesApiGamesTutorialGamesGetApiResponse,
        GetTutorialGamesApiGamesTutorialGamesGetApiArg
      >({
        query: () => ({ url: `/api/games/tutorial-games` }),
        providesTags: ['demo-detail'],
      }),
      getUserMatchListApiDemosGet: build.query<
        GetUserMatchListApiDemosGetApiResponse,
        GetUserMatchListApiDemosGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/demos/`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            favorite: queryArg.favorite,
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
            my_only: queryArg.myOnly,
            map_names: queryArg.mapNames,
            mode: queryArg.mode,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
          },
        }),
        providesTags: ['demos'],
      }),
      changeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePut: build.mutation<
        ChangeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePutApiResponse,
        ChangeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/demos/${queryArg.userMatchId}/change-favorite`,
          method: 'PUT',
        }),
        invalidatesTags: ['demos'],
      }),
      deleteUserMatchApiDemosDeletePost: build.mutation<
        DeleteUserMatchApiDemosDeletePostApiResponse,
        DeleteUserMatchApiDemosDeletePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/demos/delete`,
          method: 'POST',
          params: { user_match_id: queryArg.userMatchId },
        }),
        invalidatesTags: ['demos'],
      }),
      getSteamAvatarsApiDemosGetSteamAvatarsGet: build.query<
        GetSteamAvatarsApiDemosGetSteamAvatarsGetApiResponse,
        GetSteamAvatarsApiDemosGetSteamAvatarsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/demos/get-steam-avatars`,
          params: { steam_ids: queryArg.steamIds },
        }),
        providesTags: ['demos'],
      }),
      userMatchCountApiDemosUserMatchCountGet: build.query<
        UserMatchCountApiDemosUserMatchCountGetApiResponse,
        UserMatchCountApiDemosUserMatchCountGetApiArg
      >({
        query: () => ({ url: `/api/demos/user-match-count` }),
        providesTags: ['demos'],
      }),
      getDemoByHashApiDemosDemoHashGet: build.query<
        GetDemoByHashApiDemosDemoHashGetApiResponse,
        GetDemoByHashApiDemosDemoHashGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/demos/demo/${queryArg.hash}`,
          params: { alias: queryArg.alias },
        }),
        providesTags: ['demos'],
      }),
      userDemoProcessingApiDemosProcessingGet: build.query<
        UserDemoProcessingApiDemosProcessingGetApiResponse,
        UserDemoProcessingApiDemosProcessingGetApiArg
      >({
        query: () => ({ url: `/api/demos/processing` }),
        providesTags: ['demos'],
      }),
      uploadDemosApiDemosUploadPost: build.mutation<
        UploadDemosApiDemosUploadPostApiResponse,
        UploadDemosApiDemosUploadPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/demos/upload`,
          method: 'POST',
          body: queryArg.bodyUploadDemosApiDemosUploadPost,
        }),
        invalidatesTags: ['demos'],
      }),
      addMapNotesApiGamesMapNotesAddDemoIdPost: build.mutation<
        AddMapNotesApiGamesMapNotesAddDemoIdPostApiResponse,
        AddMapNotesApiGamesMapNotesAddDemoIdPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/games/map_notes/add/${queryArg.demoId}`,
          method: 'POST',
          body: queryArg.bodyAddMapNotesApiGamesMapNotesAddDemoIdPost,
          params: { tick: queryArg.tick, note_: queryArg.note },
        }),
        invalidatesTags: ['demo-detail-map-notes'],
      }),
      getMapNotesApiGamesMapNotesDemoIdGet: build.query<
        GetMapNotesApiGamesMapNotesDemoIdGetApiResponse,
        GetMapNotesApiGamesMapNotesDemoIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/games/map_notes/${queryArg.demoId}`,
          params: { match_id: queryArg.matchId },
        }),
        providesTags: ['demo-detail-map-notes'],
      }),
      updateMapNotesApiGamesMapNotesNoteIdPut: build.mutation<
        UpdateMapNotesApiGamesMapNotesNoteIdPutApiResponse,
        UpdateMapNotesApiGamesMapNotesNoteIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/games/map_notes/${queryArg.noteId}`,
          method: 'PUT',
          body: queryArg.bodyUpdateMapNotesApiGamesMapNotesNoteIdPut,
          params: { note_: queryArg.note, tick: queryArg.tick, is_public: queryArg.isPublic },
        }),
        invalidatesTags: ['demo-detail-map-notes'],
      }),
      deleteMapNotesApiGamesMapNotesNoteIdDelete: build.mutation<
        DeleteMapNotesApiGamesMapNotesNoteIdDeleteApiResponse,
        DeleteMapNotesApiGamesMapNotesNoteIdDeleteApiArg
      >({
        query: (queryArg) => ({ url: `/api/games/map_notes/${queryArg.noteId}`, method: 'DELETE' }),
        invalidatesTags: ['demo-detail-map-notes'],
      }),
      generateAutomaticHighlightsApiHighlightsAutomaticGenerationDemoIdPost: build.mutation<
        GenerateAutomaticHighlightsApiHighlightsAutomaticGenerationDemoIdPostApiResponse,
        GenerateAutomaticHighlightsApiHighlightsAutomaticGenerationDemoIdPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/automatic-generation/${queryArg.demoId}`,
          method: 'POST',
        }),
        invalidatesTags: ['highlight-generation'],
      }),
      generateManualHighlightsApiHighlightsManualGenerationDemoIdPost: build.mutation<
        GenerateManualHighlightsApiHighlightsManualGenerationDemoIdPostApiResponse,
        GenerateManualHighlightsApiHighlightsManualGenerationDemoIdPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/manual-generation/${queryArg.demoId}`,
          method: 'POST',
          body: queryArg.manualHighlightRequest,
        }),
        invalidatesTags: ['highlight-generation'],
      }),
      getHighlightSettingsApiHighlightSettingsHighlightSettingsGet: build.query<
        GetHighlightSettingsApiHighlightSettingsHighlightSettingsGetApiResponse,
        GetHighlightSettingsApiHighlightSettingsHighlightSettingsGetApiArg
      >({
        query: () => ({ url: `/api/highlight-settings/highlight-settings` }),
        providesTags: ['highlight-settings'],
      }),
      updateHighlightSettingsApiHighlightSettingsHighlightSettingsPost: build.mutation<
        UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiResponse,
        UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlight-settings/highlight-settings`,
          method: 'POST',
          body: queryArg.highlightSettingModelResponse,
        }),
        invalidatesTags: ['highlight-settings'],
      }),
      getResolutionsApiHighlightSettingsResolutionsGet: build.query<
        GetResolutionsApiHighlightSettingsResolutionsGetApiResponse,
        GetResolutionsApiHighlightSettingsResolutionsGetApiArg
      >({
        query: () => ({ url: `/api/highlight-settings/resolutions` }),
        providesTags: ['highlight-settings'],
      }),
      getFrameRatesApiHighlightSettingsFrameRatesGet: build.query<
        GetFrameRatesApiHighlightSettingsFrameRatesGetApiResponse,
        GetFrameRatesApiHighlightSettingsFrameRatesGetApiArg
      >({
        query: () => ({ url: `/api/highlight-settings/frame-rates` }),
        providesTags: ['highlight-settings'],
      }),
      getVideoQualityApiHighlightSettingsVideoQualityGet: build.query<
        GetVideoQualityApiHighlightSettingsVideoQualityGetApiResponse,
        GetVideoQualityApiHighlightSettingsVideoQualityGetApiArg
      >({
        query: () => ({ url: `/api/highlight-settings/video-quality` }),
        providesTags: ['highlight-settings'],
      }),
      getHighlightEventApiHighlightsEventDemoIdGet: build.query<
        GetHighlightEventApiHighlightsEventDemoIdGetApiResponse,
        GetHighlightEventApiHighlightsEventDemoIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/highlights/event/${queryArg.demoId}` }),
        providesTags: ['highlights'],
      }),
      getHighlightsApiHighlightsListGet: build.query<
        GetHighlightsApiHighlightsListGetApiResponse,
        GetHighlightsApiHighlightsListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/list`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            map_names: queryArg.mapNames,
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
            name: queryArg.name,
          },
        }),
        providesTags: ['highlights'],
      }),
      getHighlightVideoApiHighlightsVideoStreamHighlightHashGet: build.query<
        GetHighlightVideoApiHighlightsVideoStreamHighlightHashGetApiResponse,
        GetHighlightVideoApiHighlightsVideoStreamHighlightHashGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/video-stream/${queryArg.highlightHash}`,
          headers: { Range: queryArg.range },
          params: { token: queryArg.token },
        }),
        providesTags: ['highlights'],
      }),
      getHighlightProcessingApiHighlightsProcessingGet: build.query<
        GetHighlightProcessingApiHighlightsProcessingGetApiResponse,
        GetHighlightProcessingApiHighlightsProcessingGetApiArg
      >({
        query: () => ({ url: `/api/highlights/processing` }),
        providesTags: ['highlights'],
      }),
      getHighlightListFeedApiHighlightsListFeedGet: build.query<
        GetHighlightListFeedApiHighlightsListFeedGetApiResponse,
        GetHighlightListFeedApiHighlightsListFeedGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/list-feed`,
          params: {
            page_size: queryArg.pageSize,
            first_datetime: queryArg.firstDatetime,
            last_datetime: queryArg.lastDatetime,
          },
        }),
        providesTags: ['highlights'],
      }),
      renameHighlightApiHighlightsRenameHighlightIdPut: build.mutation<
        RenameHighlightApiHighlightsRenameHighlightIdPutApiResponse,
        RenameHighlightApiHighlightsRenameHighlightIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/rename/${queryArg.highlightId}`,
          method: 'PUT',
          body: queryArg.highlightRenameRequest,
        }),
        invalidatesTags: ['highlights'],
      }),
      deleteHighlightApiHighlightsHighlightIdDelete: build.mutation<
        DeleteHighlightApiHighlightsHighlightIdDeleteApiResponse,
        DeleteHighlightApiHighlightsHighlightIdDeleteApiArg
      >({
        query: (queryArg) => ({ url: `/api/highlights/${queryArg.highlightId}`, method: 'DELETE' }),
        invalidatesTags: ['highlights'],
      }),
      downloadHighlightApiHighlightsDownloadHighlightIdGet: build.query<
        DownloadHighlightApiHighlightsDownloadHighlightIdGetApiResponse,
        DownloadHighlightApiHighlightsDownloadHighlightIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/highlights/download/${queryArg.highlightId}` }),
        providesTags: ['highlights'],
      }),
      likeHighlightApiHighlightsLikeHighlightIdPut: build.mutation<
        LikeHighlightApiHighlightsLikeHighlightIdPutApiResponse,
        LikeHighlightApiHighlightsLikeHighlightIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/like/${queryArg.highlightId}`,
          method: 'PUT',
        }),
        invalidatesTags: ['highlights'],
      }),
      getHighlightDetailApiHighlightsDetailHighlightHashGet: build.query<
        GetHighlightDetailApiHighlightsDetailHighlightHashGetApiResponse,
        GetHighlightDetailApiHighlightsDetailHighlightHashGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/highlights/detail/${queryArg.highlightHash}` }),
        providesTags: ['highlights'],
      }),
      createClanInvitationApiClansInvitationPost: build.mutation<
        CreateClanInvitationApiClansInvitationPostApiResponse,
        CreateClanInvitationApiClansInvitationPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/invitation`,
          method: 'POST',
          body: queryArg.clanInvitationCreateRequest,
        }),
        invalidatesTags: ['clan-invitation'],
      }),
      createClanInvitationsByClanApiClansClanInvitationsByClanPost: build.mutation<
        CreateClanInvitationsByClanApiClansClanInvitationsByClanPostApiResponse,
        CreateClanInvitationsByClanApiClansClanInvitationsByClanPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/clan-invitations/by-clan/`,
          method: 'POST',
          body: queryArg.clanInvitationCreateByClanRequest,
        }),
        invalidatesTags: ['clan-invitation'],
      }),
      acceptClanInvitationApiClansClanInvitationsInvitationIdAcceptPut: build.mutation<
        AcceptClanInvitationApiClansClanInvitationsInvitationIdAcceptPutApiResponse,
        AcceptClanInvitationApiClansClanInvitationsInvitationIdAcceptPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/clan-invitations/${queryArg.invitationId}/accept`,
          method: 'PUT',
        }),
        invalidatesTags: ['clan-invitation'],
      }),
      listClanInvitationsApiClansClanIdInvitationAllGet: build.query<
        ListClanInvitationsApiClansClanIdInvitationAllGetApiResponse,
        ListClanInvitationsApiClansClanIdInvitationAllGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/clans/${queryArg.clanId}/invitation/all` }),
        providesTags: ['clan-invitation'],
      }),
      acceptClanInvitationByClanApiClansInvitationIdInvitationAcceptByClanPut: build.mutation<
        AcceptClanInvitationByClanApiClansInvitationIdInvitationAcceptByClanPutApiResponse,
        AcceptClanInvitationByClanApiClansInvitationIdInvitationAcceptByClanPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.invitationId}/invitation/accept-by-clan`,
          method: 'PUT',
        }),
        invalidatesTags: ['clan-invitation'],
      }),
      rejectClanInvitationByClanApiClansInvitationIdInvitationRejectByClanPut: build.mutation<
        RejectClanInvitationByClanApiClansInvitationIdInvitationRejectByClanPutApiResponse,
        RejectClanInvitationByClanApiClansInvitationIdInvitationRejectByClanPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.invitationId}/invitation/reject-by-clan`,
          method: 'PUT',
        }),
        invalidatesTags: ['clan-invitation'],
      }),
      acceptClanInvitationByUserApiClansInvitationIdInvitationAcceptByUserPut: build.mutation<
        AcceptClanInvitationByUserApiClansInvitationIdInvitationAcceptByUserPutApiResponse,
        AcceptClanInvitationByUserApiClansInvitationIdInvitationAcceptByUserPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.invitationId}/invitation/accept-by-user`,
          method: 'PUT',
        }),
        invalidatesTags: ['clan-invitation'],
      }),
      rejectClanInvitationByUserApiClansInvitationIdInvitationRejectByUserPut: build.mutation<
        RejectClanInvitationByUserApiClansInvitationIdInvitationRejectByUserPutApiResponse,
        RejectClanInvitationByUserApiClansInvitationIdInvitationRejectByUserPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.invitationId}/invitation/reject-by-user`,
          method: 'PUT',
        }),
        invalidatesTags: ['clan-invitation'],
      }),
      checkClanInvitationExistsApiClansClanInvitationsExistsClanIdGet: build.query<
        CheckClanInvitationExistsApiClansClanInvitationsExistsClanIdGetApiResponse,
        CheckClanInvitationExistsApiClansClanInvitationsExistsClanIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/clans/clan-invitations/exists/${queryArg.clanId}` }),
        providesTags: ['clan-invitation'],
      }),
      createClanPostApiClansClanIdPostPost: build.mutation<
        CreateClanPostApiClansClanIdPostPostApiResponse,
        CreateClanPostApiClansClanIdPostPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.clanId}/post/`,
          method: 'POST',
          body: queryArg.clanPostCreateRequest,
        }),
        invalidatesTags: ['clan-posts'],
      }),
      listClanPostsApiClansClanIdPostAllGet: build.query<
        ListClanPostsApiClansClanIdPostAllGetApiResponse,
        ListClanPostsApiClansClanIdPostAllGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/clans/${queryArg.clanId}/post/all` }),
        providesTags: ['clan-posts'],
      }),
      isAnyClanLeaderApiClansIsAnyClanLeaderGet: build.query<
        IsAnyClanLeaderApiClansIsAnyClanLeaderGetApiResponse,
        IsAnyClanLeaderApiClansIsAnyClanLeaderGetApiArg
      >({
        query: () => ({ url: `/api/clans/is-any-clan-leader` }),
        providesTags: ['clan-user'],
      }),
      getClanRoleApiClansClanIdClanRoleGet: build.query<
        GetClanRoleApiClansClanIdClanRoleGetApiResponse,
        GetClanRoleApiClansClanIdClanRoleGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/clans/${queryArg.clanId}/clan-role` }),
        providesTags: ['clan-user'],
      }),
      listClanMembersApiClansClanIdMemberListGet: build.query<
        ListClanMembersApiClansClanIdMemberListGetApiResponse,
        ListClanMembersApiClansClanIdMemberListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.clanId}/member-list`,
          params: { username: queryArg.username },
        }),
        providesTags: ['clan-user'],
      }),
      getClanUserMemberListApiClansIdMemberListPrivateGet: build.query<
        GetClanUserMemberListApiClansIdMemberListPrivateGetApiResponse,
        GetClanUserMemberListApiClansIdMemberListPrivateGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.id_}/member-list/private`,
          params: {
            id: queryArg.id,
            username: queryArg.username,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
          },
        }),
        providesTags: ['clan-user'],
      }),
      getClanMemberApiClansClanIdClanUserMemberIdGet: build.query<
        GetClanMemberApiClansClanIdClanUserMemberIdGetApiResponse,
        GetClanMemberApiClansClanIdClanUserMemberIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.clanId}/clan-user/${queryArg.memberId}`,
        }),
        providesTags: ['clan-user'],
      }),
      updateClanMemberApiClansClanIdClanUserMemberIdPut: build.mutation<
        UpdateClanMemberApiClansClanIdClanUserMemberIdPutApiResponse,
        UpdateClanMemberApiClansClanIdClanUserMemberIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.clanId}/clan-user/${queryArg.memberId}`,
          method: 'PUT',
          params: { clan_user_role_update: queryArg.clanUserRoleUpdate },
        }),
        invalidatesTags: ['clan-user'],
      }),
      removeClanMemberApiClansClanIdClanUserMemberIdDelete: build.mutation<
        RemoveClanMemberApiClansClanIdClanUserMemberIdDeleteApiResponse,
        RemoveClanMemberApiClansClanIdClanUserMemberIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.clanId}/clan-user/${queryArg.memberId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['clan-user'],
      }),
      listClansApiClansAllGet: build.query<
        ListClansApiClansAllGetApiResponse,
        ListClansApiClansAllGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/all`,
          params: { name: queryArg.name, page: queryArg.page, page_size: queryArg.pageSize },
        }),
        providesTags: ['clan'],
      }),
      listUserClansApiClansUserAllGet: build.query<
        ListUserClansApiClansUserAllGetApiResponse,
        ListUserClansApiClansUserAllGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/user/all`,
          params: { page: queryArg.page, page_size: queryArg.pageSize },
        }),
        providesTags: ['clan'],
      }),
      createClanApiClansPost: build.mutation<
        CreateClanApiClansPostApiResponse,
        CreateClanApiClansPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans`,
          method: 'POST',
          body: queryArg.bodyCreateClanApiClansPost,
          params: { name: queryArg.name, description: queryArg.description },
        }),
        invalidatesTags: ['clan'],
      }),
      getClanApiClansClanIdGet: build.query<
        GetClanApiClansClanIdGetApiResponse,
        GetClanApiClansClanIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/clans/${queryArg.clanId}` }),
        providesTags: ['clan'],
      }),
      updateClanApiClansClanIdPut: build.mutation<
        UpdateClanApiClansClanIdPutApiResponse,
        UpdateClanApiClansClanIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/clans/${queryArg.clanId}`,
          method: 'PUT',
          body: queryArg.bodyUpdateClanApiClansClanIdPut,
          params: { name: queryArg.name, description: queryArg.description },
        }),
        invalidatesTags: ['clan'],
      }),
      getUserRankingApiRankingsUserGet: build.query<
        GetUserRankingApiRankingsUserGetApiResponse,
        GetUserRankingApiRankingsUserGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/rankings/user`,
          params: {
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
            under_six: queryArg.underSix,
            all_time: queryArg.allTime,
          },
        }),
        providesTags: ['rankings'],
      }),
      getRankingListApiRankingsListGet: build.query<
        GetRankingListApiRankingsListGetApiResponse,
        GetRankingListApiRankingsListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/rankings/list`,
          params: {
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
            username: queryArg.username,
            countries: queryArg.countries,
            page: queryArg.page,
            page_size: queryArg.pageSize,
          },
        }),
        providesTags: ['rankings'],
      }),
      getRankingAllTimeListApiRankingsListAllTimeGet: build.query<
        GetRankingAllTimeListApiRankingsListAllTimeGetApiResponse,
        GetRankingAllTimeListApiRankingsListAllTimeGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/rankings/list/all-time`,
          params: {
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
            username: queryArg.username,
            countries: queryArg.countries,
            page: queryArg.page,
            page_size: queryArg.pageSize,
          },
        }),
        providesTags: ['rankings'],
      }),
      getRankingListUnderSixMatchesApiRankingsListUnderSixGet: build.query<
        GetRankingListUnderSixMatchesApiRankingsListUnderSixGetApiResponse,
        GetRankingListUnderSixMatchesApiRankingsListUnderSixGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/rankings/list-under-six`,
          params: {
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
            username: queryArg.username,
            countries: queryArg.countries,
            page: queryArg.page,
            page_size: queryArg.pageSize,
          },
        }),
        providesTags: ['rankings'],
      }),
      getRankingUserCountApiRankingsUserCountGet: build.query<
        GetRankingUserCountApiRankingsUserCountGetApiResponse,
        GetRankingUserCountApiRankingsUserCountGetApiArg
      >({
        query: () => ({ url: `/api/rankings/user-count` }),
        providesTags: ['rankings'],
      }),
      refreshTokenApiUserTokenRefreshPost: build.mutation<
        RefreshTokenApiUserTokenRefreshPostApiResponse,
        RefreshTokenApiUserTokenRefreshPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/token/refresh`,
          method: 'POST',
          body: queryArg.tokenRefreshRequest,
        }),
        invalidatesTags: ['user'],
      }),
      loginForAccessTokenApiUserTokenPost: build.mutation<
        LoginForAccessTokenApiUserTokenPostApiResponse,
        LoginForAccessTokenApiUserTokenPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/token`,
          method: 'POST',
          body: queryArg.bodyLoginForAccessTokenApiUserTokenPost,
        }),
        invalidatesTags: ['user'],
      }),
      loginForAdminAccessTokenApiUserTokenAdminPost: build.mutation<
        LoginForAdminAccessTokenApiUserTokenAdminPostApiResponse,
        LoginForAdminAccessTokenApiUserTokenAdminPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/token/admin`,
          method: 'POST',
          body: queryArg.bodyLoginForAdminAccessTokenApiUserTokenAdminPost,
        }),
        invalidatesTags: ['user'],
      }),
      logoutApiUserLogoutPost: build.mutation<
        LogoutApiUserLogoutPostApiResponse,
        LogoutApiUserLogoutPostApiArg
      >({
        query: () => ({ url: `/api/user/logout`, method: 'POST' }),
        invalidatesTags: ['user'],
      }),
      adminLogoutApiUserAdminLogoutPost: build.mutation<
        AdminLogoutApiUserAdminLogoutPostApiResponse,
        AdminLogoutApiUserAdminLogoutPostApiArg
      >({
        query: () => ({ url: `/api/user/admin-logout`, method: 'POST' }),
        invalidatesTags: ['user'],
      }),
      firstLoginApiUserFirstLoginPost: build.mutation<
        FirstLoginApiUserFirstLoginPostApiResponse,
        FirstLoginApiUserFirstLoginPostApiArg
      >({
        query: () => ({ url: `/api/user/first-login`, method: 'POST' }),
        invalidatesTags: ['user'],
      }),
      readCurrentUserApiUserDetailGet: build.query<
        ReadCurrentUserApiUserDetailGetApiResponse,
        ReadCurrentUserApiUserDetailGetApiArg
      >({
        query: () => ({ url: `/api/user/detail` }),
        providesTags: ['user'],
      }),
      uploadAvatarApiUserUpdateAvatarPut: build.mutation<
        UploadAvatarApiUserUpdateAvatarPutApiResponse,
        UploadAvatarApiUserUpdateAvatarPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/update/avatar`,
          method: 'PUT',
          body: queryArg.bodyUploadAvatarApiUserUpdateAvatarPut,
        }),
        invalidatesTags: ['user'],
      }),
      listClanUsersApiUserListClanClanIdGet: build.query<
        ListClanUsersApiUserListClanClanIdGetApiResponse,
        ListClanUsersApiUserListClanClanIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/list/clan/${queryArg.clanId}`,
          params: { search: queryArg.search },
        }),
        providesTags: ['user'],
      }),
      listUsersApiUserListGet: build.query<
        ListUsersApiUserListGetApiResponse,
        ListUsersApiUserListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/list`,
          params: { search: queryArg.search, page: queryArg.page, page_size: queryArg.pageSize },
        }),
        providesTags: ['user'],
      }),
      updateUserSettingsApiUserChangeSettingsPut: build.mutation<
        UpdateUserSettingsApiUserChangeSettingsPutApiResponse,
        UpdateUserSettingsApiUserChangeSettingsPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/change-settings`,
          method: 'PUT',
          body: queryArg.userUpdateRequest,
        }),
        invalidatesTags: ['user'],
      }),
      getUserDashboardApiUserDashboardGet: build.query<
        GetUserDashboardApiUserDashboardGetApiResponse,
        GetUserDashboardApiUserDashboardGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/dashboard`,
          params: { steam_id: queryArg.steamId, last_n_games: queryArg.lastNGames },
        }),
        providesTags: ['user'],
      }),
      getUserMapStatsApiUserMapStatsGet: build.query<
        GetUserMapStatsApiUserMapStatsGetApiResponse,
        GetUserMapStatsApiUserMapStatsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/map-stats`,
          params: { steam_id: queryArg.steamId },
        }),
        providesTags: ['user'],
      }),
      validateUsernameApiUserUsernameValidationPost: build.mutation<
        ValidateUsernameApiUserUsernameValidationPostApiResponse,
        ValidateUsernameApiUserUsernameValidationPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/username-validation/`,
          method: 'POST',
          params: { username: queryArg.username },
        }),
        invalidatesTags: ['user-register'],
      }),
      validateEmailApiUserEmailValidationPost: build.mutation<
        ValidateEmailApiUserEmailValidationPostApiResponse,
        ValidateEmailApiUserEmailValidationPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/email-validation/`,
          method: 'POST',
          params: { email: queryArg.email },
        }),
        invalidatesTags: ['user-register'],
      }),
      registerUserApiUserRegisterPost: build.mutation<
        RegisterUserApiUserRegisterPostApiResponse,
        RegisterUserApiUserRegisterPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/register/`,
          method: 'POST',
          body: queryArg.userRegisterRequest,
        }),
        invalidatesTags: ['user-register'],
      }),
      confirmEmailApiUserConfirmRegistrationPost: build.mutation<
        ConfirmEmailApiUserConfirmRegistrationPostApiResponse,
        ConfirmEmailApiUserConfirmRegistrationPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/confirm-registration/`,
          method: 'POST',
          body: queryArg.confirmEmailRequest,
        }),
        invalidatesTags: ['user-register'],
      }),
      resendConfirmEmailApiUserResendConfirmRegistrationPost: build.mutation<
        ResendConfirmEmailApiUserResendConfirmRegistrationPostApiResponse,
        ResendConfirmEmailApiUserResendConfirmRegistrationPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/resend-confirm-registration/`,
          method: 'POST',
          body: queryArg.resendConfirmEmailRequest,
        }),
        invalidatesTags: ['user-register'],
      }),
      changePasswordApiUserChangePasswordPut: build.mutation<
        ChangePasswordApiUserChangePasswordPutApiResponse,
        ChangePasswordApiUserChangePasswordPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/change-password`,
          method: 'PUT',
          body: queryArg.changePasswordRequest,
        }),
        invalidatesTags: ['user-password'],
      }),
      resetPasswordRequestApiUserResetPasswordRequestPost: build.mutation<
        ResetPasswordRequestApiUserResetPasswordRequestPostApiResponse,
        ResetPasswordRequestApiUserResetPasswordRequestPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/reset-password-request`,
          method: 'POST',
          body: queryArg.resetPasswordRequest,
        }),
        invalidatesTags: ['user-password'],
      }),
      resetPasswordApiUserResetPasswordPut: build.mutation<
        ResetPasswordApiUserResetPasswordPutApiResponse,
        ResetPasswordApiUserResetPasswordPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/reset-password`,
          method: 'PUT',
          body: queryArg.setNewPasswordRequest,
        }),
        invalidatesTags: ['user-password'],
      }),
      userSteamLoginApiUserSteamLoginPost: build.mutation<
        UserSteamLoginApiUserSteamLoginPostApiResponse,
        UserSteamLoginApiUserSteamLoginPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/steam-login`,
          method: 'POST',
          body: queryArg.steamLoginDataRequest,
        }),
        invalidatesTags: ['user-steam'],
      }),
      userSteamConnectApiUserSteamConnectPost: build.mutation<
        UserSteamConnectApiUserSteamConnectPostApiResponse,
        UserSteamConnectApiUserSteamConnectPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/steam-connect`,
          method: 'POST',
          body: queryArg.steamLoginDataRequest,
        }),
        invalidatesTags: ['user-steam'],
      }),
      userSteamDisconnectApiUserSteamDisconnectPut: build.mutation<
        UserSteamDisconnectApiUserSteamDisconnectPutApiResponse,
        UserSteamDisconnectApiUserSteamDisconnectPutApiArg
      >({
        query: () => ({ url: `/api/user/steam-disconnect`, method: 'PUT' }),
        invalidatesTags: ['user-steam'],
      }),
      userFaceitDisconnectApiUserFaceitDisconnectPut: build.mutation<
        UserFaceitDisconnectApiUserFaceitDisconnectPutApiResponse,
        UserFaceitDisconnectApiUserFaceitDisconnectPutApiArg
      >({
        query: () => ({ url: `/api/user/faceit-disconnect`, method: 'PUT' }),
        invalidatesTags: ['user-steam'],
      }),
      updateSteamCodeApiUserSteamUpdateGamecodePut: build.mutation<
        UpdateSteamCodeApiUserSteamUpdateGamecodePutApiResponse,
        UpdateSteamCodeApiUserSteamUpdateGamecodePutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/steam/update-gamecode`,
          method: 'PUT',
          body: queryArg.steamCodeUpdateRequest,
        }),
        invalidatesTags: ['user-steam'],
      }),
      getSteamCodeApiUserSteamGetCodesGet: build.query<
        GetSteamCodeApiUserSteamGetCodesGetApiResponse,
        GetSteamCodeApiUserSteamGetCodesGetApiArg
      >({
        query: () => ({ url: `/api/user/steam/get-codes` }),
        providesTags: ['user-steam'],
      }),
      refreshSteamDataApiUserSteamRefreshPut: build.mutation<
        RefreshSteamDataApiUserSteamRefreshPutApiResponse,
        RefreshSteamDataApiUserSteamRefreshPutApiArg
      >({
        query: () => ({ url: `/api/user/steam/refresh`, method: 'PUT' }),
        invalidatesTags: ['user-steam'],
      }),
      createContentCreatorApiContentCreatorCreatePost: build.mutation<
        CreateContentCreatorApiContentCreatorCreatePostApiResponse,
        CreateContentCreatorApiContentCreatorCreatePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/content-creator/create`,
          method: 'POST',
          body: queryArg.userContentCreatorCreateUpdateRequest,
        }),
        invalidatesTags: ['content-creator'],
      }),
      getContentCreatorPlatformsApiContentCreatorPlatformsGet: build.query<
        GetContentCreatorPlatformsApiContentCreatorPlatformsGetApiResponse,
        GetContentCreatorPlatformsApiContentCreatorPlatformsGetApiArg
      >({
        query: () => ({ url: `/api/content-creator/platforms` }),
        providesTags: ['content-creator'],
      }),
      createContentCreatorPlatformApiContentCreatorPlatformsPost: build.mutation<
        CreateContentCreatorPlatformApiContentCreatorPlatformsPostApiResponse,
        CreateContentCreatorPlatformApiContentCreatorPlatformsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/content-creator/platforms`,
          method: 'POST',
          body: queryArg.userContentCreatorPlatformCreateRequest,
        }),
        invalidatesTags: ['content-creator'],
      }),
      updateContentCreatorPlatformApiContentCreatorPlatformsPlatformIdPut: build.mutation<
        UpdateContentCreatorPlatformApiContentCreatorPlatformsPlatformIdPutApiResponse,
        UpdateContentCreatorPlatformApiContentCreatorPlatformsPlatformIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/content-creator/platforms/${queryArg.platformId}`,
          method: 'PUT',
          body: queryArg.userContentCreatorPlatformCreateRequest,
        }),
        invalidatesTags: ['content-creator'],
      }),
      deleteContentCreatorPlatformApiContentCreatorPlatformsPlatformIdDelete: build.mutation<
        DeleteContentCreatorPlatformApiContentCreatorPlatformsPlatformIdDeleteApiResponse,
        DeleteContentCreatorPlatformApiContentCreatorPlatformsPlatformIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/content-creator/platforms/${queryArg.platformId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['content-creator'],
      }),
      getContentCreatorApiContentCreatorDetailGet: build.query<
        GetContentCreatorApiContentCreatorDetailGetApiResponse,
        GetContentCreatorApiContentCreatorDetailGetApiArg
      >({
        query: () => ({ url: `/api/content-creator/detail` }),
        providesTags: ['content-creator'],
      }),
      updateContentCreatorApiContentCreatorUpdatePut: build.mutation<
        UpdateContentCreatorApiContentCreatorUpdatePutApiResponse,
        UpdateContentCreatorApiContentCreatorUpdatePutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/content-creator/update`,
          method: 'PUT',
          body: queryArg.bodyUpdateContentCreatorApiContentCreatorUpdatePut,
          params: {
            name: queryArg.name,
            surname: queryArg.surname,
            birth_date: queryArg.birthDate,
            message: queryArg.message,
            code: queryArg.code,
          },
        }),
        invalidatesTags: ['content-creator'],
      }),
      createContentCreatorSupporterApiContentCreatorSupportCodePost: build.mutation<
        CreateContentCreatorSupporterApiContentCreatorSupportCodePostApiResponse,
        CreateContentCreatorSupporterApiContentCreatorSupportCodePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/content-creator/support/${queryArg.code}`,
          method: 'POST',
        }),
        invalidatesTags: ['content-creator-supporter'],
      }),
      listContentCreatorSupportersApiContentCreatorSupportersGet: build.query<
        ListContentCreatorSupportersApiContentCreatorSupportersGetApiResponse,
        ListContentCreatorSupportersApiContentCreatorSupportersGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/content-creator/supporters`,
          params: { history: queryArg.history, page: queryArg.page, page_size: queryArg.pageSize },
        }),
        providesTags: ['content-creator-supporter'],
      }),
      removeContentCreatorSupportApiContentCreatorRemoveSupportPut: build.mutation<
        RemoveContentCreatorSupportApiContentCreatorRemoveSupportPutApiResponse,
        RemoveContentCreatorSupportApiContentCreatorRemoveSupportPutApiArg
      >({
        query: () => ({ url: `/api/content-creator/remove-support`, method: 'PUT' }),
        invalidatesTags: ['content-creator-supporter'],
      }),
      getSupportedContentCreatorApiContentCreatorSupportedContentCreatorGet: build.query<
        GetSupportedContentCreatorApiContentCreatorSupportedContentCreatorGetApiResponse,
        GetSupportedContentCreatorApiContentCreatorSupportedContentCreatorGetApiArg
      >({
        query: () => ({ url: `/api/content-creator/supported-content-creator` }),
        providesTags: ['content-creator-supporter'],
      }),
      getSettingsApiUserSettingSettingsGet: build.query<
        GetSettingsApiUserSettingSettingsGetApiResponse,
        GetSettingsApiUserSettingSettingsGetApiArg
      >({
        query: () => ({ url: `/api/user-setting/settings` }),
        providesTags: ['user-setting'],
      }),
      updateSettingsApiUserSettingSettingsPut: build.mutation<
        UpdateSettingsApiUserSettingSettingsPutApiResponse,
        UpdateSettingsApiUserSettingSettingsPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-setting/settings`,
          method: 'PUT',
          body: queryArg.userSettingsUpdateRequest,
        }),
        invalidatesTags: ['user-setting'],
      }),
      createEventEventsCreatePost: build.mutation<
        CreateEventEventsCreatePostApiResponse,
        CreateEventEventsCreatePostApiArg
      >({
        query: (queryArg) => ({
          url: `/events/create/`,
          method: 'POST',
          body: queryArg.eventCreateRequest,
        }),
        invalidatesTags: ['events'],
      }),
      getEventEventsEventIdGet: build.query<
        GetEventEventsEventIdGetApiResponse,
        GetEventEventsEventIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/events/${queryArg.eventId}` }),
        providesTags: ['events'],
      }),
      updateEventEventsEventsEventIdPut: build.mutation<
        UpdateEventEventsEventsEventIdPutApiResponse,
        UpdateEventEventsEventsEventIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/events/events/${queryArg.eventId}`,
          method: 'PUT',
          body: queryArg.eventUpdateRequest,
        }),
        invalidatesTags: ['events'],
      }),
      deleteEventEventsEventsEventIdDelete: build.mutation<
        DeleteEventEventsEventsEventIdDeleteApiResponse,
        DeleteEventEventsEventsEventIdDeleteApiArg
      >({
        query: (queryArg) => ({ url: `/events/events/${queryArg.eventId}`, method: 'DELETE' }),
        invalidatesTags: ['events'],
      }),
      getCalendarEventsDashboardCalendarGet: build.query<
        GetCalendarEventsDashboardCalendarGetApiResponse,
        GetCalendarEventsDashboardCalendarGetApiArg
      >({
        query: (queryArg) => ({
          url: `/events/dashboard/calendar`,
          params: { start_date: queryArg.startDate, end_date: queryArg.endDate },
        }),
        providesTags: ['events-dashboard'],
      }),
      getEventInvitationsEventsDashboardInvitationsEventGet: build.query<
        GetEventInvitationsEventsDashboardInvitationsEventGetApiResponse,
        GetEventInvitationsEventsDashboardInvitationsEventGetApiArg
      >({
        query: () => ({ url: `/events/dashboard/invitations-event` }),
        providesTags: ['events-dashboard'],
      }),
      getUpcomingEventsEventsDashboardUpcomingEventGet: build.query<
        GetUpcomingEventsEventsDashboardUpcomingEventGetApiResponse,
        GetUpcomingEventsEventsDashboardUpcomingEventGetApiArg
      >({
        query: (queryArg) => ({
          url: `/events/dashboard/upcoming-event`,
          params: { date_: queryArg.date },
        }),
        providesTags: ['events-dashboard'],
      }),
      createEventUserApiEventsUserCreateUpdatePost: build.mutation<
        CreateEventUserApiEventsUserCreateUpdatePostApiResponse,
        CreateEventUserApiEventsUserCreateUpdatePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/events/user/create-update`,
          method: 'POST',
          body: queryArg.eventUserCreateUpdateRequest,
        }),
        invalidatesTags: ['events'],
      }),
      discoverEventsEventsDiscoverDiscoverGet: build.query<
        DiscoverEventsEventsDiscoverDiscoverGetApiResponse,
        DiscoverEventsEventsDiscoverDiscoverGetApiArg
      >({
        query: (queryArg) => ({
          url: `/events/discover/discover`,
          params: {
            available: queryArg.available,
            clan: queryArg.clan,
            todays: queryArg.todays,
            title: queryArg.title,
          },
        }),
        providesTags: ['events-discover'],
      }),
      listUsersApiSystemAdminUserListGet: build.query<
        ListUsersApiSystemAdminUserListGetApiResponse,
        ListUsersApiSystemAdminUserListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/user/list`,
          params: {
            email: queryArg.email,
            username: queryArg.username,
            steam_id: queryArg.steamId,
            created_at_after: queryArg.createdAtAfter,
            created_at_before: queryArg.createdAtBefore,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
            page: queryArg.page,
            page_size: queryArg.pageSize,
          },
        }),
        providesTags: ['system-admin-user'],
      }),
      getUsersCountApiSystemAdminUserCountGet: build.query<
        GetUsersCountApiSystemAdminUserCountGetApiResponse,
        GetUsersCountApiSystemAdminUserCountGetApiArg
      >({
        query: () => ({ url: `/api/system-admin/user/count` }),
        providesTags: ['system-admin-user'],
      }),
      getUserApiSystemAdminUserUserIdGet: build.query<
        GetUserApiSystemAdminUserUserIdGetApiResponse,
        GetUserApiSystemAdminUserUserIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/system-admin/user/${queryArg.userId}` }),
        providesTags: ['system-admin-user'],
      }),
      updateUserApiSystemAdminUserUserIdPut: build.mutation<
        UpdateUserApiSystemAdminUserUserIdPutApiResponse,
        UpdateUserApiSystemAdminUserUserIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/user/${queryArg.userId}`,
          method: 'PUT',
          params: {
            country: queryArg.country,
            email: queryArg.email,
            username: queryArg.username,
            steam_id: queryArg.steamId,
            bio: queryArg.bio,
            match_code: queryArg.matchCode,
            auth_code: queryArg.authCode,
          },
        }),
        invalidatesTags: ['system-admin-user'],
      }),
      deleteUserApiSystemAdminUserUserIdDelete: build.mutation<
        DeleteUserApiSystemAdminUserUserIdDeleteApiResponse,
        DeleteUserApiSystemAdminUserUserIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/user/${queryArg.userId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['system-admin-user'],
      }),
      restoreUserApiSystemAdminUserRestoreUserIdPut: build.mutation<
        RestoreUserApiSystemAdminUserRestoreUserIdPutApiResponse,
        RestoreUserApiSystemAdminUserRestoreUserIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/user/restore/${queryArg.userId}`,
          method: 'PUT',
        }),
        invalidatesTags: ['system-admin-user'],
      }),
      loginAsUserApiSystemAdminUserLoginAsUserIdGet: build.query<
        LoginAsUserApiSystemAdminUserLoginAsUserIdGetApiResponse,
        LoginAsUserApiSystemAdminUserLoginAsUserIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/system-admin/user/login-as/${queryArg.userId}` }),
        providesTags: ['system-admin-user'],
      }),
      userRefreshSteamDataApiSystemAdminUserSteamRefreshUserIdPut: build.mutation<
        UserRefreshSteamDataApiSystemAdminUserSteamRefreshUserIdPutApiResponse,
        UserRefreshSteamDataApiSystemAdminUserSteamRefreshUserIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/user/steam/refresh/${queryArg.userId}`,
          method: 'PUT',
        }),
        invalidatesTags: ['system-admin-user'],
      }),
      getAdminApiSystemAdminAdminAdminIdGet: build.query<
        GetAdminApiSystemAdminAdminAdminIdGetApiResponse,
        GetAdminApiSystemAdminAdminAdminIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/system-admin/admin/${queryArg.adminId}` }),
        providesTags: ['system-admin-admin'],
      }),
      ticketListApiSystemAdminTicketListGet: build.query<
        TicketListApiSystemAdminTicketListGetApiResponse,
        TicketListApiSystemAdminTicketListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/ticket/list`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            category: queryArg.category,
            status: queryArg.status,
            priority: queryArg.priority,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
          },
        }),
        providesTags: ['system-admin-ticket'],
      }),
      ticketCategoriesListApiSystemAdminTicketCategoriesGet: build.query<
        TicketCategoriesListApiSystemAdminTicketCategoriesGetApiResponse,
        TicketCategoriesListApiSystemAdminTicketCategoriesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/ticket/categories`,
          params: { page: queryArg.page, page_size: queryArg.pageSize },
        }),
        providesTags: ['system-admin-ticket'],
      }),
      ticketPrioritiesListApiSystemAdminTicketPrioritiesGet: build.query<
        TicketPrioritiesListApiSystemAdminTicketPrioritiesGetApiResponse,
        TicketPrioritiesListApiSystemAdminTicketPrioritiesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/ticket/priorities`,
          params: { page: queryArg.page, page_size: queryArg.pageSize },
        }),
        providesTags: ['system-admin-ticket'],
      }),
      ticketStatusesListApiSystemAdminTicketStatusesGet: build.query<
        TicketStatusesListApiSystemAdminTicketStatusesGetApiResponse,
        TicketStatusesListApiSystemAdminTicketStatusesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/ticket/statuses`,
          params: { page: queryArg.page, page_size: queryArg.pageSize },
        }),
        providesTags: ['system-admin-ticket'],
      }),
      ticketDetailApiSystemAdminTicketTicketIdGet: build.query<
        TicketDetailApiSystemAdminTicketTicketIdGetApiResponse,
        TicketDetailApiSystemAdminTicketTicketIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/system-admin/ticket/${queryArg.ticketId}` }),
        providesTags: ['system-admin-ticket'],
      }),
      updateTicketApiSystemAdminTicketTicketIdPut: build.mutation<
        UpdateTicketApiSystemAdminTicketTicketIdPutApiResponse,
        UpdateTicketApiSystemAdminTicketTicketIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/ticket/${queryArg.ticketId}`,
          method: 'PUT',
          params: {
            title: queryArg.title,
            category: queryArg.category,
            priority: queryArg.priority,
            status: queryArg.status,
          },
        }),
        invalidatesTags: ['system-admin-ticket'],
      }),
      createTicketMessageApiSystemAdminTicketTicketIdAddMessagesPost: build.mutation<
        CreateTicketMessageApiSystemAdminTicketTicketIdAddMessagesPostApiResponse,
        CreateTicketMessageApiSystemAdminTicketTicketIdAddMessagesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/ticket/${queryArg.ticketId}/add-messages`,
          method: 'POST',
          body: queryArg.bodyCreateTicketMessageApiSystemAdminTicketTicketIdAddMessagesPost,
          params: { content: queryArg.content },
        }),
        invalidatesTags: ['system-admin-ticket'],
      }),
      getHighlightListApiSystemAdminHighlightListGet: build.query<
        GetHighlightListApiSystemAdminHighlightListGetApiResponse,
        GetHighlightListApiSystemAdminHighlightListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/highlight/list`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            created_at: queryArg.createdAt,
            is_automatic: queryArg.isAutomatic,
            name: queryArg.name,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
          },
        }),
        providesTags: ['system-admin-highlight'],
      }),
      getCountApiSystemAdminHighlightCountGet: build.query<
        GetCountApiSystemAdminHighlightCountGetApiResponse,
        GetCountApiSystemAdminHighlightCountGetApiArg
      >({
        query: () => ({ url: `/api/system-admin/highlight/count` }),
        providesTags: ['system-admin-highlight'],
      }),
      downloadDemoApiSystemAdminDemoDemoHashDownloadGet: build.query<
        DownloadDemoApiSystemAdminDemoDemoHashDownloadGetApiResponse,
        DownloadDemoApiSystemAdminDemoDemoHashDownloadGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/system-admin/demo/${queryArg.demoHash}/download` }),
        providesTags: ['system-admin-demo'],
      }),
      getUploadTypeChoicesApiSystemAdminDemoUploadTypesGet: build.query<
        GetUploadTypeChoicesApiSystemAdminDemoUploadTypesGetApiResponse,
        GetUploadTypeChoicesApiSystemAdminDemoUploadTypesGetApiArg
      >({
        query: () => ({ url: `/api/system-admin/demo/upload-types` }),
        providesTags: ['system-admin-demo'],
      }),
      getDemoStatusChoicesApiSystemAdminDemoDemoStatusesGet: build.query<
        GetDemoStatusChoicesApiSystemAdminDemoDemoStatusesGetApiResponse,
        GetDemoStatusChoicesApiSystemAdminDemoDemoStatusesGetApiArg
      >({
        query: () => ({ url: `/api/system-admin/demo/demo-statuses` }),
        providesTags: ['system-admin-demo'],
      }),
      getDemoCountApiSystemAdminDemoCountGet: build.query<
        GetDemoCountApiSystemAdminDemoCountGetApiResponse,
        GetDemoCountApiSystemAdminDemoCountGetApiArg
      >({
        query: () => ({ url: `/api/system-admin/demo/count` }),
        providesTags: ['system-admin-demo'],
      }),
      getDemoCountByTypeApiSystemAdminDemoCountByTypeGet: build.query<
        GetDemoCountByTypeApiSystemAdminDemoCountByTypeGetApiResponse,
        GetDemoCountByTypeApiSystemAdminDemoCountByTypeGetApiArg
      >({
        query: () => ({ url: `/api/system-admin/demo/count-by-type` }),
        providesTags: ['system-admin-demo'],
      }),
      getDemoListApiSystemAdminDemoListGet: build.query<
        GetDemoListApiSystemAdminDemoListGetApiResponse,
        GetDemoListApiSystemAdminDemoListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/demo/list`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            status: queryArg.status,
            upload_type: queryArg.uploadType,
            username: queryArg.username,
            email: queryArg.email,
            demo_hash: queryArg.demoHash,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
          },
        }),
        providesTags: ['system-admin-demo'],
      }),
      getAdminDemoDetailApiSystemAdminDemoDetailDemoHashGet: build.query<
        GetAdminDemoDetailApiSystemAdminDemoDetailDemoHashGetApiResponse,
        GetAdminDemoDetailApiSystemAdminDemoDetailDemoHashGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/system-admin/demo/detail/${queryArg.demoHash}` }),
        providesTags: ['system-admin-demo'],
      }),
      updateAdminDemoApiSystemAdminDemoDemoHashPut: build.mutation<
        UpdateAdminDemoApiSystemAdminDemoDemoHashPutApiResponse,
        UpdateAdminDemoApiSystemAdminDemoDemoHashPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/demo/${queryArg.demoHash}`,
          method: 'PUT',
          params: {
            status: queryArg.status,
            origin_name: queryArg.originName,
            size: queryArg.size,
            file_path: queryArg.filePath,
            analysis_version: queryArg.analysisVersion,
            parser_version: queryArg.parserVersion,
            game_version: queryArg.gameVersion,
            demo_version: queryArg.demoVersion,
            tick_rate: queryArg.tickRate,
            created_at: queryArg.createdAt,
            played_at: queryArg.playedAt,
            processed_at: queryArg.processedAt,
          },
        }),
        invalidatesTags: ['system-admin-demo'],
      }),
      deleteAdminDemoApiSystemAdminDemoDemoHashDelete: build.mutation<
        DeleteAdminDemoApiSystemAdminDemoDemoHashDeleteApiResponse,
        DeleteAdminDemoApiSystemAdminDemoDemoHashDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/demo/${queryArg.demoHash}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['system-admin-demo'],
      }),
      listContentCreatorsApiSystemAdminContentCreatorListGet: build.query<
        ListContentCreatorsApiSystemAdminContentCreatorListGetApiResponse,
        ListContentCreatorsApiSystemAdminContentCreatorListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/content-creator/list`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            username: queryArg.username,
            code: queryArg.code,
            email: queryArg.email,
            more_than_x_supporters: queryArg.moreThanXSupporters,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
          },
        }),
        providesTags: ['system-admin-content-creator'],
      }),
      deleteContentCreatorApiSystemAdminContentCreatorDeleteCreatorIdDelete: build.mutation<
        DeleteContentCreatorApiSystemAdminContentCreatorDeleteCreatorIdDeleteApiResponse,
        DeleteContentCreatorApiSystemAdminContentCreatorDeleteCreatorIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/content-creator/delete/${queryArg.creatorId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['system-admin-content-creator'],
      }),
      listContentCreatorSupportersApiSystemAdminContentCreatorSupporterCreatorIdSupportersGet:
        build.query<
          ListContentCreatorSupportersApiSystemAdminContentCreatorSupporterCreatorIdSupportersGetApiResponse,
          ListContentCreatorSupportersApiSystemAdminContentCreatorSupporterCreatorIdSupportersGetApiArg
        >({
          query: (queryArg) => ({
            url: `/api/system-admin/content-creator-supporter/${queryArg.creatorId}/supporters`,
            params: {
              page: queryArg.page,
              page_size: queryArg.pageSize,
              history: queryArg.history,
            },
          }),
          providesTags: ['system-admin-content-creator'],
        }),
      getContentCreatorDetailApiSystemAdminContentCreatorCreatorIdGet: build.query<
        GetContentCreatorDetailApiSystemAdminContentCreatorCreatorIdGetApiResponse,
        GetContentCreatorDetailApiSystemAdminContentCreatorCreatorIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/system-admin/content-creator/${queryArg.creatorId}` }),
        providesTags: ['system-admin-content-creator'],
      }),
      listContentCreatorApplicationsApiSystemAdminContentCreatorApplicationListGet: build.query<
        ListContentCreatorApplicationsApiSystemAdminContentCreatorApplicationListGetApiResponse,
        ListContentCreatorApplicationsApiSystemAdminContentCreatorApplicationListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/content-creator-application/list`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            username: queryArg.username,
            status: queryArg.status,
          },
        }),
        providesTags: ['system-admin-content-creator-application'],
      }),
      getContentCreatorApplicationApiSystemAdminContentCreatorApplicationApplicationIdGet:
        build.query<
          GetContentCreatorApplicationApiSystemAdminContentCreatorApplicationApplicationIdGetApiResponse,
          GetContentCreatorApplicationApiSystemAdminContentCreatorApplicationApplicationIdGetApiArg
        >({
          query: (queryArg) => ({
            url: `/api/system-admin/content-creator-application/${queryArg.applicationId}`,
          }),
          providesTags: ['system-admin-content-creator-application'],
        }),
      approveContentCreatorApplicationApiSystemAdminContentCreatorApplicationApproveApplicationIdPut:
        build.mutation<
          ApproveContentCreatorApplicationApiSystemAdminContentCreatorApplicationApproveApplicationIdPutApiResponse,
          ApproveContentCreatorApplicationApiSystemAdminContentCreatorApplicationApproveApplicationIdPutApiArg
        >({
          query: (queryArg) => ({
            url: `/api/system-admin/content-creator-application/approve/${queryArg.applicationId}`,
            method: 'PUT',
          }),
          invalidatesTags: ['system-admin-content-creator-application'],
        }),
      rejectContentCreatorApplicationApiSystemAdminContentCreatorApplicationRejectApplicationIdPut:
        build.mutation<
          RejectContentCreatorApplicationApiSystemAdminContentCreatorApplicationRejectApplicationIdPutApiResponse,
          RejectContentCreatorApplicationApiSystemAdminContentCreatorApplicationRejectApplicationIdPutApiArg
        >({
          query: (queryArg) => ({
            url: `/api/system-admin/content-creator-application/reject/${queryArg.applicationId}`,
            method: 'PUT',
            params: { not_approved_reason: queryArg.notApprovedReason },
          }),
          invalidatesTags: ['system-admin-content-creator-application'],
        }),
      createClanRoleApiSystemAdminTaxonomyClanRolePost: build.mutation<
        CreateClanRoleApiSystemAdminTaxonomyClanRolePostApiResponse,
        CreateClanRoleApiSystemAdminTaxonomyClanRolePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/clan-role`,
          method: 'POST',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdGet: build.query<
        GetClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdGetApiResponse,
        GetClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/clan-role/${queryArg.clanRoleId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updateClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdPut: build.mutation<
        UpdateClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdPutApiResponse,
        UpdateClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/clan-role/${queryArg.clanRoleId}`,
          method: 'PUT',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      createPremiumApiSystemAdminTaxonomyPremiumTypePost: build.mutation<
        CreatePremiumApiSystemAdminTaxonomyPremiumTypePostApiResponse,
        CreatePremiumApiSystemAdminTaxonomyPremiumTypePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/premium-type`,
          method: 'POST',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getPremiumApiSystemAdminTaxonomyPremiumTypePremiumIdGet: build.query<
        GetPremiumApiSystemAdminTaxonomyPremiumTypePremiumIdGetApiResponse,
        GetPremiumApiSystemAdminTaxonomyPremiumTypePremiumIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/premium-type/${queryArg.premiumId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updatePremiumApiSystemAdminTaxonomyPremiumTypePremiumIdPut: build.mutation<
        UpdatePremiumApiSystemAdminTaxonomyPremiumTypePremiumIdPutApiResponse,
        UpdatePremiumApiSystemAdminTaxonomyPremiumTypePremiumIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/premium-type/${queryArg.premiumId}`,
          method: 'PUT',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      createContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformPost:
        build.mutation<
          CreateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformPostApiResponse,
          CreateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformPostApiArg
        >({
          query: (queryArg) => ({
            url: `/api/system-admin/taxonomy/user-content-creator-platform`,
            method: 'POST',
            params: { name: queryArg.name },
          }),
          invalidatesTags: ['system-admin-taxonomy'],
        }),
      getContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdGet:
        build.query<
          GetContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdGetApiResponse,
          GetContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdGetApiArg
        >({
          query: (queryArg) => ({
            url: `/api/system-admin/taxonomy/user-content-creator-platform/${queryArg.contentCreatorPlatformId}`,
          }),
          providesTags: ['system-admin-taxonomy'],
        }),
      updateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdPut:
        build.mutation<
          UpdateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdPutApiResponse,
          UpdateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdPutApiArg
        >({
          query: (queryArg) => ({
            url: `/api/system-admin/taxonomy/user-content-creator-platform/${queryArg.contentCreatorPlatformId}`,
            method: 'PUT',
            params: { name: queryArg.name },
          }),
          invalidatesTags: ['system-admin-taxonomy'],
        }),
      createDemoTypeApiSystemAdminTaxonomyDemoTypePost: build.mutation<
        CreateDemoTypeApiSystemAdminTaxonomyDemoTypePostApiResponse,
        CreateDemoTypeApiSystemAdminTaxonomyDemoTypePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/demo-type`,
          method: 'POST',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdGet: build.query<
        GetDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdGetApiResponse,
        GetDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/demo-type/${queryArg.demoTypeId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updateDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdPut: build.mutation<
        UpdateDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdPutApiResponse,
        UpdateDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/demo-type/${queryArg.demoTypeId}`,
          method: 'PUT',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      createDemoModeApiSystemAdminTaxonomyDemoModePost: build.mutation<
        CreateDemoModeApiSystemAdminTaxonomyDemoModePostApiResponse,
        CreateDemoModeApiSystemAdminTaxonomyDemoModePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/demo-mode`,
          method: 'POST',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdGet: build.query<
        GetDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdGetApiResponse,
        GetDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/demo-mode/${queryArg.demoModeId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updateDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdPut: build.mutation<
        UpdateDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdPutApiResponse,
        UpdateDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/demo-mode/${queryArg.demoModeId}`,
          method: 'PUT',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      createUserMatchRoleApiSystemAdminTaxonomyUserMatchRolePost: build.mutation<
        CreateUserMatchRoleApiSystemAdminTaxonomyUserMatchRolePostApiResponse,
        CreateUserMatchRoleApiSystemAdminTaxonomyUserMatchRolePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/user-match-role`,
          method: 'POST',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdGet: build.query<
        GetUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdGetApiResponse,
        GetUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/user-match-role/${queryArg.userMatchRoleId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updateUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdPut: build.mutation<
        UpdateUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdPutApiResponse,
        UpdateUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/user-match-role/${queryArg.userMatchRoleId}`,
          method: 'PUT',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      createEventPublicityApiSystemAdminTaxonomyEventPublicityPost: build.mutation<
        CreateEventPublicityApiSystemAdminTaxonomyEventPublicityPostApiResponse,
        CreateEventPublicityApiSystemAdminTaxonomyEventPublicityPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/event-publicity`,
          method: 'POST',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdGet: build.query<
        GetEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdGetApiResponse,
        GetEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/event-publicity/${queryArg.eventPublicityId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updateEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdPut: build.mutation<
        UpdateEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdPutApiResponse,
        UpdateEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/event-publicity/${queryArg.eventPublicityId}`,
          method: 'PUT',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      createEventTypeApiSystemAdminTaxonomyEventTypePost: build.mutation<
        CreateEventTypeApiSystemAdminTaxonomyEventTypePostApiResponse,
        CreateEventTypeApiSystemAdminTaxonomyEventTypePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/event-type`,
          method: 'POST',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdGet: build.query<
        GetEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdGetApiResponse,
        GetEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/event-type/${queryArg.eventTypeId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updateEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdPut: build.mutation<
        UpdateEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdPutApiResponse,
        UpdateEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/event-type/${queryArg.eventTypeId}`,
          method: 'PUT',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      createTicketStatusApiSystemAdminTaxonomyTicketStatusPost: build.mutation<
        CreateTicketStatusApiSystemAdminTaxonomyTicketStatusPostApiResponse,
        CreateTicketStatusApiSystemAdminTaxonomyTicketStatusPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/ticket-status`,
          method: 'POST',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdGet: build.query<
        GetTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdGetApiResponse,
        GetTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/ticket-status/${queryArg.ticketStatusId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updateTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdPut: build.mutation<
        UpdateTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdPutApiResponse,
        UpdateTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/ticket-status/${queryArg.ticketStatusId}`,
          method: 'PUT',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      createTicketPriorityApiSystemAdminTaxonomyTicketPriorityPost: build.mutation<
        CreateTicketPriorityApiSystemAdminTaxonomyTicketPriorityPostApiResponse,
        CreateTicketPriorityApiSystemAdminTaxonomyTicketPriorityPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/ticket-priority`,
          method: 'POST',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdGet: build.query<
        GetTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdGetApiResponse,
        GetTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/ticket-priority/${queryArg.ticketPriorityId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updateTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdPut: build.mutation<
        UpdateTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdPutApiResponse,
        UpdateTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/ticket-priority/${queryArg.ticketPriorityId}`,
          method: 'PUT',
          params: { name: queryArg.name },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      createTicketCategoryApiSystemAdminTaxonomyTicketCategoryPost: build.mutation<
        CreateTicketCategoryApiSystemAdminTaxonomyTicketCategoryPostApiResponse,
        CreateTicketCategoryApiSystemAdminTaxonomyTicketCategoryPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/ticket-category`,
          method: 'POST',
          params: { name: queryArg.name, parent_category_id: queryArg.parentCategoryId },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdGet: build.query<
        GetTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdGetApiResponse,
        GetTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/ticket-category/${queryArg.ticketCategoryId}`,
        }),
        providesTags: ['system-admin-taxonomy'],
      }),
      updateTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdPut: build.mutation<
        UpdateTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdPutApiResponse,
        UpdateTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/taxonomy/ticket-category/${queryArg.ticketCategoryId}`,
          method: 'PUT',
          params: { name: queryArg.name, parent_category_id: queryArg.parentCategoryId },
        }),
        invalidatesTags: ['system-admin-taxonomy'],
      }),
      getUploadQueueTaskListApiSystemAdminQueueUploadListGet: build.query<
        GetUploadQueueTaskListApiSystemAdminQueueUploadListGetApiResponse,
        GetUploadQueueTaskListApiSystemAdminQueueUploadListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/queue/upload-list`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            status: queryArg.status,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
          },
        }),
        providesTags: ['system-admin-queue'],
      }),
      getDownloadQueueTaskListApiSystemAdminQueueDownloadListGet: build.query<
        GetDownloadQueueTaskListApiSystemAdminQueueDownloadListGetApiResponse,
        GetDownloadQueueTaskListApiSystemAdminQueueDownloadListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/queue/download-list`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            status: queryArg.status,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
          },
        }),
        providesTags: ['system-admin-queue'],
      }),
      getHighlightQueueTaskListApiSystemAdminQueueHighlightListGet: build.query<
        GetHighlightQueueTaskListApiSystemAdminQueueHighlightListGetApiResponse,
        GetHighlightQueueTaskListApiSystemAdminQueueHighlightListGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/queue/highlight-list`,
          params: {
            page: queryArg.page,
            page_size: queryArg.pageSize,
            status: queryArg.status,
            sort_by: queryArg.sortBy,
            sort_order: queryArg.sortOrder,
          },
        }),
        providesTags: ['system-admin-queue'],
      }),
      getQueueDashboardApiSystemAdminQueueDashboardGet: build.query<
        GetQueueDashboardApiSystemAdminQueueDashboardGetApiResponse,
        GetQueueDashboardApiSystemAdminQueueDashboardGetApiArg
      >({
        query: () => ({ url: `/api/system-admin/queue/dashboard` }),
        providesTags: ['system-admin-queue'],
      }),
      moveQueueTaskApiSystemAdminQueueMoveTaskPost: build.mutation<
        MoveQueueTaskApiSystemAdminQueueMoveTaskPostApiResponse,
        MoveQueueTaskApiSystemAdminQueueMoveTaskPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/system-admin/queue/move-task`,
          method: 'POST',
          params: { queue: queryArg.queue, new_queue: queryArg.newQueue, task_id: queryArg.taskId },
        }),
        invalidatesTags: ['system-admin-queue'],
      }),
      listUserTicketsApiTicketsAllGet: build.query<
        ListUserTicketsApiTicketsAllGetApiResponse,
        ListUserTicketsApiTicketsAllGetApiArg
      >({
        query: () => ({ url: `/api/tickets/all` }),
        providesTags: ['ticket'],
      }),
      createTicketApiTicketsCreatePost: build.mutation<
        CreateTicketApiTicketsCreatePostApiResponse,
        CreateTicketApiTicketsCreatePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tickets/create`,
          method: 'POST',
          body: queryArg.bodyCreateTicketApiTicketsCreatePost,
          params: { title: queryArg.title, category: queryArg.category, content: queryArg.content },
        }),
        invalidatesTags: ['ticket'],
      }),
      createTicketMessageApiTicketsTicketIdAddUserMessagePost: build.mutation<
        CreateTicketMessageApiTicketsTicketIdAddUserMessagePostApiResponse,
        CreateTicketMessageApiTicketsTicketIdAddUserMessagePostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tickets/${queryArg.ticketId}/add-user-message`,
          method: 'POST',
          body: queryArg.bodyCreateTicketMessageApiTicketsTicketIdAddUserMessagePost,
          params: { content: queryArg.content },
        }),
        invalidatesTags: ['ticket'],
      }),
      getTicketApiTicketsTicketIdGet: build.query<
        GetTicketApiTicketsTicketIdGetApiResponse,
        GetTicketApiTicketsTicketIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/tickets/${queryArg.ticketId}` }),
        providesTags: ['ticket'],
      }),
      updateTicketMessageRatingApiTicketsMessageTicketMessageIdRatingPut: build.mutation<
        UpdateTicketMessageRatingApiTicketsMessageTicketMessageIdRatingPutApiResponse,
        UpdateTicketMessageRatingApiTicketsMessageTicketMessageIdRatingPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tickets/message/${queryArg.ticketMessageId}/rating`,
          method: 'PUT',
          params: { rating: queryArg.rating, rating_message: queryArg.ratingMessage },
        }),
        invalidatesTags: ['ticket'],
      }),
      getTicketMessageAttachmentsApiTicketsmessageTicketMessageIdAttachmentsGet: build.query<
        GetTicketMessageAttachmentsApiTicketsmessageTicketMessageIdAttachmentsGetApiResponse,
        GetTicketMessageAttachmentsApiTicketsmessageTicketMessageIdAttachmentsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ticketsmessage/${queryArg.ticketMessageId}/attachments`,
        }),
        providesTags: ['ticket'],
      }),
      getTicketMessageFileApiTicketsfileTicketMessageFileIdAttachmentsGet: build.query<
        GetTicketMessageFileApiTicketsfileTicketMessageFileIdAttachmentsGetApiResponse,
        GetTicketMessageFileApiTicketsfileTicketMessageFileIdAttachmentsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ticketsfile/${queryArg.ticketMessageFileId}/attachments`,
        }),
        providesTags: ['ticket'],
      }),
      getCountriesApiUserCountriesGet: build.query<
        GetCountriesApiUserCountriesGetApiResponse,
        GetCountriesApiUserCountriesGetApiArg
      >({
        query: () => ({ url: `/api/user/countries` }),
        providesTags: ['user-helper'],
      }),
      homeGet: build.query<HomeGetApiResponse, HomeGetApiArg>({
        query: () => ({ url: `/` }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as api };
export type GetDemoHeaderApiGamesDemoIdHeaderGetApiResponse =
  /** status 200 Successful Response */ DemoHeaderResponse;
export type GetDemoHeaderApiGamesDemoIdHeaderGetApiArg = {
  demoId: string;
  steamId?: string | null;
  lastNGames?: number;
};
export type GetDemoDetailApiGamesDemoIdDetailGetApiResponse =
  /** status 200 Successful Response */ PlayerMatchStatDetailModelRead;
export type GetDemoDetailApiGamesDemoIdDetailGetApiArg = {
  demoId: string;
  steamId?: string | null;
  mapName?: string | null;
  lastNGames?: number | null;
};
export type GetDemoMyDeathApiGamesDemoIdDeathsGetApiResponse =
  /** status 200 Successful Response */ UserDemoMyDeathResponseRead;
export type GetDemoMyDeathApiGamesDemoIdDeathsGetApiArg = {
  demoId: string;
  steamId?: string | null;
};
export type GetDemoDuelApiGamesDemoIdDuelsGetApiResponse =
  /** status 200 Successful Response */ UserDemoDuelResponse;
export type GetDemoDuelApiGamesDemoIdDuelsGetApiArg = {
  demoId: string;
  steamId?: string | null;
};
export type GetDemoPlayersApiGamesDemoIdPlayersGetApiResponse =
  /** status 200 Successful Response */ UserDemoPlayerResponse[];
export type GetDemoPlayersApiGamesDemoIdPlayersGetApiArg = {
  demoId: string;
};
export type GetDemoRoundApiGamesDemoIdRoundsGetApiResponse =
  /** status 200 Successful Response */ UserDemoRoundResponse;
export type GetDemoRoundApiGamesDemoIdRoundsGetApiArg = {
  demoId: string;
};
export type GetDemoScoreboardApiGamesDemoIdScoreboardGetApiResponse =
  /** status 200 Successful Response */ UserDemoScoreboardResponse[];
export type GetDemoScoreboardApiGamesDemoIdScoreboardGetApiArg = {
  demoId: string;
};
export type GetDemoPlayerRankingApiGamesRankingDemoIdGetApiResponse =
  /** status 200 Successful Response */ UserDemoRankingResponse;
export type GetDemoPlayerRankingApiGamesRankingDemoIdGetApiArg = {
  demoId: string;
  steamId?: string | null;
};
export type GetDemoMapDetailApiGamesDemoIdMapGetApiResponse =
  /** status 200 Successful Response */ string;
export type GetDemoMapDetailApiGamesDemoIdMapGetApiArg = {
  demoId: string;
};
export type GetDemoMapDetailRoundApiGamesDemoIdMapRoundNumberGetApiResponse =
  /** status 200 Successful Response */ string;
export type GetDemoMapDetailRoundApiGamesDemoIdMapRoundNumberGetApiArg = {
  demoId: string;
  roundNumber: number;
};
export type GetDemoHashByDemoIdApiGamesDemoHashDemoIdGetApiResponse =
  /** status 200 Successful Response */ string;
export type GetDemoHashByDemoIdApiGamesDemoHashDemoIdGetApiArg = {
  demoId: string;
};
export type GetTutorialGamesApiGamesTutorialGamesGetApiResponse =
  /** status 200 Successful Response */ TutorialMatchesResponse[];
export type GetTutorialGamesApiGamesTutorialGamesGetApiArg = void;
export type GetUserMatchListApiDemosGetApiResponse =
  /** status 200 Successful Response */ UserMatchListResultsResponse;
export type GetUserMatchListApiDemosGetApiArg = {
  page?: number | null;
  pageSize?: number | null;
  favorite?: number | null;
  dateFrom?: string | null;
  dateTo?: string | null;
  myOnly?: boolean;
  mapNames?: string | null;
  mode?: string | null;
  sortBy?: string;
  sortOrder?: SortOrder;
};
export type ChangeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePutApiResponse =
  /** status 200 Successful Response */ UserMatchUpdateResponse;
export type ChangeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePutApiArg = {
  userMatchId: string;
};
export type DeleteUserMatchApiDemosDeletePostApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteUserMatchApiDemosDeletePostApiArg = {
  userMatchId: string;
};
export type GetSteamAvatarsApiDemosGetSteamAvatarsGetApiResponse =
  /** status 200 Successful Response */ any;
export type GetSteamAvatarsApiDemosGetSteamAvatarsGetApiArg = {
  /** Comma separated list of steam ids */
  steamIds: string;
};
export type UserMatchCountApiDemosUserMatchCountGetApiResponse =
  /** status 200 Successful Response */ MatchCountResponse;
export type UserMatchCountApiDemosUserMatchCountGetApiArg = void;
export type GetDemoByHashApiDemosDemoHashGetApiResponse =
  /** status 200 Successful Response */ boolean;
export type GetDemoByHashApiDemosDemoHashGetApiArg = {
  hash: string;
  alias: string;
};
export type UserDemoProcessingApiDemosProcessingGetApiResponse =
  /** status 200 Successful Response */ UserMatchResult[];
export type UserDemoProcessingApiDemosProcessingGetApiArg = void;
export type UploadDemosApiDemosUploadPostApiResponse = /** status 201 Successful Response */ any;
export type UploadDemosApiDemosUploadPostApiArg = {
  bodyUploadDemosApiDemosUploadPost: BodyUploadDemosApiDemosUploadPost;
};
export type AddMapNotesApiGamesMapNotesAddDemoIdPostApiResponse =
  /** status 201 Successful Response */ any;
export type AddMapNotesApiGamesMapNotesAddDemoIdPostApiArg = {
  demoId: string;
  tick: number;
  note?: string | null;
  bodyAddMapNotesApiGamesMapNotesAddDemoIdPost: BodyAddMapNotesApiGamesMapNotesAddDemoIdPost;
};
export type GetMapNotesApiGamesMapNotesDemoIdGetApiResponse =
  /** status 200 Successful Response */ MatchDemoMapNotesResponse[];
export type GetMapNotesApiGamesMapNotesDemoIdGetApiArg = {
  demoId: string;
  matchId: string;
};
export type UpdateMapNotesApiGamesMapNotesNoteIdPutApiResponse =
  /** status 200 Successful Response */ MatchDemoMapNotesResponse;
export type UpdateMapNotesApiGamesMapNotesNoteIdPutApiArg = {
  noteId: number;
  note?: string | null;
  tick?: number | null;
  isPublic?: boolean | null;
  bodyUpdateMapNotesApiGamesMapNotesNoteIdPut: BodyUpdateMapNotesApiGamesMapNotesNoteIdPut;
};
export type DeleteMapNotesApiGamesMapNotesNoteIdDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteMapNotesApiGamesMapNotesNoteIdDeleteApiArg = {
  noteId: number;
};
export type GenerateAutomaticHighlightsApiHighlightsAutomaticGenerationDemoIdPostApiResponse =
  /** status 200 Successful Response */ any;
export type GenerateAutomaticHighlightsApiHighlightsAutomaticGenerationDemoIdPostApiArg = {
  demoId: string;
};
export type GenerateManualHighlightsApiHighlightsManualGenerationDemoIdPostApiResponse =
  /** status 200 Successful Response */ any;
export type GenerateManualHighlightsApiHighlightsManualGenerationDemoIdPostApiArg = {
  demoId: string;
  manualHighlightRequest: ManualHighlightRequest;
};
export type GetHighlightSettingsApiHighlightSettingsHighlightSettingsGetApiResponse =
  /** status 200 Successful Response */ HighlightSettingModelResponse;
export type GetHighlightSettingsApiHighlightSettingsHighlightSettingsGetApiArg = void;
export type UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiResponse =
  /** status 200 Successful Response */ HighlightSettingModelResponse;
export type UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiArg = {
  highlightSettingModelResponse: HighlightSettingModelResponse;
};
export type GetResolutionsApiHighlightSettingsResolutionsGetApiResponse =
  /** status 200 Successful Response */ AdminHighlightResolution[];
export type GetResolutionsApiHighlightSettingsResolutionsGetApiArg = void;
export type GetFrameRatesApiHighlightSettingsFrameRatesGetApiResponse =
  /** status 200 Successful Response */ AdminHighlightFrameRate[];
export type GetFrameRatesApiHighlightSettingsFrameRatesGetApiArg = void;
export type GetVideoQualityApiHighlightSettingsVideoQualityGetApiResponse =
  /** status 200 Successful Response */ AdminHighlightVideoQuality[];
export type GetVideoQualityApiHighlightSettingsVideoQualityGetApiArg = void;
export type GetHighlightEventApiHighlightsEventDemoIdGetApiResponse =
  /** status 200 Successful Response */ HighlightEventAndRoundResponse;
export type GetHighlightEventApiHighlightsEventDemoIdGetApiArg = {
  demoId: string;
};
export type GetHighlightsApiHighlightsListGetApiResponse =
  /** status 200 Successful Response */ HighlightsListResponse;
export type GetHighlightsApiHighlightsListGetApiArg = {
  /** Page number */
  page?: number | null;
  /** Page size */
  pageSize?: number | null;
  /** Comma-separated list of map names */
  mapNames?: string | null;
  /** Start date for filtering */
  dateFrom?: string | null;
  /** End date for filtering */
  dateTo?: string | null;
  /** Name of the highlight to filter */
  name?: string | null;
};
export type GetHighlightVideoApiHighlightsVideoStreamHighlightHashGetApiResponse =
  /** status 200 Successful Response */ any;
export type GetHighlightVideoApiHighlightsVideoStreamHighlightHashGetApiArg = {
  highlightHash: string;
  token?: string | null;
  range?: string;
};
export type GetHighlightProcessingApiHighlightsProcessingGetApiResponse =
  /** status 200 Successful Response */ HighlightProcessingResponse;
export type GetHighlightProcessingApiHighlightsProcessingGetApiArg = void;
export type GetHighlightListFeedApiHighlightsListFeedGetApiResponse =
  /** status 200 Successful Response */ HighlightFeedResponse;
export type GetHighlightListFeedApiHighlightsListFeedGetApiArg = {
  pageSize?: number;
  firstDatetime?: string | null;
  lastDatetime?: string | null;
};
export type RenameHighlightApiHighlightsRenameHighlightIdPutApiResponse =
  /** status 200 Successful Response */ any;
export type RenameHighlightApiHighlightsRenameHighlightIdPutApiArg = {
  highlightId: string;
  highlightRenameRequest: HighlightRenameRequest;
};
export type DeleteHighlightApiHighlightsHighlightIdDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteHighlightApiHighlightsHighlightIdDeleteApiArg = {
  highlightId: string;
};
export type DownloadHighlightApiHighlightsDownloadHighlightIdGetApiResponse =
  /** status 200 Successful Response */ any;
export type DownloadHighlightApiHighlightsDownloadHighlightIdGetApiArg = {
  highlightId: string;
};
export type LikeHighlightApiHighlightsLikeHighlightIdPutApiResponse =
  /** status 200 Successful Response */ any;
export type LikeHighlightApiHighlightsLikeHighlightIdPutApiArg = {
  highlightId: string;
};
export type GetHighlightDetailApiHighlightsDetailHighlightHashGetApiResponse =
  /** status 200 Successful Response */ HighlightDetailResponse;
export type GetHighlightDetailApiHighlightsDetailHighlightHashGetApiArg = {
  highlightHash: string;
};
export type CreateClanInvitationApiClansInvitationPostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateClanInvitationApiClansInvitationPostApiArg = {
  clanInvitationCreateRequest: ClanInvitationCreateRequest;
};
export type CreateClanInvitationsByClanApiClansClanInvitationsByClanPostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateClanInvitationsByClanApiClansClanInvitationsByClanPostApiArg = {
  clanInvitationCreateByClanRequest: ClanInvitationCreateByClanRequest;
};
export type AcceptClanInvitationApiClansClanInvitationsInvitationIdAcceptPutApiResponse =
  /** status 200 Successful Response */ any;
export type AcceptClanInvitationApiClansClanInvitationsInvitationIdAcceptPutApiArg = {
  invitationId: number;
};
export type ListClanInvitationsApiClansClanIdInvitationAllGetApiResponse =
  /** status 200 Successful Response */ ClanInvitationListResponse[];
export type ListClanInvitationsApiClansClanIdInvitationAllGetApiArg = {
  clanId: string;
};
export type AcceptClanInvitationByClanApiClansInvitationIdInvitationAcceptByClanPutApiResponse =
  /** status 200 Successful Response */ any;
export type AcceptClanInvitationByClanApiClansInvitationIdInvitationAcceptByClanPutApiArg = {
  invitationId: number;
};
export type RejectClanInvitationByClanApiClansInvitationIdInvitationRejectByClanPutApiResponse =
  /** status 204 Successful Response */ void;
export type RejectClanInvitationByClanApiClansInvitationIdInvitationRejectByClanPutApiArg = {
  invitationId: number;
};
export type AcceptClanInvitationByUserApiClansInvitationIdInvitationAcceptByUserPutApiResponse =
  /** status 200 Successful Response */ any;
export type AcceptClanInvitationByUserApiClansInvitationIdInvitationAcceptByUserPutApiArg = {
  invitationId: number;
};
export type RejectClanInvitationByUserApiClansInvitationIdInvitationRejectByUserPutApiResponse =
  /** status 204 Successful Response */ void;
export type RejectClanInvitationByUserApiClansInvitationIdInvitationRejectByUserPutApiArg = {
  invitationId: number;
};
export type CheckClanInvitationExistsApiClansClanInvitationsExistsClanIdGetApiResponse =
  /** status 200 Successful Response */ ClanInvitationResponse | null;
export type CheckClanInvitationExistsApiClansClanInvitationsExistsClanIdGetApiArg = {
  clanId: string;
};
export type CreateClanPostApiClansClanIdPostPostApiResponse =
  /** status 201 Successful Response */ ClanPostResponse;
export type CreateClanPostApiClansClanIdPostPostApiArg = {
  clanId: string;
  clanPostCreateRequest: ClanPostCreateRequest;
};
export type ListClanPostsApiClansClanIdPostAllGetApiResponse =
  /** status 200 Successful Response */ ClanPostListResponse[];
export type ListClanPostsApiClansClanIdPostAllGetApiArg = {
  clanId: string;
};
export type IsAnyClanLeaderApiClansIsAnyClanLeaderGetApiResponse =
  /** status 200 Successful Response */ IsAnyClanLeaderResponse;
export type IsAnyClanLeaderApiClansIsAnyClanLeaderGetApiArg = void;
export type GetClanRoleApiClansClanIdClanRoleGetApiResponse =
  /** status 200 Successful Response */ ClanRoleResponse;
export type GetClanRoleApiClansClanIdClanRoleGetApiArg = {
  clanId: string;
};
export type ListClanMembersApiClansClanIdMemberListGetApiResponse =
  /** status 200 Successful Response */ ClanUserPublicResponse[];
export type ListClanMembersApiClansClanIdMemberListGetApiArg = {
  clanId: string;
  username?: string | null;
};
export type GetClanUserMemberListApiClansIdMemberListPrivateGetApiResponse =
  /** status 200 Successful Response */ ClanUserMemberListPrivateResponse[];
export type GetClanUserMemberListApiClansIdMemberListPrivateGetApiArg = {
  id_: string;
  id: number;
  username?: string | null;
  sortBy?: SortByEnumClanPrivateMemberList;
  sortOrder?: SortOrder;
};
export type GetClanMemberApiClansClanIdClanUserMemberIdGetApiResponse =
  /** status 200 Successful Response */ ClanUserResponse;
export type GetClanMemberApiClansClanIdClanUserMemberIdGetApiArg = {
  clanId: string;
  memberId: number;
};
export type UpdateClanMemberApiClansClanIdClanUserMemberIdPutApiResponse =
  /** status 200 Successful Response */ ClanUserUpdateResponse;
export type UpdateClanMemberApiClansClanIdClanUserMemberIdPutApiArg = {
  clanId: string;
  memberId: number;
  clanUserRoleUpdate: ClanUserRole;
};
export type RemoveClanMemberApiClansClanIdClanUserMemberIdDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type RemoveClanMemberApiClansClanIdClanUserMemberIdDeleteApiArg = {
  clanId: string;
  memberId: number;
};
export type ListClansApiClansAllGetApiResponse =
  /** status 200 Successful Response */ ClanPaginatedResponse;
export type ListClansApiClansAllGetApiArg = {
  /** Filter by name */
  name?: string | null;
  page?: number | null;
  pageSize?: number | null;
};
export type ListUserClansApiClansUserAllGetApiResponse =
  /** status 200 Successful Response */ ClanUserListResponse;
export type ListUserClansApiClansUserAllGetApiArg = {
  page?: number | null;
  pageSize?: number | null;
};
export type CreateClanApiClansPostApiResponse = /** status 201 Successful Response */ any;
export type CreateClanApiClansPostApiArg = {
  name: string;
  description: string;
  bodyCreateClanApiClansPost: BodyCreateClanApiClansPost;
};
export type GetClanApiClansClanIdGetApiResponse =
  /** status 200 Successful Response */ ClanDetailResponse;
export type GetClanApiClansClanIdGetApiArg = {
  clanId: number;
};
export type UpdateClanApiClansClanIdPutApiResponse =
  /** status 200 Successful Response */ ClanDetailResponse;
export type UpdateClanApiClansClanIdPutApiArg = {
  clanId: number;
  name: string;
  description: string;
  bodyUpdateClanApiClansClanIdPut: BodyUpdateClanApiClansClanIdPut;
};
export type GetUserRankingApiRankingsUserGetApiResponse =
  /** status 200 Successful Response */ RankingListResult | null;
export type GetUserRankingApiRankingsUserGetApiArg = {
  sortBy?: RankingSortBy | null;
  sortOrder?: SortOrder | null;
  underSix?: boolean;
  allTime?: boolean;
};
export type GetRankingListApiRankingsListGetApiResponse =
  /** status 200 Successful Response */ RankingListResponse;
export type GetRankingListApiRankingsListGetApiArg = {
  sortBy?: RankingSortBy | null;
  sortOrder?: SortOrder | null;
  username?: string | null;
  countries?: string | null;
  page?: number | null;
  pageSize?: number | null;
};
export type GetRankingAllTimeListApiRankingsListAllTimeGetApiResponse =
  /** status 200 Successful Response */ RankingListResponse;
export type GetRankingAllTimeListApiRankingsListAllTimeGetApiArg = {
  sortBy?: RankingSortBy | null;
  sortOrder?: SortOrder | null;
  username?: string | null;
  countries?: string | null;
  page?: number | null;
  pageSize?: number | null;
};
export type GetRankingListUnderSixMatchesApiRankingsListUnderSixGetApiResponse =
  /** status 200 Successful Response */ RankingListResponse;
export type GetRankingListUnderSixMatchesApiRankingsListUnderSixGetApiArg = {
  sortBy?: RankingSortBy | null;
  sortOrder?: SortOrder | null;
  username?: string | null;
  countries?: string | null;
  page?: number | null;
  pageSize?: number | null;
};
export type GetRankingUserCountApiRankingsUserCountGetApiResponse =
  /** status 200 Successful Response */ number;
export type GetRankingUserCountApiRankingsUserCountGetApiArg = void;
export type RefreshTokenApiUserTokenRefreshPostApiResponse =
  /** status 200 Successful Response */ UserToken;
export type RefreshTokenApiUserTokenRefreshPostApiArg = {
  tokenRefreshRequest: TokenRefreshRequest;
};
export type LoginForAccessTokenApiUserTokenPostApiResponse =
  /** status 200 Successful Response */ UserToken;
export type LoginForAccessTokenApiUserTokenPostApiArg = {
  bodyLoginForAccessTokenApiUserTokenPost: BodyLoginForAccessTokenApiUserTokenPost;
};
export type LoginForAdminAccessTokenApiUserTokenAdminPostApiResponse =
  /** status 200 Successful Response */ AdminToken;
export type LoginForAdminAccessTokenApiUserTokenAdminPostApiArg = {
  bodyLoginForAdminAccessTokenApiUserTokenAdminPost: BodyLoginForAdminAccessTokenApiUserTokenAdminPost;
};
export type LogoutApiUserLogoutPostApiResponse = /** status 200 Successful Response */ any;
export type LogoutApiUserLogoutPostApiArg = void;
export type AdminLogoutApiUserAdminLogoutPostApiResponse =
  /** status 200 Successful Response */ any;
export type AdminLogoutApiUserAdminLogoutPostApiArg = void;
export type FirstLoginApiUserFirstLoginPostApiResponse = /** status 200 Successful Response */ any;
export type FirstLoginApiUserFirstLoginPostApiArg = void;
export type ReadCurrentUserApiUserDetailGetApiResponse =
  /** status 200 Successful Response */ UserResponse;
export type ReadCurrentUserApiUserDetailGetApiArg = void;
export type UploadAvatarApiUserUpdateAvatarPutApiResponse =
  /** status 200 Successful Response */ any;
export type UploadAvatarApiUserUpdateAvatarPutApiArg = {
  bodyUploadAvatarApiUserUpdateAvatarPut: BodyUploadAvatarApiUserUpdateAvatarPut;
};
export type ListClanUsersApiUserListClanClanIdGetApiResponse =
  /** status 200 Successful Response */ UserMinimalResponse[];
export type ListClanUsersApiUserListClanClanIdGetApiArg = {
  clanId: string;
  search?: string | null;
};
export type ListUsersApiUserListGetApiResponse =
  /** status 200 Successful Response */ UserListPaginatedResponse;
export type ListUsersApiUserListGetApiArg = {
  search?: string | null;
  page?: number | null;
  pageSize?: number | null;
};
export type UpdateUserSettingsApiUserChangeSettingsPutApiResponse =
  /** status 200 Successful Response */ UserResponse;
export type UpdateUserSettingsApiUserChangeSettingsPutApiArg = {
  userUpdateRequest: UserUpdateRequest;
};
export type GetUserDashboardApiUserDashboardGetApiResponse =
  /** status 200 Successful Response */ UserDashboardResponse;
export type GetUserDashboardApiUserDashboardGetApiArg = {
  steamId: string;
  lastNGames?: number;
};
export type GetUserMapStatsApiUserMapStatsGetApiResponse =
  /** status 200 Successful Response */ MapWinRateResponse[];
export type GetUserMapStatsApiUserMapStatsGetApiArg = {
  steamId: string;
};
export type ValidateUsernameApiUserUsernameValidationPostApiResponse =
  /** status 200 Successful Response */ any;
export type ValidateUsernameApiUserUsernameValidationPostApiArg = {
  username?: string | null;
};
export type ValidateEmailApiUserEmailValidationPostApiResponse =
  /** status 200 Successful Response */ any;
export type ValidateEmailApiUserEmailValidationPostApiArg = {
  email: string;
};
export type RegisterUserApiUserRegisterPostApiResponse =
  /** status 200 Successful Response */ UserToken;
export type RegisterUserApiUserRegisterPostApiArg = {
  userRegisterRequest: UserRegisterRequest;
};
export type ConfirmEmailApiUserConfirmRegistrationPostApiResponse =
  /** status 200 Successful Response */ UserToken;
export type ConfirmEmailApiUserConfirmRegistrationPostApiArg = {
  confirmEmailRequest: ConfirmEmailRequest;
};
export type ResendConfirmEmailApiUserResendConfirmRegistrationPostApiResponse =
  /** status 200 Successful Response */ any;
export type ResendConfirmEmailApiUserResendConfirmRegistrationPostApiArg = {
  resendConfirmEmailRequest: ResendConfirmEmailRequest;
};
export type ChangePasswordApiUserChangePasswordPutApiResponse =
  /** status 200 Successful Response */ any;
export type ChangePasswordApiUserChangePasswordPutApiArg = {
  changePasswordRequest: ChangePasswordRequest;
};
export type ResetPasswordRequestApiUserResetPasswordRequestPostApiResponse =
  /** status 200 Successful Response */ any;
export type ResetPasswordRequestApiUserResetPasswordRequestPostApiArg = {
  resetPasswordRequest: ResetPasswordRequest;
};
export type ResetPasswordApiUserResetPasswordPutApiResponse =
  /** status 200 Successful Response */ any;
export type ResetPasswordApiUserResetPasswordPutApiArg = {
  setNewPasswordRequest: SetNewPasswordRequest;
};
export type UserSteamLoginApiUserSteamLoginPostApiResponse =
  /** status 200 Successful Response */ UserToken;
export type UserSteamLoginApiUserSteamLoginPostApiArg = {
  steamLoginDataRequest: SteamLoginDataRequest;
};
export type UserSteamConnectApiUserSteamConnectPostApiResponse =
  /** status 200 Successful Response */ any;
export type UserSteamConnectApiUserSteamConnectPostApiArg = {
  steamLoginDataRequest: SteamLoginDataRequest;
};
export type UserSteamDisconnectApiUserSteamDisconnectPutApiResponse =
  /** status 200 Successful Response */ any;
export type UserSteamDisconnectApiUserSteamDisconnectPutApiArg = void;
export type UserFaceitDisconnectApiUserFaceitDisconnectPutApiResponse =
  /** status 200 Successful Response */ any;
export type UserFaceitDisconnectApiUserFaceitDisconnectPutApiArg = void;
export type UpdateSteamCodeApiUserSteamUpdateGamecodePutApiResponse =
  /** status 200 Successful Response */ SteamCodeResponse;
export type UpdateSteamCodeApiUserSteamUpdateGamecodePutApiArg = {
  steamCodeUpdateRequest: SteamCodeUpdateRequest;
};
export type GetSteamCodeApiUserSteamGetCodesGetApiResponse =
  /** status 200 Successful Response */ SteamCodeResponse;
export type GetSteamCodeApiUserSteamGetCodesGetApiArg = void;
export type RefreshSteamDataApiUserSteamRefreshPutApiResponse =
  /** status 200 Successful Response */ any;
export type RefreshSteamDataApiUserSteamRefreshPutApiArg = void;
export type CreateContentCreatorApiContentCreatorCreatePostApiResponse =
  /** status 200 Successful Response */ UserContentCreatorCreateResponse;
export type CreateContentCreatorApiContentCreatorCreatePostApiArg = {
  userContentCreatorCreateUpdateRequest: UserContentCreatorCreateUpdateRequest;
};
export type GetContentCreatorPlatformsApiContentCreatorPlatformsGetApiResponse =
  /** status 200 Successful Response */ UserContentCreatorPlatformResponse[];
export type GetContentCreatorPlatformsApiContentCreatorPlatformsGetApiArg = void;
export type CreateContentCreatorPlatformApiContentCreatorPlatformsPostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateContentCreatorPlatformApiContentCreatorPlatformsPostApiArg = {
  userContentCreatorPlatformCreateRequest: UserContentCreatorPlatformCreateRequest;
};
export type UpdateContentCreatorPlatformApiContentCreatorPlatformsPlatformIdPutApiResponse =
  /** status 204 Successful Response */ void;
export type UpdateContentCreatorPlatformApiContentCreatorPlatformsPlatformIdPutApiArg = {
  platformId: number;
  userContentCreatorPlatformCreateRequest: UserContentCreatorPlatformCreateRequest;
};
export type DeleteContentCreatorPlatformApiContentCreatorPlatformsPlatformIdDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteContentCreatorPlatformApiContentCreatorPlatformsPlatformIdDeleteApiArg = {
  platformId: number;
};
export type GetContentCreatorApiContentCreatorDetailGetApiResponse =
  /** status 200 Successful Response */ UserContentCreatorDetailResponse;
export type GetContentCreatorApiContentCreatorDetailGetApiArg = void;
export type UpdateContentCreatorApiContentCreatorUpdatePutApiResponse =
  /** status 200 Successful Response */ any;
export type UpdateContentCreatorApiContentCreatorUpdatePutApiArg = {
  name: string | null;
  surname: string | null;
  birthDate: string | null;
  message?: string | null;
  code?: string | null;
  bodyUpdateContentCreatorApiContentCreatorUpdatePut: BodyUpdateContentCreatorApiContentCreatorUpdatePut;
};
export type CreateContentCreatorSupporterApiContentCreatorSupportCodePostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateContentCreatorSupporterApiContentCreatorSupportCodePostApiArg = {
  code: string;
};
export type ListContentCreatorSupportersApiContentCreatorSupportersGetApiResponse =
  /** status 200 Successful Response */ UserContentCreatorSupporterListResponse;
export type ListContentCreatorSupportersApiContentCreatorSupportersGetApiArg = {
  /** Content creator supporters - either historic or current */
  history?: boolean | null;
  /** Page number */
  page?: number | null;
  /** Page size */
  pageSize?: number | null;
};
export type RemoveContentCreatorSupportApiContentCreatorRemoveSupportPutApiResponse = unknown;
export type RemoveContentCreatorSupportApiContentCreatorRemoveSupportPutApiArg = void;
export type GetSupportedContentCreatorApiContentCreatorSupportedContentCreatorGetApiResponse =
  /** status 200 Successful Response */ UserUsernameResponse | null;
export type GetSupportedContentCreatorApiContentCreatorSupportedContentCreatorGetApiArg = void;
export type GetSettingsApiUserSettingSettingsGetApiResponse =
  /** status 200 Successful Response */ UserSettingsModel;
export type GetSettingsApiUserSettingSettingsGetApiArg = void;
export type UpdateSettingsApiUserSettingSettingsPutApiResponse =
  /** status 200 Successful Response */ UserSettingsModel;
export type UpdateSettingsApiUserSettingSettingsPutApiArg = {
  userSettingsUpdateRequest: UserSettingsUpdateRequest;
};
export type CreateEventEventsCreatePostApiResponse = /** status 201 Successful Response */ any;
export type CreateEventEventsCreatePostApiArg = {
  eventCreateRequest: EventCreateRequest;
};
export type GetEventEventsEventIdGetApiResponse =
  /** status 200 Successful Response */ EventDetailResponse;
export type GetEventEventsEventIdGetApiArg = {
  eventId: string;
};
export type UpdateEventEventsEventsEventIdPutApiResponse =
  /** status 200 Successful Response */ EventDetailResponse;
export type UpdateEventEventsEventsEventIdPutApiArg = {
  eventId: string;
  eventUpdateRequest: EventUpdateRequest;
};
export type DeleteEventEventsEventsEventIdDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteEventEventsEventsEventIdDeleteApiArg = {
  eventId: string;
};
export type GetCalendarEventsDashboardCalendarGetApiResponse =
  /** status 200 Successful Response */ CalendarResponse;
export type GetCalendarEventsDashboardCalendarGetApiArg = {
  /** Start date for the calendar range */
  startDate: string;
  /** End date for the calendar range */
  endDate: string;
};
export type GetEventInvitationsEventsDashboardInvitationsEventGetApiResponse =
  /** status 200 Successful Response */ EventInvitationResponse[];
export type GetEventInvitationsEventsDashboardInvitationsEventGetApiArg = void;
export type GetUpcomingEventsEventsDashboardUpcomingEventGetApiResponse =
  /** status 200 Successful Response */ UpcomingEventResponse[];
export type GetUpcomingEventsEventsDashboardUpcomingEventGetApiArg = {
  /** Date for which to retrieve upcoming events */
  date: string;
};
export type CreateEventUserApiEventsUserCreateUpdatePostApiResponse =
  /** status 200 Successful Response */ any;
export type CreateEventUserApiEventsUserCreateUpdatePostApiArg = {
  eventUserCreateUpdateRequest: EventUserCreateUpdateRequest;
};
export type DiscoverEventsEventsDiscoverDiscoverGetApiResponse =
  /** status 200 Successful Response */ DiscoverEventResponse[];
export type DiscoverEventsEventsDiscoverDiscoverGetApiArg = {
  available?: boolean | null;
  clan?: boolean;
  todays?: boolean;
  title?: string | null;
};
export type ListUsersApiSystemAdminUserListGetApiResponse =
  /** status 200 Successful Response */ AdminUserListResponse;
export type ListUsersApiSystemAdminUserListGetApiArg = {
  email?: string | null;
  username?: string | null;
  steamId?: string | null;
  createdAtAfter?: string | null;
  createdAtBefore?: string | null;
  sortBy?: string | null;
  sortOrder?: SortOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type GetUsersCountApiSystemAdminUserCountGetApiResponse =
  /** status 200 Successful Response */ AdminUserCountResponse;
export type GetUsersCountApiSystemAdminUserCountGetApiArg = void;
export type GetUserApiSystemAdminUserUserIdGetApiResponse =
  /** status 200 Successful Response */ AdminUserQueueTasksResponse;
export type GetUserApiSystemAdminUserUserIdGetApiArg = {
  userId: string;
};
export type UpdateUserApiSystemAdminUserUserIdPutApiResponse =
  /** status 200 Successful Response */ AdminGetUpdateUserResponse;
export type UpdateUserApiSystemAdminUserUserIdPutApiArg = {
  userId: string;
  country?: number | null;
  email?: string;
  username?: string;
  steamId?: string | null;
  bio?: string | null;
  matchCode?: string | null;
  authCode?: string | null;
};
export type DeleteUserApiSystemAdminUserUserIdDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteUserApiSystemAdminUserUserIdDeleteApiArg = {
  userId: string;
};
export type RestoreUserApiSystemAdminUserRestoreUserIdPutApiResponse =
  /** status 204 Successful Response */ void;
export type RestoreUserApiSystemAdminUserRestoreUserIdPutApiArg = {
  userId: string;
};
export type LoginAsUserApiSystemAdminUserLoginAsUserIdGetApiResponse =
  /** status 200 Successful Response */ UserToken;
export type LoginAsUserApiSystemAdminUserLoginAsUserIdGetApiArg = {
  userId: string;
};
export type UserRefreshSteamDataApiSystemAdminUserSteamRefreshUserIdPutApiResponse =
  /** status 200 Successful Response */ any;
export type UserRefreshSteamDataApiSystemAdminUserSteamRefreshUserIdPutApiArg = {
  userId: string;
};
export type GetAdminApiSystemAdminAdminAdminIdGetApiResponse =
  /** status 200 Successful Response */ AdminResponse;
export type GetAdminApiSystemAdminAdminAdminIdGetApiArg = {
  adminId: string;
};
export type TicketListApiSystemAdminTicketListGetApiResponse =
  /** status 200 Successful Response */ AdminTicketListPaginatedResponse;
export type TicketListApiSystemAdminTicketListGetApiArg = {
  page?: number | null;
  pageSize?: number | null;
  category?: number | null;
  status?: number | null;
  priority?: number | null;
  sortBy?: AdminTicketListSortBy | null;
  sortOrder?: SortOrder | null;
};
export type TicketCategoriesListApiSystemAdminTicketCategoriesGetApiResponse =
  /** status 200 Successful Response */ AdminTicketCategoriesPaginatedResponse;
export type TicketCategoriesListApiSystemAdminTicketCategoriesGetApiArg = {
  /** Page number */
  page?: number | null;
  /** Page size */
  pageSize?: number | null;
};
export type TicketPrioritiesListApiSystemAdminTicketPrioritiesGetApiResponse =
  /** status 200 Successful Response */ AdminTicketPrioritiesPaginatedResponse;
export type TicketPrioritiesListApiSystemAdminTicketPrioritiesGetApiArg = {
  /** Page number */
  page?: number | null;
  /** Page size */
  pageSize?: number | null;
};
export type TicketStatusesListApiSystemAdminTicketStatusesGetApiResponse =
  /** status 200 Successful Response */ AdminTicketStatusesPaginatedResponse;
export type TicketStatusesListApiSystemAdminTicketStatusesGetApiArg = {
  /** Page number */
  page?: number | null;
  /** Page size */
  pageSize?: number | null;
};
export type TicketDetailApiSystemAdminTicketTicketIdGetApiResponse =
  /** status 200 Successful Response */ AdminTicketDetailResponse;
export type TicketDetailApiSystemAdminTicketTicketIdGetApiArg = {
  ticketId: string;
};
export type UpdateTicketApiSystemAdminTicketTicketIdPutApiResponse =
  /** status 200 Successful Response */ AdminTicketDetailResponse;
export type UpdateTicketApiSystemAdminTicketTicketIdPutApiArg = {
  ticketId: string;
  title?: string | null;
  category?: number | null;
  priority?: number | null;
  status?: number | null;
};
export type CreateTicketMessageApiSystemAdminTicketTicketIdAddMessagesPostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateTicketMessageApiSystemAdminTicketTicketIdAddMessagesPostApiArg = {
  ticketId: string;
  content: string;
  bodyCreateTicketMessageApiSystemAdminTicketTicketIdAddMessagesPost: BodyCreateTicketMessageApiSystemAdminTicketTicketIdAddMessagesPost;
};
export type GetHighlightListApiSystemAdminHighlightListGetApiResponse =
  /** status 200 Successful Response */ AdminHighlightsListResponse;
export type GetHighlightListApiSystemAdminHighlightListGetApiArg = {
  page?: number | null;
  pageSize?: number | null;
  createdAt?: string | null;
  isAutomatic?: boolean | null;
  name?: string | null;
  sortBy?: string | null;
  sortOrder?: SortOrder | null;
};
export type GetCountApiSystemAdminHighlightCountGetApiResponse =
  /** status 200 Successful Response */ AdminHighlightCountResponse;
export type GetCountApiSystemAdminHighlightCountGetApiArg = void;
export type DownloadDemoApiSystemAdminDemoDemoHashDownloadGetApiResponse =
  /** status 200 Successful Response */ void;
export type DownloadDemoApiSystemAdminDemoDemoHashDownloadGetApiArg = {
  /** The hash of the demo to download */
  demoHash: string;
};
export type GetUploadTypeChoicesApiSystemAdminDemoUploadTypesGetApiResponse =
  /** status 200 Successful Response */ UploadType[];
export type GetUploadTypeChoicesApiSystemAdminDemoUploadTypesGetApiArg = void;
export type GetDemoStatusChoicesApiSystemAdminDemoDemoStatusesGetApiResponse =
  /** status 200 Successful Response */ DemoStatus[];
export type GetDemoStatusChoicesApiSystemAdminDemoDemoStatusesGetApiArg = void;
export type GetDemoCountApiSystemAdminDemoCountGetApiResponse =
  /** status 200 Successful Response */ AdminDemoCountResponse;
export type GetDemoCountApiSystemAdminDemoCountGetApiArg = void;
export type GetDemoCountByTypeApiSystemAdminDemoCountByTypeGetApiResponse =
  /** status 200 Successful Response */ AdminDemoCountByTypeResponse;
export type GetDemoCountByTypeApiSystemAdminDemoCountByTypeGetApiArg = void;
export type GetDemoListApiSystemAdminDemoListGetApiResponse =
  /** status 200 Successful Response */ AdminDemoListResponse;
export type GetDemoListApiSystemAdminDemoListGetApiArg = {
  page?: number;
  pageSize?: number;
  status?: DemoStatus | null;
  uploadType?: UploadType | null;
  username?: string | null;
  email?: string | null;
  demoHash?: string | null;
  sortBy?: string | null;
  sortOrder?: SortOrder | null;
};
export type GetAdminDemoDetailApiSystemAdminDemoDetailDemoHashGetApiResponse =
  /** status 200 Successful Response */ AdminDemoDetailResponse;
export type GetAdminDemoDetailApiSystemAdminDemoDetailDemoHashGetApiArg = {
  /** The hash of the demo */
  demoHash: string;
};
export type UpdateAdminDemoApiSystemAdminDemoDemoHashPutApiResponse =
  /** status 200 Successful Response */ AdminDemoDetailResponse;
export type UpdateAdminDemoApiSystemAdminDemoDemoHashPutApiArg = {
  /** The hash of the demo to update */
  demoHash: string;
  status?: DemoStatus | null;
  originName?: string | null;
  size?: number | null;
  filePath?: string | null;
  analysisVersion?: string | null;
  parserVersion?: string | null;
  gameVersion?: string | null;
  demoVersion?: string | null;
  tickRate?: number | null;
  createdAt?: string | null;
  playedAt?: string | null;
  processedAt?: string | null;
};
export type DeleteAdminDemoApiSystemAdminDemoDemoHashDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteAdminDemoApiSystemAdminDemoDemoHashDeleteApiArg = {
  /** The hash of the demo to delete */
  demoHash: string;
};
export type ListContentCreatorsApiSystemAdminContentCreatorListGetApiResponse =
  /** status 200 Successful Response */ AdminContentCreatorDetailResponse;
export type ListContentCreatorsApiSystemAdminContentCreatorListGetApiArg = {
  page?: number | null;
  pageSize?: number | null;
  username?: string | null;
  code?: string | null;
  email?: string | null;
  moreThanXSupporters?: number | null;
  sortBy?: AdminContentCreatorListSortBy | null;
  sortOrder?: SortOrder | null;
};
export type DeleteContentCreatorApiSystemAdminContentCreatorDeleteCreatorIdDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteContentCreatorApiSystemAdminContentCreatorDeleteCreatorIdDeleteApiArg = {
  creatorId: string;
};
export type ListContentCreatorSupportersApiSystemAdminContentCreatorSupporterCreatorIdSupportersGetApiResponse =
  /** status 200 Successful Response */ AdminContentCreatorSupportersResponse;
export type ListContentCreatorSupportersApiSystemAdminContentCreatorSupporterCreatorIdSupportersGetApiArg =
  {
    creatorId: string;
    page?: number | null;
    pageSize?: number | null;
    history?: boolean | null;
  };
export type GetContentCreatorDetailApiSystemAdminContentCreatorCreatorIdGetApiResponse =
  /** status 200 Successful Response */ AdminUserContentCreatorResponse;
export type GetContentCreatorDetailApiSystemAdminContentCreatorCreatorIdGetApiArg = {
  creatorId: string;
};
export type ListContentCreatorApplicationsApiSystemAdminContentCreatorApplicationListGetApiResponse =
  /** status 200 Successful Response */ AdminUserContentCreatorApplicationListResponse;
export type ListContentCreatorApplicationsApiSystemAdminContentCreatorApplicationListGetApiArg = {
  page?: number | null;
  pageSize?: number | null;
  username?: string | null;
  status?: UserContentCreatorStatus | null;
};
export type GetContentCreatorApplicationApiSystemAdminContentCreatorApplicationApplicationIdGetApiResponse =
  /** status 200 Successful Response */ AdminUserContentCreatorApplicationDetailResponse;
export type GetContentCreatorApplicationApiSystemAdminContentCreatorApplicationApplicationIdGetApiArg =
  {
    applicationId: number;
  };
export type ApproveContentCreatorApplicationApiSystemAdminContentCreatorApplicationApproveApplicationIdPutApiResponse =
  /** status 200 Successful Response */ any;
export type ApproveContentCreatorApplicationApiSystemAdminContentCreatorApplicationApproveApplicationIdPutApiArg =
  {
    applicationId: number;
  };
export type RejectContentCreatorApplicationApiSystemAdminContentCreatorApplicationRejectApplicationIdPutApiResponse =
  /** status 200 Successful Response */ AdminUserContentCreatorApplicationRejectResponse;
export type RejectContentCreatorApplicationApiSystemAdminContentCreatorApplicationRejectApplicationIdPutApiArg =
  {
    applicationId: number;
    notApprovedReason: string | null;
  };
export type CreateClanRoleApiSystemAdminTaxonomyClanRolePostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreateClanRoleApiSystemAdminTaxonomyClanRolePostApiArg = {
  name: string;
};
export type GetClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdGetApiArg = {
  clanRoleId: number;
};
export type UpdateClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdateClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdPutApiArg = {
  clanRoleId: number;
  name: string;
};
export type CreatePremiumApiSystemAdminTaxonomyPremiumTypePostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreatePremiumApiSystemAdminTaxonomyPremiumTypePostApiArg = {
  name: string;
};
export type GetPremiumApiSystemAdminTaxonomyPremiumTypePremiumIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetPremiumApiSystemAdminTaxonomyPremiumTypePremiumIdGetApiArg = {
  premiumId: number;
};
export type UpdatePremiumApiSystemAdminTaxonomyPremiumTypePremiumIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdatePremiumApiSystemAdminTaxonomyPremiumTypePremiumIdPutApiArg = {
  premiumId: number;
  name: string;
};
export type CreateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformPostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformPostApiArg =
  {
    name: string;
  };
export type GetContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdGetApiArg =
  {
    contentCreatorPlatformId: number;
  };
export type UpdateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdPutApiArg =
  {
    contentCreatorPlatformId: number;
    name: string;
  };
export type CreateDemoTypeApiSystemAdminTaxonomyDemoTypePostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreateDemoTypeApiSystemAdminTaxonomyDemoTypePostApiArg = {
  name: string;
};
export type GetDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdGetApiArg = {
  demoTypeId: number;
};
export type UpdateDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdateDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdPutApiArg = {
  demoTypeId: number;
  name: string;
};
export type CreateDemoModeApiSystemAdminTaxonomyDemoModePostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreateDemoModeApiSystemAdminTaxonomyDemoModePostApiArg = {
  name: string;
};
export type GetDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdGetApiArg = {
  demoModeId: number;
};
export type UpdateDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdateDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdPutApiArg = {
  demoModeId: number;
  name: string;
};
export type CreateUserMatchRoleApiSystemAdminTaxonomyUserMatchRolePostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreateUserMatchRoleApiSystemAdminTaxonomyUserMatchRolePostApiArg = {
  name: string;
};
export type GetUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdGetApiArg = {
  userMatchRoleId: number;
};
export type UpdateUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdateUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdPutApiArg = {
  userMatchRoleId: number;
  name: string;
};
export type CreateEventPublicityApiSystemAdminTaxonomyEventPublicityPostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreateEventPublicityApiSystemAdminTaxonomyEventPublicityPostApiArg = {
  name: string;
};
export type GetEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdGetApiArg = {
  eventPublicityId: number;
};
export type UpdateEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdateEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdPutApiArg = {
  eventPublicityId: number;
  name: string;
};
export type CreateEventTypeApiSystemAdminTaxonomyEventTypePostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreateEventTypeApiSystemAdminTaxonomyEventTypePostApiArg = {
  name: string;
};
export type GetEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdGetApiArg = {
  eventTypeId: number;
};
export type UpdateEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdateEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdPutApiArg = {
  eventTypeId: number;
  name: string;
};
export type CreateTicketStatusApiSystemAdminTaxonomyTicketStatusPostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreateTicketStatusApiSystemAdminTaxonomyTicketStatusPostApiArg = {
  name: string;
};
export type GetTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdGetApiArg = {
  ticketStatusId: number;
};
export type UpdateTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdateTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdPutApiArg = {
  ticketStatusId: number;
  name: string;
};
export type CreateTicketPriorityApiSystemAdminTaxonomyTicketPriorityPostApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type CreateTicketPriorityApiSystemAdminTaxonomyTicketPriorityPostApiArg = {
  name: string;
};
export type GetTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdGetApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type GetTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdGetApiArg = {
  ticketPriorityId: number;
};
export type UpdateTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdPutApiResponse =
  /** status 200 Successful Response */ AdminTaxonomyCreateUpdateResponse;
export type UpdateTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdPutApiArg = {
  ticketPriorityId: number;
  name: string;
};
export type CreateTicketCategoryApiSystemAdminTaxonomyTicketCategoryPostApiResponse =
  /** status 200 Successful Response */ TicketCategoryResponse;
export type CreateTicketCategoryApiSystemAdminTaxonomyTicketCategoryPostApiArg = {
  name: string;
  parentCategoryId?: number | null;
};
export type GetTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdGetApiResponse =
  /** status 200 Successful Response */ TicketCategoryResponse;
export type GetTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdGetApiArg = {
  ticketCategoryId: number;
};
export type UpdateTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdPutApiResponse =
  /** status 200 Successful Response */ TicketCategoryResponse;
export type UpdateTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdPutApiArg = {
  ticketCategoryId: number;
  name: string;
  parentCategoryId?: number | null;
};
export type GetUploadQueueTaskListApiSystemAdminQueueUploadListGetApiResponse =
  /** status 200 Successful Response */ DemoQueueResponseListModel;
export type GetUploadQueueTaskListApiSystemAdminQueueUploadListGetApiArg = {
  page?: number | null;
  pageSize?: number | null;
  status?: QueueStatus | null;
  sortBy?: QueueSortBy | null;
  sortOrder?: SortOrder | null;
};
export type GetDownloadQueueTaskListApiSystemAdminQueueDownloadListGetApiResponse =
  /** status 200 Successful Response */ AutoDownloadQueueResponseListModel;
export type GetDownloadQueueTaskListApiSystemAdminQueueDownloadListGetApiArg = {
  page?: number | null;
  pageSize?: number | null;
  status?: QueueStatus | null;
  sortBy?: QueueSortBy | null;
  sortOrder?: SortOrder | null;
};
export type GetHighlightQueueTaskListApiSystemAdminQueueHighlightListGetApiResponse =
  /** status 200 Successful Response */ HighlightQueueResponseListModel;
export type GetHighlightQueueTaskListApiSystemAdminQueueHighlightListGetApiArg = {
  page?: number | null;
  pageSize?: number | null;
  status?: QueueStatus | null;
  sortBy?: QueueSortBy | null;
  sortOrder?: SortOrder | null;
};
export type GetQueueDashboardApiSystemAdminQueueDashboardGetApiResponse =
  /** status 200 Successful Response */ QueueDashboardResponse;
export type GetQueueDashboardApiSystemAdminQueueDashboardGetApiArg = void;
export type MoveQueueTaskApiSystemAdminQueueMoveTaskPostApiResponse =
  /** status 200 Successful Response */ any | /** status 201 Created */ void;
export type MoveQueueTaskApiSystemAdminQueueMoveTaskPostApiArg = {
  queue?: QueueTypes;
  newQueue?: QueueTypes;
  taskId: number;
};
export type ListUserTicketsApiTicketsAllGetApiResponse =
  /** status 200 Successful Response */ TicketListResponse[];
export type ListUserTicketsApiTicketsAllGetApiArg = void;
export type CreateTicketApiTicketsCreatePostApiResponse = /** status 201 Successful Response */ any;
export type CreateTicketApiTicketsCreatePostApiArg = {
  title: string;
  category: number;
  content: string;
  bodyCreateTicketApiTicketsCreatePost: BodyCreateTicketApiTicketsCreatePost;
};
export type CreateTicketMessageApiTicketsTicketIdAddUserMessagePostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateTicketMessageApiTicketsTicketIdAddUserMessagePostApiArg = {
  ticketId: string;
  content: string;
  bodyCreateTicketMessageApiTicketsTicketIdAddUserMessagePost: BodyCreateTicketMessageApiTicketsTicketIdAddUserMessagePost;
};
export type GetTicketApiTicketsTicketIdGetApiResponse =
  /** status 200 Successful Response */ TicketDetailResponse;
export type GetTicketApiTicketsTicketIdGetApiArg = {
  ticketId: string;
};
export type UpdateTicketMessageRatingApiTicketsMessageTicketMessageIdRatingPutApiResponse =
  /** status 200 Successful Response */ TicketMessageResponse;
export type UpdateTicketMessageRatingApiTicketsMessageTicketMessageIdRatingPutApiArg = {
  ticketMessageId: number;
  rating: number;
  ratingMessage: string;
};
export type GetTicketMessageAttachmentsApiTicketsmessageTicketMessageIdAttachmentsGetApiResponse =
  /** status 200 Successful Response */ void;
export type GetTicketMessageAttachmentsApiTicketsmessageTicketMessageIdAttachmentsGetApiArg = {
  ticketMessageId: number;
};
export type GetTicketMessageFileApiTicketsfileTicketMessageFileIdAttachmentsGetApiResponse =
  /** status 200 Successful Response */ void;
export type GetTicketMessageFileApiTicketsfileTicketMessageFileIdAttachmentsGetApiArg = {
  ticketMessageFileId: number;
};
export type GetCountriesApiUserCountriesGetApiResponse =
  /** status 200 Successful Response */ CountryPaginatedResponse;
export type GetCountriesApiUserCountriesGetApiArg = void;
export type HomeGetApiResponse = /** status 200 Successful Response */ any;
export type HomeGetApiArg = void;
export type ResultEnum = 'VICTORY' | 'DRAW' | 'LOSS';
export type MatchMode = 'WINGMAN' | 'COMPETITIVE' | 'PREMIER' | 'UNKNOWN';
export type DemoHeaderMatchInfo = {
  demo_id: string;
  demo_hash: string;
  map_name: string;
  score: string;
  created_at: string;
  my_result: ResultEnum;
  mode: MatchMode;
};
export type DemoHeaderUserRankPlayersPositionsInfo = {
  player_name: string;
  total_rank: number;
  position: number;
  is_my_team: boolean;
};
export type DemoHeaderUserRankInfo = {
  position: number;
  position_average: number;
  total_rank: number;
  total_rank_average: number;
  quantitative_rank: number;
  quantitative_rank_average: number;
  quantitative_rank_total: number;
  total_rank_history: number[];
  players_positions: DemoHeaderUserRankPlayersPositionsInfo[];
};
export type LastHighlightDemoHeader = {
  hash: string;
  url: string;
  name: string;
  created_at: string;
  length?: number | null;
  /** Number of likes of the highlight. If the highlight does not have any like, it will be None. */
  likes_count?: number | null;
  /** True if the user liked the highlight, otherwise False. If the user did not like or dislike the highlight, it will be None. */
  is_liked?: boolean | null;
  highlightId: string;
  estimated_at?: string | null;
};
export type DemoHeaderResponse = {
  match_: DemoHeaderMatchInfo;
  user_rank: DemoHeaderUserRankInfo;
  last_highlight?: LastHighlightDemoHeader | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type AimCurrentAverageStatBaseModel = {
  average_damage_per_round: number;
  headshot_rate: number | null;
};
export type AimStatModel = {
  average_damage_per_round: number;
  headshot_rate: number | null;
  aim_current_average_stat: AimCurrentAverageStatBaseModel;
};
export type PlayerKillDeathRatioDemoDetail = {
  kill_death_ratio: number;
};
export type PlayerKillDeathRatioAverageDemoDetailModel = {
  kill_death_ratio: number;
  player_kill_death_current_average_stat: PlayerKillDeathRatioDemoDetail;
};
export type RankingBaseModel = {
  aim_rank: number;
  utility_rank: number;
  movement_rank: number;
  dick_factor_rank: number;
  sixth_sense_rank: number;
  total_rank: number;
  position: number;
  weighted_total_rank: number;
  quantitative_rank: number;
};
export type QuantitativeScoreRankingBaseModel = {
  id: number;
  match_played: number;
  match_result: number;
  kills: number;
  deaths: number;
  assists: number;
  round_survived: number;
  team_kills: number;
  bomb_plant: number;
  bomb_defuse: number;
  weapon_drop: number;
  messages: number;
};
export type RankingMatchDetailModel = {
  aim_rank: number;
  utility_rank: number;
  movement_rank: number;
  dick_factor_rank: number;
  sixth_sense_rank: number;
  total_rank: number;
  position: number;
  weighted_total_rank: number;
  quantitative_rank: number;
  ranking_current_average: RankingBaseModel | null;
  quantitative_score_ranking: QuantitativeScoreRankingBaseModel | null;
};
export type StatPropertyBaseModel = {
  id: number;
  name: string;
  description: string;
  unit: string;
};
export type StrengthWeaknessCommonMatchDetailModel = {
  stat_property: StatPropertyBaseModel;
  average: number;
  current: number;
};
export type StrengthWeaknessCommonMatchDetailModelRead = {
  stat_property: StatPropertyBaseModel;
  average: number;
  current: number;
  name: string;
  description: string;
  unit: string;
};
export type SkillRatingMapHistoryMatchStatDetailModel = {
  result: string;
  total_rank: number;
};
export type PlayerMatchStatDetailModel = {
  player_name: string;
  aim_stat: AimStatModel;
  player_kill_death_stat: PlayerKillDeathRatioAverageDemoDetailModel;
  ranking?: RankingMatchDetailModel;
  my_steam_id?: string | null;
  strengths?: StrengthWeaknessCommonMatchDetailModel[];
  weaknesses?: StrengthWeaknessCommonMatchDetailModel[];
  avg_dmg_history?: number[];
  skill_rating_map_history?: SkillRatingMapHistoryMatchStatDetailModel[];
};
export type PlayerMatchStatDetailModelRead = {
  player_name: string;
  aim_stat: AimStatModel;
  player_kill_death_stat: PlayerKillDeathRatioAverageDemoDetailModel;
  ranking?: RankingMatchDetailModel;
  my_steam_id?: string | null;
  strengths?: StrengthWeaknessCommonMatchDetailModelRead[];
  weaknesses?: StrengthWeaknessCommonMatchDetailModelRead[];
  avg_dmg_history?: number[];
  skill_rating_map_history?: SkillRatingMapHistoryMatchStatDetailModel[];
};
export type PlayerKillDeathStatBaseModel = {
  kill_death_ratio: number;
  death_per_round_ratio: number;
  average_survival_time: number;
  average_bullets_to_my_death: number;
  unready_for_death: number;
};
export type AveragePlayerKillDeathStatModel = {
  kill_death_ratio: number;
  death_per_round_ratio: number;
  average_survival_time: number;
  average_bullets_to_my_death: number;
  unready_for_death: number;
  player_kill_death_current_average_stat: PlayerKillDeathStatBaseModel;
};
export type PlayerAttackStat = {
  id: number;
  damage: number;
  kills: number;
  assists: number;
  player_name: string;
  is_friendly: boolean;
  attacker_team: string;
  steam_id: string;
};
export type RoundEndType =
  | 'INVALID'
  | 'BOMB_DETONATED'
  | 'BOMB_DEFUSED'
  | 'CT_WIN'
  | 'T_WIN'
  | 'TARGET_SAVED'
  | 'T_SURRENDER'
  | 'CT_SURRENDER'
  | 'ALL_HOSTAGES_RESCUED'
  | 'HOSTAGES_NOT_RESCUED';
export type RoundStatBaseModel = {
  id: number;
  number: number;
  length: number;
  start_tick: number;
  official_end_tick: number;
  freeze_time_end_tick: number;
  end_tick: number;
  round_end_type: RoundEndType | null;
};
export type ItemModel = {
  id: number;
  alias: string;
};
export type PlayerMatchStatBaseModel = {
  player_name: string;
};
export type PlayerDeathDamageStatListModel = {
  id: number;
  attacker: PlayerMatchStatBaseModel | null;
  item: ItemModel | null;
  time_from_start: number;
  health_damage: number;
  vest_damage: number;
};
export type PlayerDeathRoundDamageStatModel = {
  id: number;
  traded: boolean;
  easy_prey: boolean;
  teammates_alive_before_action: number;
  enemies_alive_before_action: number;
  time_from_first_damage_to_death: number;
  tick_number: number;
  player_item: ItemModel;
  enemy_item: ItemModel | null;
  player_death_damage_stats: PlayerDeathDamageStatListModel[];
};
export type RoundDeathModel = {
  tick: number;
  victim: PlayerMatchStatBaseModel;
  enemy_item: ItemModel | null;
  team: string;
};
export type RoundDeathModelRead = {
  tick: number;
  victim: PlayerMatchStatBaseModel;
  enemy_item: ItemModel | null;
  team: string;
  victim_name: string;
  item_alias: string | null;
};
export type DeathRoundModel = {
  round_stat: RoundStatBaseModel;
  player_death_round_stat: PlayerDeathRoundDamageStatModel;
  round_deaths?: RoundDeathModel[];
  team?: string;
};
export type DeathRoundModelRead = {
  round_stat: RoundStatBaseModel;
  player_death_round_stat: PlayerDeathRoundDamageStatModel;
  round_deaths?: RoundDeathModelRead[];
  team?: string;
  /** My death tick property.
    
    Returns:
        int: Returns my death tick in round. */
  my_death_tick: number;
};
export type UserDemoMyDeathResponse = {
  id: number;
  player_kill_death_stat: AveragePlayerKillDeathStatModel;
  player_attack_stats: PlayerAttackStat[];
  player_death_rounds: DeathRoundModel[];
};
export type UserDemoMyDeathResponseRead = {
  id: number;
  player_kill_death_stat: AveragePlayerKillDeathStatModel;
  player_attack_stats: PlayerAttackStat[];
  player_death_rounds: DeathRoundModelRead[];
};
export type Duel = {
  victim_name: string;
  victim_steam_id: string;
  attacker_kills: number;
  attacker_entry_kills: number;
  victim_kills: number;
  victim_entry_kills: number;
};
export type DuelModel = {
  attacker_name: string;
  attacker_steam_id: string;
  duels: Duel[];
};
export type TeamStat = {
  id: number;
  team_name: string;
  result: string;
  kills: number;
  deaths: number;
  score: number;
};
export type UserDemoDuelResponse = {
  duels: DuelModel[];
  team_stats: TeamStat[];
};
export type UserDemoPlayerResponse = {
  id: number;
  player_name: string;
  steam_id: string;
};
export type PlayerRoundInventoryItem = {
  is_main: boolean;
  alias: string;
};
export type PlayerRoundStat = {
  is_alive: boolean;
  player_name: string;
  player_round_inventory_stats: PlayerRoundInventoryItem[];
};
export type EconomyTeamRoundStat = {
  money_before_buy: number;
  money_after_buy: number;
};
export type TeamRoundStatPlayer = {
  alive_count: number;
  player_count: number;
  end_score: number;
  is_ct: boolean;
  is_t: boolean;
  is_winner?: boolean | null;
  player_round_stats: PlayerRoundStat[];
  economy_team_round_stat: EconomyTeamRoundStat;
};
export type RoundStatTeam = {
  round_end_type?: RoundEndType | null;
  number: number;
  team_round_stats: TeamRoundStatPlayer[];
};
export type UserDemoRoundResponse = {
  mode?: MatchMode | null;
  team_stats: TeamStat[];
  round_stats: RoundStatTeam[];
};
export type PlayerMatchStatScoreboard = {
  player_name: string;
  steam_id: string;
  competitive_rank?: number | null;
  premier_rating?: number | null;
  kills: number;
  deaths: number;
  assists: number;
  kast: number;
  average_damage_per_round: number;
  kill_death_ratio: number;
  headshot_rate?: number | null;
  quantitative_rank: number;
  total_rank: string;
  sixth_sense_rank: string;
};
export type UserDemoScoreboardResponse = {
  player_matches: PlayerMatchStatScoreboard[];
  team_name: string;
  score: number;
};
export type AimRanking = {
  average_damage_per_round?: number | null;
  headshot_rate?: number | null;
  accuracy_with_bullets?: number | null;
  tap_accuracy?: number | null;
  burst_accuracy?: number | null;
  spray_accuracy?: number | null;
  tap_kill_rate?: number | null;
  burst_kill_rate?: number | null;
  spray_kill_rate?: number | null;
};
export type UtilityRanking = {
  smoke_buys_ratio?: number | null;
  smoke_unused_if_death_ratio?: number | null;
  smoke_unused_if_alive_ratio?: number | null;
  flash_buys_ratio?: number | null;
  flash_unused_if_death_ratio?: number | null;
  flash_unused_if_alive_ratio?: number | null;
  flashed_enemies_ratio?: number | null;
  flashed_teammates_ratio?: number | null;
  flashed_myself_ratio?: number | null;
  flash_blind_duration?: number | null;
  flash_leading_to_kill_ratio?: number | null;
  fire_buys_ratio?: number | null;
  fire_unused_if_death_ratio?: number | null;
  fire_unused_if_alive_ratio?: number | null;
  fire_enemy_damage_dealt?: number | null;
  fire_teammate_damage_dealt?: number | null;
  fire_self_damage_dealt?: number | null;
  he_buys_ratio?: number | null;
  he_unused_if_death_ratio?: number | null;
  he_unused_if_alive_ratio?: number | null;
  he_enemy_damage_dealt?: number | null;
  he_teammate_damage_dealt?: number | null;
  he_self_damage_dealt?: number | null;
};
export type MovementRanking = {
  counter_strafe_ratio?: number | null;
  moving_speed?: number | null;
  fire_damage_from_enemy?: number | null;
  he_damage_from_enemy?: number | null;
  flash_from_enemy?: number | null;
  one_key_movements?: number | null;
};
export type SixthSenseRanking = {
  clutches_kill_rate_1v1?: number | null;
  clutches_kill_rate_1v2?: number | null;
  clutches_kill_rate_1v3?: number | null;
  clutches_kill_rate_1v4?: number | null;
  clutches_kill_rate_1v5?: number | null;
  clutches_player_saved_1v1?: number | null;
  clutches_player_saved_1v2?: number | null;
  clutches_player_saved_1v3?: number | null;
  clutches_player_saved_1v4?: number | null;
  clutches_player_saved_1v5?: number | null;
  disadvantage_kill_rate?: number | null;
  disadvantage_safe_rate?: number | null;
  disadvantage_won_round?: number | null;
  ap_ct_even_play_kill_rate?: number | null;
  ap_ct_even_play_successful_retake?: number | null;
  ap_ct_power_play_kill_rate?: number | null;
  ap_ct_power_play_successful_retake?: number | null;
  ap_ct_dis_play_kill_rate?: number | null;
  ap_ct_dis_play_successful_retake?: number | null;
  ap_ct_very_dis_play_kill_rate?: number | null;
  ap_ct_very_dis_play_successful_retake?: number | null;
  ap_t_side_kill_rate?: number | null;
  ap_t_side_round_win?: number | null;
  trade_kills?: number | null;
};
export type DickFactorRanking = {
  death_with_knife_ratio?: number | null;
  death_with_utility_ratio?: number | null;
  death_with_no_ammo_ratio?: number | null;
  death_when_switch_ratio?: number | null;
  fall_damage?: number | null;
  friendly_damage?: number | null;
  not_meta_weapon?: number | null;
  purchases_after_freeze_time?: number | null;
  afk_on_start?: number | null;
  noisy?: number | null;
  left_game?: number | null;
  griefing?: number | null;
};
export type UserDemoRankingResponse = {
  aim_ranking: AimRanking;
  utility_ranking: UtilityRanking;
  movement_ranking: MovementRanking;
  sixth_sense_ranking: SixthSenseRanking;
  dick_factor_ranking: DickFactorRanking;
};
export type TutorialMatchesResponse = {
  player_name: string;
  quantitative_rank: number;
  total_rank: number;
  position: number;
  team_1_score: number;
  team_2_score: number;
  map_name: string;
  demo_id: string;
  steam_id: string;
  created_at: string;
  result: ResultEnum;
};
export type DemoStatus =
  | 'NEW'
  | 'PROCESSING'
  | 'FAILED'
  | 'SUCCESS'
  | 'QUEUED'
  | 'REQUEUED'
  | 'DISCARDED';
export type UserMatchResult = {
  id: string;
  is_favorite?: boolean | null;
  demo_id: string;
  map_name?: string | null;
  created_at: string;
  score?: string | null;
  quantitative_rank?: number | null;
  sixth_sense_rank?: number | null;
  total_rank?: number | null;
  my_result?: ResultEnum | null;
  is_tmp: boolean;
  alias: string;
  estimated_at?: string | null;
  status: DemoStatus;
};
export type UserMatchListResultsResponse = {
  results_count: number;
  count: number;
  results: UserMatchResult[];
};
export type SortOrder = 'ASC' | 'DESC';
export type UserMatchUpdateResponse = {
  id: string;
  is_favorite: boolean;
};
export type MatchCountResponse = {
  all_games: number;
  my_games: number;
};
export type BodyUploadDemosApiDemosUploadPost = {
  file: Blob;
  alias: string;
};
export type BodyAddMapNotesApiGamesMapNotesAddDemoIdPost = {
  screenshot?: Blob | null;
};
export type UserMinimalResponse = {
  id: string;
  username: string;
  avatar_path?: string | null;
};
export type MatchDemoMapNotesResponse = {
  id: number;
  match_demo_id: number;
  tick: number;
  note?: string | null;
  file_path?: string | null;
  user: UserMinimalResponse;
};
export type BodyUpdateMapNotesApiGamesMapNotesNoteIdPut = {
  screenshot?: Blob | null;
};
export type HighlightEventGeneration = {
  id: number;
  score: number | string;
  start_tick: number;
  end_tick: number;
};
export type ManualHighlightRequest = {
  highlights: HighlightEventGeneration[][];
  use_xray?: boolean;
};
export type HighlightSettingModelResponse = {
  is_for_all: boolean;
  resolution_id: number;
  frame_rate_id: number;
  video_quality_id: number;
  use_info_bar: boolean;
  use_kill_feed: boolean;
  use_video_transitions: boolean;
  use_hud: boolean;
  use_xray: boolean;
  dynamic_multi_kills: number;
  seconds_before: number;
  seconds_after: number;
};
export type AdminHighlightResolution = {
  id: number;
  name: string;
  width: number;
  height: number;
};
export type AdminHighlightFrameRate = {
  id: number;
  value: number;
};
export type AdminHighlightVideoQuality = {
  id: number;
  name: string;
};
export type HighlightRound = {
  id: number;
  round_number: number;
  round_length: number;
  start_tick: number;
  end_tick: number;
  official_end_tick: number;
  freeze_time_end_tick: number;
};
export type HighlightKillEventModel = {
  id: number;
  demo_id: string;
  item_id: number;
  attacker_id: number;
  victim_id: number;
  round_number: number;
  tick_number: number;
  score: string;
  time_after_start: number;
  alias: string;
  attacker_name: string;
  victim_name: string;
  attacker_steam_id: number;
  victim_steam_id: number;
};
export type HighlightAttackerEventModel = {
  kills: HighlightKillEventModel[];
  name: string;
};
export type HighlightTopThreeMoments = {
  id: number;
  round_number: number;
  tick_number: number;
  time_after_start: number;
  score: string;
  alias?: string | null;
  victim_name?: string | null;
  attacker_name?: string | null;
};
export type HighlightEventAndRoundResponse = {
  rounds: HighlightRound[];
  events: {
    [key: string]: HighlightAttackerEventModel;
  };
  top_three_moments: HighlightTopThreeMoments[];
  map_name: string | null;
};
export type HighlightUser = {
  id: string;
  username: string;
  steam_id?: string | null;
  avatar_path?: string | null;
};
export type HighlightResultList = {
  id: string;
  hash: string;
  url?: string | null;
  image_url?: string | null;
  name: string;
  is_automatic: boolean;
  created_at: string;
  length?: number | null;
  likes_count?: number | null;
  is_liked: boolean;
  user: HighlightUser;
  is_premade?: boolean;
};
export type HighlightsListResponse = {
  results_count: number;
  count: number;
  results: HighlightResultList[];
};
export type HighlightStatus = 'NEW' | 'PENDING' | 'PROCESSING' | 'FINISHED' | 'FAILED';
export type HighlightProcessingResultList = {
  id: string;
  hash: string;
  url?: string | null;
  image_url?: string | null;
  name: string;
  is_automatic: boolean;
  created_at: string;
  length?: number | null;
  likes_count?: number | null;
  is_liked: boolean;
  user: HighlightUser;
  is_premade?: boolean;
  status: HighlightStatus;
  estimated_at: string;
};
export type HighlightProcessingResponse = {
  results: HighlightProcessingResultList[];
};
export type HighlightFeedResponse = {
  newer_count: number;
  highlights: HighlightResultList[];
};
export type HighlightRenameRequest = {
  name: string;
};
export type HighlightDetailResponse = {
  hash: string;
  url?: string | null;
  image_url?: string | null;
  name: string;
  created_at: string;
  length?: number | null;
  likes_count?: number | null;
  user: HighlightUser;
};
export type ClanInvitationCreateRequest = {
  id: number;
  clan_id: number;
};
export type ClanInvitationCreateByClanRequest = {
  id: number;
  users: string[];
  clan_id: number;
};
export type ClanInvitationListResponse = {
  id: number;
  username: string;
  created_by_clan: boolean;
  created_at: string;
  avatar_path?: string | null;
};
export type ClanInvitationResponse = {
  id: number;
  clan_id: string;
  user_id: string;
  created_by_clan: boolean;
};
export type ClanPostResponse = {
  id: number;
  content: string;
  author_id: string;
  clan_id: string;
};
export type ClanPostCreateRequest = {
  id: number;
  content: string;
};
export type ClanPostListResponse = {
  id: number;
  content: string;
  username: string;
  avatar_path?: string | null;
  created_at: string;
};
export type IsAnyClanLeaderResponse = {
  is_any_clan_leader: boolean;
};
export type ClanUserRole = 'MEMBER' | 'OFFICER' | 'LEADER';
export type ClanRoleResponse = {
  id: number;
  role: ClanUserRole;
};
export type ClanUserPublicResponse = {
  id: number;
  username: string;
  avatar_path?: string | null;
};
export type ClanRankingList = {
  id: number;
  user_id?: string | null;
  username?: string | null;
  aim_rank?: number | null;
  utility_rank?: number | null;
  total_rank?: number | null;
  movement_rank?: number | null;
  dick_factor_rank?: number | null;
  order?: number | null;
  game_count?: number | null;
};
export type ClanUserMemberListPrivateResponse = {
  id: number;
  created_at: string;
  username: string;
  avatar?: string | null;
  user_id: string;
  role: ClanUserRole;
  ranking?: ClanRankingList | null;
};
export type SortByEnumClanPrivateMemberList =
  | 'aim_rank'
  | 'utility_rank'
  | 'total_rank'
  | 'movement_rank'
  | 'dick_factor_rank';
export type ClanUserResponse = {
  id: number;
  user_id: string;
  clan_id: string;
  role: ClanUserRole;
  created_at: string;
  avatar_path?: string | null;
};
export type ClanUserUpdateResponse = {
  id: number;
  role: ClanUserRole;
  created_at: string;
  username: string;
  avatar_path?: string | null;
  user_id: string;
};
export type OneClanInfo = {
  id: string;
  logo_path?: string | null;
  name: string;
  members_count?: number | null;
};
export type ClanPaginatedResponse = {
  results_count: number;
  count: number;
  results: OneClanInfo[];
};
export type ClanUserInfo = {
  id: number;
  name: string;
  logo_path?: string | null;
  members_count: number;
};
export type ClanUserListResponse = {
  results_count: number;
  count: number;
  results: ClanUserInfo[];
};
export type BodyCreateClanApiClansPost = {
  logo?: Blob | null;
};
export type ClanDetailResponse = {
  id: string;
  logo_path?: string | null;
  name: string;
  description: string;
  members_count: number;
};
export type BodyUpdateClanApiClansClanIdPut = {
  logo?: Blob | null;
};
export type CountryDetail = {
  id: number;
  name: string;
  iso: string;
};
export type RankingListResult = {
  total_rank: string;
  aim_rank: string;
  utility_rank: string;
  movement_rank: string;
  dick_factor_rank: string;
  sixth_sense_rank: string;
  quantitative_rank: number;
  order?: number | null;
  game_count: number;
  player_name: string;
  steam_id: string;
  country?: CountryDetail | null;
};
export type RankingSortBy =
  | 'aim_rank'
  | 'utility_rank'
  | 'total_rank'
  | 'movement_rank'
  | 'quantitative_rank'
  | 'dick_factor_rank'
  | 'sixth_sense_rank';
export type RankingListResponse = {
  results_count: number;
  count: number;
  results: RankingListResult[];
};
export type CountryName = {
  id: number;
  name: string;
};
export type UserLoginResponse = {
  id: number;
  last_login?: string | null;
  is_first_login: boolean;
};
export type UserResponse = {
  id: string;
  email: string;
  username: string;
  steam_id?: string | null;
  avatar_path?: string | null;
  bio?: string | null;
  country: CountryName;
  user_login: UserLoginResponse;
};
export type UserToken = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  user: UserResponse;
};
export type TokenRefreshRequest = {
  refresh_token: string;
};
export type BodyLoginForAccessTokenApiUserTokenPost = {
  grant_type?: string | null;
  username: string;
  password: string;
  scope?: string;
  client_id?: string | null;
  client_secret?: string | null;
};
export type AdminResponse = {
  id: string;
  email: string;
  username: string;
};
export type AdminToken = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  admin: AdminResponse;
};
export type BodyLoginForAdminAccessTokenApiUserTokenAdminPost = {
  grant_type?: string | null;
  username: string;
  password: string;
  scope?: string;
  client_id?: string | null;
  client_secret?: string | null;
};
export type BodyUploadAvatarApiUserUpdateAvatarPut = {
  file: Blob;
};
export type UserListPaginatedResponse = {
  results_count: number;
  count: number;
  results: UserMinimalResponse[];
};
export type UserUpdateRequest = {
  username?: string | null;
  country?: number | null;
  bio?: string | null;
};
export type LastMatchUserDashboard = {
  map_name: string;
  team_1_score: number;
  team_2_score: number;
  result: string;
  player_name: string;
  kill_death_ratio: number;
  kills: number;
  deaths: number;
  assists: number;
  total_rank: number;
  quantitative_rank: number;
  position: number;
};
export type WeaponUserDashboard = {
  alias: string;
  kills: number;
};
export type WeaponKillsUserDashboard = {
  primary_weapon?: WeaponUserDashboard | null;
  secondary_weapon?: WeaponUserDashboard | null;
};
export type StatsUserDashboard = {
  kill_death_ratio_history: number[];
  kill_death_ratio_average: number;
  headshot_ratio_history: number[];
  headshot_ratio_average: number;
  average_damage_per_round_history: number[];
  average_damage_per_round_average: number;
};
export type UserDashboard = {
  email?: string | null;
  avatar_path?: string | null;
};
export type UserDashboardResponse = {
  last_match: LastMatchUserDashboard;
  rankings: RankingListResult;
  weapon_kills: WeaponKillsUserDashboard;
  stats: StatsUserDashboard;
  user?: UserDashboard | null;
};
export type MapWinRateResponse = {
  map_name: string;
  match_played: number;
  average_damage_per_round: number;
  average_total_rank: number;
  map_victory_rate: number;
  ct_victory_rate?: number | null;
  t_victory_rate?: number | null;
};
export type UserRegisterRequest = {
  email: string;
  username: string;
  country: number;
  password: string;
};
export type ConfirmEmailRequest = {
  email: string;
  token: string;
};
export type ResendConfirmEmailRequest = {
  email: string;
};
export type ChangePasswordRequest = {
  old_password: string;
  password: string;
  password2: string;
};
export type ResetPasswordRequest = {
  email: string;
};
export type SetNewPasswordRequest = {
  password: string;
  password2: string;
  token: string;
};
export type SteamLoginDataRequest = {
  'openid.ns': string;
  'openid.mode': string;
  'openid.assoc_handle': string;
  'openid.claimed_id': string;
  'openid.identity': string;
  'openid.op_endpoint': string;
  'openid.response_nonce': string;
  'openid.return_to': string;
  'openid.sig': string;
  'openid.signed': string;
};
export type SteamCodeResponse = {
  steam_id?: string | null;
  match_code?: string | null;
  auth_code?: string | null;
};
export type SteamCodeUpdateRequest = {
  match_code?: string | null;
  auth_code?: string | null;
};
export type UserContentCreatorAddress = {
  id: number;
  country: number;
  address_line: string;
  address_line_2?: string | null;
  city: string;
  zip: string;
};
export type UserContentCreatorLink = {
  id: number;
  platform_id: number;
  link: string;
};
export type UserContentCreatorCreateResponse = {
  id: string;
  name: string;
  surname: string;
  birth_date: string;
  address: UserContentCreatorAddress;
  message: string;
  code: string;
  links: UserContentCreatorLink[];
};
export type UserContentCreatorAddressCreateUpdate = {
  country: number;
  address_line: string;
  address_line_2?: string | null;
  city: string;
  zip: string;
};
export type UserContentCreatorLinksCreateUpdate = {
  platform_id: number;
  link: string;
};
export type UserContentCreatorCreateUpdateRequest = {
  /** Name of the content creator */
  name: string | null;
  /** Surname of the content creator */
  surname: string | null;
  /** Birth date of the content creator */
  birth_date: string | null;
  /** Address of the content creator */
  address?: UserContentCreatorAddressCreateUpdate | null;
  /** Message for the content creator */
  message?: string | null;
  /** Code for the content creator */
  code?: string | null;
  /** Links for the content creator */
  links?: UserContentCreatorLinksCreateUpdate[] | null;
};
export type UserContentCreatorPlatformResponse = {
  id: number;
  name: string;
};
export type UserContentCreatorPlatformCreateRequest = {
  name: string;
};
export type UserContentCreatorAddressDetail = {
  id: number;
  country: CountryDetail;
  address_line: string;
  address_line_2?: string | null;
  city: string;
  zip: string;
};
export type UserContentCreatorStatus = 'APPROVED' | 'REJECTED' | 'WAITING';
export type UserContentCreatorApplicationDetail = {
  id: number;
  status: UserContentCreatorStatus | null;
  message: string;
  not_approved_reason?: string | null;
  created_at: string | null;
};
export type UserContentCreatorLinkDetail = {
  id: number;
  platform_id: number;
  link: string;
};
export type UserContentCreatorDetailResponse = {
  id: string;
  name: string;
  surname: string;
  birth_date: string;
  address: UserContentCreatorAddressDetail;
  message: string;
  code: string;
  user: UserMinimalResponse;
  content_creator_application: UserContentCreatorApplicationDetail[];
  links: UserContentCreatorLinkDetail[];
};
export type BodyUpdateContentCreatorApiContentCreatorUpdatePut = {
  address?: UserContentCreatorAddressCreateUpdate | null;
  links?: UserContentCreatorLinksCreateUpdate[] | null;
};
export type UserContentCreatorSupporterMinimal = {
  id: string;
  email: string;
  username: string;
};
export type UserContentCreatorSupporterList = {
  id: number;
  supporter: UserContentCreatorSupporterMinimal;
  date_to: string | null;
  date_from: string | null;
};
export type UserContentCreatorSupporterListResponse = {
  results_count: number;
  count: number;
  results: UserContentCreatorSupporterList[];
};
export type UserUsernameResponse = {
  id: string;
  username: string;
};
export type UserSettingsModel = {
  id: number;
  notification_match_invite?: boolean | null;
  notification_match_confirm?: boolean | null;
  notification_match_reminder?: number | null;
  notification_match_email?: boolean | null;
  notification_match_push?: boolean | null;
};
export type UserSettingsUpdateRequest = {
  notification_match_invite: boolean | null;
  notification_match_confirm: boolean | null;
  notification_match_reminder: number | null;
  notification_match_email: boolean | null;
  notification_match_push: boolean | null;
};
export type EventType = 'COMPETITIVE' | 'DEATHMATCH' | 'PREMIERE';
export type EventPublicity = 'CLAN' | 'PUBLIC' | 'PRIVATE';
export type EventCreateRequest = {
  title: string;
  start_time: string;
  end_time: string;
  note?: string | null;
  link?: string | null;
  event_type: EventType;
  publicity: EventPublicity;
  invited_users?: number[] | null;
};
export type UserEvent = {
  id: string;
  username: string;
  email: string;
  avatar?: string | null;
};
export type EventDetailResponse = {
  id: string;
  owner: UserEvent;
  title: string;
  start_time: string;
  end_time: string;
  note?: string | null;
  link?: string | null;
  capacity?: number | null;
  capacity_occupied?: number | null;
  is_owner?: boolean | null;
  event_type: EventType;
  event_users: UserEvent[] | null;
  has_invitation?: boolean | null;
  accepted?: boolean | null;
};
export type EventUpdateRequest = {
  title: string;
  start_time: string;
  end_time: string;
  note?: string | null;
  link?: string | null;
  event_type: EventType;
};
export type CalendarDate = {
  date: string;
  accepted: boolean;
  declined: boolean;
  invited: boolean;
};
export type CalendarResponse = {
  id: number;
  dates: CalendarDate[];
};
export type EventInvitationResponse = {
  id: string;
  title: string;
  start_time: string;
  end_time: string;
  capacity_occupied: number | null;
  owner: UserEvent;
  event_users: UserEvent[];
};
export type UpcomingEventResponse = {
  id: string;
  title: string;
  start_time: string;
  end_time: string;
  capacity_occupied: number;
  owner: UserEvent;
  is_owner: boolean;
  accepted: boolean;
  event_users: UserEvent[] | null;
};
export type EventUserCreateUpdateRequest = {
  event: string;
  is_accepted: boolean;
};
export type DiscoverUserEvent = {
  id: number;
  username: string;
};
export type DiscoverEventResponse = {
  id: string;
  title: string;
  start_time: string;
  end_time: string;
  capacity: number;
  capacity_occupied: number;
  event_users: DiscoverUserEvent[];
  is_clan: boolean;
};
export type AdminUserDetail = {
  id: string;
  email: string;
  username: string;
  steam_id?: string | null;
  avatar_path?: string | null;
  bio?: string | null;
  country: CountryName;
  user_login: UserLoginResponse;
  deleted_at?: string | null;
  demos_count?: number | null;
};
export type AdminUserListResponse = {
  results_count: number;
  count: number;
  results: AdminUserDetail[];
};
export type AdminUserCountResponse = {
  count_all: number;
  count_last_day: number;
  count_last_week: number;
  count_last_hour: number;
};
export type QueueStatus =
  | 'NEW'
  | 'PROCESSING'
  | 'QUEUED'
  | 'FAILED'
  | 'FINISHED'
  | 'REQUEUED'
  | 'DISCARDED';
export type AdminUserTaskResponse = {
  id: number;
  hash_url: string;
  status: QueueStatus;
};
export type AdminUserQueueTasksResponse = {
  id: string;
  email: string;
  username: string;
  steam_id?: string | null;
  avatar_path?: string | null;
  bio?: string | null;
  country: CountryName;
  user_login: UserLoginResponse;
  deleted_at?: string | null;
  demos_count?: number | null;
  match_code?: string | null;
  auth_code?: string | null;
  upload_tasks: AdminUserTaskResponse[];
  download_tasks: AdminUserTaskResponse[];
  highlight_tasks: AdminUserTaskResponse[];
};
export type AdminGetUpdateUserResponse = {
  id: string;
  email: string;
  username: string;
  steam_id?: string | null;
  avatar_path?: string | null;
  bio?: string | null;
  country: CountryName;
  user_login: UserLoginResponse;
  deleted_at?: string | null;
  demos_count?: number | null;
  match_code?: string | null;
  auth_code?: string | null;
};
export type AdminTicketCategory = {
  id: number;
  name: string;
  parent_category?: number | null;
};
export type AdminTicketPriority = {
  id: number;
  name: string;
};
export type AdminTicketStatus = {
  id: number;
  name: string;
};
export type AdminUserTicketList = {
  id: string;
  username: string;
};
export type AdminTicketList = {
  id: string;
  title: string;
  ticket_category: AdminTicketCategory;
  ticket_priority: AdminTicketPriority;
  ticket_status: AdminTicketStatus;
  user: AdminUserTicketList;
  created_at: string;
};
export type AdminTicketListPaginatedResponse = {
  results_count: number;
  count: number;
  results: AdminTicketList[];
};
export type AdminTicketListSortBy = 'title' | 'created_at';
export type AdminTicketCategoriesPaginatedResponse = {
  results_count: number;
  count: number;
  results: AdminTicketCategory[];
};
export type AdminTicketPrioritiesPaginatedResponse = {
  results_count: number;
  count: number;
  results: AdminTicketPriority[];
};
export type AdminTicketStatusesPaginatedResponse = {
  results_count: number;
  count: number;
  results: AdminTicketStatus[];
};
export type AdminTicketMessageFile = {
  id: number;
  file?: string | null;
};
export type AdminTicketMessage = {
  id: number;
  content: string;
  user?: UserResponse | null;
  admin?: AdminResponse | null;
  ticket_message_files?: AdminTicketMessageFile[] | null;
  created_at?: string | null;
  rating?: number | null;
  rating_message?: string | null;
};
export type AdminTicketDetailResponse = {
  id: string;
  title: string;
  ticket_category: AdminTicketCategory;
  ticket_priority: AdminTicketPriority;
  ticket_status: AdminTicketStatus;
  ticket_messages?: AdminTicketMessage[] | null;
  user: AdminUserTicketList;
  created_at?: string | null;
};
export type BodyCreateTicketMessageApiSystemAdminTicketTicketIdAddMessagesPost = {
  files?: Blob[] | null;
};
export type AdminHighlightDetail = {
  id: string;
  name: string;
  hash: string;
  score: number;
  created_at: string;
  length?: number | null;
  size?: number | null;
  resolution_width: number;
  resolution_height: number;
  frame_rate: number;
  video_quality: string;
  use_info_bar: boolean;
  use_video_transitions: boolean;
  is_automatic: boolean;
  likes_count: number;
};
export type AdminHighlightsListResponse = {
  results_count: number;
  count: number;
  results: AdminHighlightDetail[];
};
export type AdminHighlightCountResponse = {
  count_all: number;
  count_last_day: number;
  count_last_week: number;
  count_last_hour: number;
  count_failed_all: number;
  count_failed_last_day: number;
  count_failed_last_week: number;
  count_failed_last_hour: number;
};
export type UploadType = 'UPLOADED' | 'STEAM' | 'FACEIT';
export type AdminDemoCountResponse = {
  count_all: number;
  count_last_day: number;
  count_last_week: number;
  count_last_hour: number;
  count_failed_all: number;
  count_failed_last_day: number;
  count_failed_last_week: number;
  count_failed_last_hour: number;
};
export type AdminDemoCountByTypeResponse = {
  uploaded: number;
  steam: number;
  face_it: number;
};
export type AdminDemoListResults = {
  id: string;
  upload_types: UploadType[];
  status: DemoStatus;
  created_at: string;
  hash: string;
};
export type AdminDemoListResponse = {
  results_count: number;
  count: number;
  results: AdminDemoListResults[];
};
export type AdminDemoMatchUser = {
  id: string;
  username: string;
  email: string;
};
export type AdminDemoDetailResponse = {
  id: string;
  demo_type: string;
  demo_mode: string;
  status: DemoStatus;
  origin_name: string;
  size: number;
  file_path: string;
  map_name: string;
  analysis_version?: string | null;
  parser_version?: string | null;
  demo_version?: string | null;
  tick_rate: number;
  created_at: string;
  played_at: string;
  processed_at?: string | null;
  upload_type: UploadType | null;
  users: AdminDemoMatchUser[];
};
export type AdminUserContentCreatorAddress = {
  country: CountryDetail;
  address_line: string;
  address_line_2?: string | null;
  city: string;
  zip: string;
};
export type AdminUserContentCreator = {
  id: string;
  email: string;
  username: string;
  name?: string | null;
  surname?: string | null;
  code?: string | null;
  address: AdminUserContentCreatorAddress;
  deleted_at?: string | null;
  supporters_count?: number | null;
};
export type AdminContentCreatorDetailResponse = {
  results_count: number;
  count: number;
  results: AdminUserContentCreator[];
};
export type AdminContentCreatorListSortBy = 'username' | 'code';
export type AdminSupporterUserMinimal = {
  id: string;
  username: string;
  email: string;
};
export type AdminUserContentCreatorSupporter = {
  supporter: AdminSupporterUserMinimal;
  date_from?: string | null;
  date_to?: string | null;
};
export type AdminContentCreatorSupportersResponse = {
  results_count: number;
  count: number;
  results: AdminUserContentCreatorSupporter[];
};
export type AdminContentCreatorUserDetail = {
  id: string;
  email: string;
  username: string;
  steam_id?: string | null;
  avatar_path?: string | null;
  bio?: string | null;
  country?: string | null;
  last_login?: string | null;
  is_first_login?: boolean | null;
};
export type AdminUserContentCreatorLink = {
  platform_id: number;
  link: string;
};
export type AdminUserContentCreatorApplication = {
  status?: UserContentCreatorStatus | null;
  message: string;
  not_approved_reason?: string | null;
  created_at?: string | null;
};
export type AdminUserContentCreatorResponse = {
  id: string;
  user: AdminContentCreatorUserDetail;
  name: string;
  surname: string;
  birthdate: string;
  address: AdminUserContentCreatorAddress;
  code: string;
  links: AdminUserContentCreatorLink[];
  content_creator_application: (AdminUserContentCreatorApplication | null)[];
};
export type AdminUserApplicationList = {
  id: string;
  name: string;
  surname: string;
  code: string;
  username?: string | null;
  email?: string | null;
  country?: CountryDetail | null;
  deleted_at?: string | null;
  supporters_count?: number | null;
};
export type AdminUserContentCreatorApplicationList = {
  id: number;
  user_content_creator: AdminUserApplicationList;
  message: string;
  status?: UserContentCreatorStatus | null;
  not_approved_reason?: string | null;
  created_at: string;
};
export type AdminUserContentCreatorApplicationListResponse = {
  results_count: number;
  count: number;
  results: AdminUserContentCreatorApplicationList[];
};
export type AdminUserContentCreatorApplicationDetailResponse = {
  user_content_creator: AdminUserContentCreatorResponse;
  message?: string | null;
  status?: UserContentCreatorStatus | null;
  not_approved_reason?: string | null;
  created_at?: string | null;
};
export type AdminUserContentCreatorApplicationRejectResponse = {
  status: UserContentCreatorStatus;
  not_approved_reason?: string | null;
};
export type AdminTaxonomyCreateUpdateResponse = {
  id: number;
  name: string;
};
export type TicketCategoryResponse = {
  id: number;
  name: string;
  parent_category_id?: number | null;
};
export type DemoQueueResponseModel = {
  id: number;
  name: string;
  payload: string;
  status: QueueStatus;
  fail_count: number;
  created_at: string;
  first_time_queued_at: string | null;
  start_processing_at: string | null;
  end_processing_at: string | null;
  hash: string;
  users: UserMinimalResponse[];
};
export type DemoQueueResponseListModel = {
  results_count: number;
  count: number;
  results: DemoQueueResponseModel[];
};
export type QueueSortBy =
  | 'created_at'
  | 'first_time_queued_at'
  | 'start_processing_at'
  | 'end_processing_at';
export type AutoDownloadQueueResponseModel = {
  id: number;
  name: string;
  payload: string;
  status: QueueStatus;
  fail_count: number;
  created_at: string;
  first_time_queued_at: string | null;
  start_processing_at: string | null;
  end_processing_at: string | null;
  url: string;
  users: UserMinimalResponse[];
};
export type AutoDownloadQueueResponseListModel = {
  results_count: number;
  count: number;
  results: AutoDownloadQueueResponseModel[];
};
export type HighlightQueueResponseModel = {
  id: number;
  name: string;
  payload: string;
  status: QueueStatus;
  fail_count: number;
  created_at: string;
  first_time_queued_at: string | null;
  start_processing_at: string | null;
  end_processing_at: string | null;
  hash: string;
  user: UserMinimalResponse;
};
export type HighlightQueueResponseListModel = {
  results_count: number;
  count: number;
  results: HighlightQueueResponseModel[];
};
export type QueueDashboardResponse = {
  upload_status_count: {
    [key: string]: number;
  };
  download_status_count: {
    [key: string]: number;
  };
  highlight_status_count: {
    [key: string]: number;
  };
  upload_process_time_seconds: number;
  download_process_time_seconds: number;
  highlight_process_time_seconds: number;
};
export type QueueTypes =
  | 'priority_queue'
  | 'non_priority_queue'
  | 'auto_download_queue'
  | 'highlight_queue';
export type TicketEnumModel = {
  id: number;
  name: string;
};
export type TicketListResponse = {
  id: string;
  title: string;
  ticket_priority: TicketEnumModel;
  ticket_status: TicketEnumModel;
  ticket_category: TicketEnumModel;
  user: UserResponse;
  created_at: string;
};
export type BodyCreateTicketApiTicketsCreatePost = {
  files?: Blob[];
};
export type BodyCreateTicketMessageApiTicketsTicketIdAddUserMessagePost = {
  files?: Blob[];
};
export type TicketMessageFile = {
  id: number;
  file: string;
};
export type TicketMessageResponse = {
  id: number;
  content: string;
  user: UserResponse | null;
  admin: AdminResponse | null;
  ticket_message_files: TicketMessageFile[];
  created_at: string;
  rating: number | null;
  rating_message: string | null;
};
export type TicketDetailResponse = {
  id: string;
  title: string;
  ticket_priority: TicketEnumModel;
  ticket_status: TicketEnumModel;
  ticket_category: TicketEnumModel;
  user: UserResponse;
  ticket_messages: TicketMessageResponse[];
};
export type CountryPaginatedResponse = {
  results_count: number;
  count: number;
  results: CountryDetail[];
};
export const {
  useGetDemoHeaderApiGamesDemoIdHeaderGetQuery,
  useGetDemoDetailApiGamesDemoIdDetailGetQuery,
  useGetDemoMyDeathApiGamesDemoIdDeathsGetQuery,
  useGetDemoDuelApiGamesDemoIdDuelsGetQuery,
  useGetDemoPlayersApiGamesDemoIdPlayersGetQuery,
  useGetDemoRoundApiGamesDemoIdRoundsGetQuery,
  useGetDemoScoreboardApiGamesDemoIdScoreboardGetQuery,
  useGetDemoPlayerRankingApiGamesRankingDemoIdGetQuery,
  useGetDemoMapDetailApiGamesDemoIdMapGetQuery,
  useGetDemoMapDetailRoundApiGamesDemoIdMapRoundNumberGetQuery,
  useGetDemoHashByDemoIdApiGamesDemoHashDemoIdGetQuery,
  useGetTutorialGamesApiGamesTutorialGamesGetQuery,
  useGetUserMatchListApiDemosGetQuery,
  useChangeUserMatchFavoriteApiDemosUserMatchIdChangeFavoritePutMutation,
  useDeleteUserMatchApiDemosDeletePostMutation,
  useGetSteamAvatarsApiDemosGetSteamAvatarsGetQuery,
  useUserMatchCountApiDemosUserMatchCountGetQuery,
  useGetDemoByHashApiDemosDemoHashGetQuery,
  useUserDemoProcessingApiDemosProcessingGetQuery,
  useUploadDemosApiDemosUploadPostMutation,
  useAddMapNotesApiGamesMapNotesAddDemoIdPostMutation,
  useGetMapNotesApiGamesMapNotesDemoIdGetQuery,
  useUpdateMapNotesApiGamesMapNotesNoteIdPutMutation,
  useDeleteMapNotesApiGamesMapNotesNoteIdDeleteMutation,
  useGenerateAutomaticHighlightsApiHighlightsAutomaticGenerationDemoIdPostMutation,
  useGenerateManualHighlightsApiHighlightsManualGenerationDemoIdPostMutation,
  useGetHighlightSettingsApiHighlightSettingsHighlightSettingsGetQuery,
  useUpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostMutation,
  useGetResolutionsApiHighlightSettingsResolutionsGetQuery,
  useGetFrameRatesApiHighlightSettingsFrameRatesGetQuery,
  useGetVideoQualityApiHighlightSettingsVideoQualityGetQuery,
  useGetHighlightEventApiHighlightsEventDemoIdGetQuery,
  useGetHighlightsApiHighlightsListGetQuery,
  useGetHighlightVideoApiHighlightsVideoStreamHighlightHashGetQuery,
  useGetHighlightProcessingApiHighlightsProcessingGetQuery,
  useGetHighlightListFeedApiHighlightsListFeedGetQuery,
  useRenameHighlightApiHighlightsRenameHighlightIdPutMutation,
  useDeleteHighlightApiHighlightsHighlightIdDeleteMutation,
  useDownloadHighlightApiHighlightsDownloadHighlightIdGetQuery,
  useLikeHighlightApiHighlightsLikeHighlightIdPutMutation,
  useGetHighlightDetailApiHighlightsDetailHighlightHashGetQuery,
  useCreateClanInvitationApiClansInvitationPostMutation,
  useCreateClanInvitationsByClanApiClansClanInvitationsByClanPostMutation,
  useAcceptClanInvitationApiClansClanInvitationsInvitationIdAcceptPutMutation,
  useListClanInvitationsApiClansClanIdInvitationAllGetQuery,
  useAcceptClanInvitationByClanApiClansInvitationIdInvitationAcceptByClanPutMutation,
  useRejectClanInvitationByClanApiClansInvitationIdInvitationRejectByClanPutMutation,
  useAcceptClanInvitationByUserApiClansInvitationIdInvitationAcceptByUserPutMutation,
  useRejectClanInvitationByUserApiClansInvitationIdInvitationRejectByUserPutMutation,
  useCheckClanInvitationExistsApiClansClanInvitationsExistsClanIdGetQuery,
  useCreateClanPostApiClansClanIdPostPostMutation,
  useListClanPostsApiClansClanIdPostAllGetQuery,
  useIsAnyClanLeaderApiClansIsAnyClanLeaderGetQuery,
  useGetClanRoleApiClansClanIdClanRoleGetQuery,
  useListClanMembersApiClansClanIdMemberListGetQuery,
  useGetClanUserMemberListApiClansIdMemberListPrivateGetQuery,
  useGetClanMemberApiClansClanIdClanUserMemberIdGetQuery,
  useUpdateClanMemberApiClansClanIdClanUserMemberIdPutMutation,
  useRemoveClanMemberApiClansClanIdClanUserMemberIdDeleteMutation,
  useListClansApiClansAllGetQuery,
  useListUserClansApiClansUserAllGetQuery,
  useCreateClanApiClansPostMutation,
  useGetClanApiClansClanIdGetQuery,
  useUpdateClanApiClansClanIdPutMutation,
  useGetUserRankingApiRankingsUserGetQuery,
  useGetRankingListApiRankingsListGetQuery,
  useGetRankingAllTimeListApiRankingsListAllTimeGetQuery,
  useGetRankingListUnderSixMatchesApiRankingsListUnderSixGetQuery,
  useGetRankingUserCountApiRankingsUserCountGetQuery,
  useRefreshTokenApiUserTokenRefreshPostMutation,
  useLoginForAccessTokenApiUserTokenPostMutation,
  useLoginForAdminAccessTokenApiUserTokenAdminPostMutation,
  useLogoutApiUserLogoutPostMutation,
  useAdminLogoutApiUserAdminLogoutPostMutation,
  useFirstLoginApiUserFirstLoginPostMutation,
  useReadCurrentUserApiUserDetailGetQuery,
  useUploadAvatarApiUserUpdateAvatarPutMutation,
  useListClanUsersApiUserListClanClanIdGetQuery,
  useListUsersApiUserListGetQuery,
  useUpdateUserSettingsApiUserChangeSettingsPutMutation,
  useGetUserDashboardApiUserDashboardGetQuery,
  useGetUserMapStatsApiUserMapStatsGetQuery,
  useValidateUsernameApiUserUsernameValidationPostMutation,
  useValidateEmailApiUserEmailValidationPostMutation,
  useRegisterUserApiUserRegisterPostMutation,
  useConfirmEmailApiUserConfirmRegistrationPostMutation,
  useResendConfirmEmailApiUserResendConfirmRegistrationPostMutation,
  useChangePasswordApiUserChangePasswordPutMutation,
  useResetPasswordRequestApiUserResetPasswordRequestPostMutation,
  useResetPasswordApiUserResetPasswordPutMutation,
  useUserSteamLoginApiUserSteamLoginPostMutation,
  useUserSteamConnectApiUserSteamConnectPostMutation,
  useUserSteamDisconnectApiUserSteamDisconnectPutMutation,
  useUserFaceitDisconnectApiUserFaceitDisconnectPutMutation,
  useUpdateSteamCodeApiUserSteamUpdateGamecodePutMutation,
  useGetSteamCodeApiUserSteamGetCodesGetQuery,
  useRefreshSteamDataApiUserSteamRefreshPutMutation,
  useCreateContentCreatorApiContentCreatorCreatePostMutation,
  useGetContentCreatorPlatformsApiContentCreatorPlatformsGetQuery,
  useCreateContentCreatorPlatformApiContentCreatorPlatformsPostMutation,
  useUpdateContentCreatorPlatformApiContentCreatorPlatformsPlatformIdPutMutation,
  useDeleteContentCreatorPlatformApiContentCreatorPlatformsPlatformIdDeleteMutation,
  useGetContentCreatorApiContentCreatorDetailGetQuery,
  useUpdateContentCreatorApiContentCreatorUpdatePutMutation,
  useCreateContentCreatorSupporterApiContentCreatorSupportCodePostMutation,
  useListContentCreatorSupportersApiContentCreatorSupportersGetQuery,
  useRemoveContentCreatorSupportApiContentCreatorRemoveSupportPutMutation,
  useGetSupportedContentCreatorApiContentCreatorSupportedContentCreatorGetQuery,
  useGetSettingsApiUserSettingSettingsGetQuery,
  useUpdateSettingsApiUserSettingSettingsPutMutation,
  useCreateEventEventsCreatePostMutation,
  useGetEventEventsEventIdGetQuery,
  useUpdateEventEventsEventsEventIdPutMutation,
  useDeleteEventEventsEventsEventIdDeleteMutation,
  useGetCalendarEventsDashboardCalendarGetQuery,
  useGetEventInvitationsEventsDashboardInvitationsEventGetQuery,
  useGetUpcomingEventsEventsDashboardUpcomingEventGetQuery,
  useCreateEventUserApiEventsUserCreateUpdatePostMutation,
  useDiscoverEventsEventsDiscoverDiscoverGetQuery,
  useListUsersApiSystemAdminUserListGetQuery,
  useGetUsersCountApiSystemAdminUserCountGetQuery,
  useGetUserApiSystemAdminUserUserIdGetQuery,
  useUpdateUserApiSystemAdminUserUserIdPutMutation,
  useDeleteUserApiSystemAdminUserUserIdDeleteMutation,
  useRestoreUserApiSystemAdminUserRestoreUserIdPutMutation,
  useLoginAsUserApiSystemAdminUserLoginAsUserIdGetQuery,
  useUserRefreshSteamDataApiSystemAdminUserSteamRefreshUserIdPutMutation,
  useGetAdminApiSystemAdminAdminAdminIdGetQuery,
  useTicketListApiSystemAdminTicketListGetQuery,
  useTicketCategoriesListApiSystemAdminTicketCategoriesGetQuery,
  useTicketPrioritiesListApiSystemAdminTicketPrioritiesGetQuery,
  useTicketStatusesListApiSystemAdminTicketStatusesGetQuery,
  useTicketDetailApiSystemAdminTicketTicketIdGetQuery,
  useUpdateTicketApiSystemAdminTicketTicketIdPutMutation,
  useCreateTicketMessageApiSystemAdminTicketTicketIdAddMessagesPostMutation,
  useGetHighlightListApiSystemAdminHighlightListGetQuery,
  useGetCountApiSystemAdminHighlightCountGetQuery,
  useDownloadDemoApiSystemAdminDemoDemoHashDownloadGetQuery,
  useGetUploadTypeChoicesApiSystemAdminDemoUploadTypesGetQuery,
  useGetDemoStatusChoicesApiSystemAdminDemoDemoStatusesGetQuery,
  useGetDemoCountApiSystemAdminDemoCountGetQuery,
  useGetDemoCountByTypeApiSystemAdminDemoCountByTypeGetQuery,
  useGetDemoListApiSystemAdminDemoListGetQuery,
  useGetAdminDemoDetailApiSystemAdminDemoDetailDemoHashGetQuery,
  useUpdateAdminDemoApiSystemAdminDemoDemoHashPutMutation,
  useDeleteAdminDemoApiSystemAdminDemoDemoHashDeleteMutation,
  useListContentCreatorsApiSystemAdminContentCreatorListGetQuery,
  useDeleteContentCreatorApiSystemAdminContentCreatorDeleteCreatorIdDeleteMutation,
  useListContentCreatorSupportersApiSystemAdminContentCreatorSupporterCreatorIdSupportersGetQuery,
  useGetContentCreatorDetailApiSystemAdminContentCreatorCreatorIdGetQuery,
  useListContentCreatorApplicationsApiSystemAdminContentCreatorApplicationListGetQuery,
  useGetContentCreatorApplicationApiSystemAdminContentCreatorApplicationApplicationIdGetQuery,
  useApproveContentCreatorApplicationApiSystemAdminContentCreatorApplicationApproveApplicationIdPutMutation,
  useRejectContentCreatorApplicationApiSystemAdminContentCreatorApplicationRejectApplicationIdPutMutation,
  useCreateClanRoleApiSystemAdminTaxonomyClanRolePostMutation,
  useGetClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdGetQuery,
  useUpdateClanRoleApiSystemAdminTaxonomyClanRoleClanRoleIdPutMutation,
  useCreatePremiumApiSystemAdminTaxonomyPremiumTypePostMutation,
  useGetPremiumApiSystemAdminTaxonomyPremiumTypePremiumIdGetQuery,
  useUpdatePremiumApiSystemAdminTaxonomyPremiumTypePremiumIdPutMutation,
  useCreateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformPostMutation,
  useGetContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdGetQuery,
  useUpdateContentCreatorPlatformApiSystemAdminTaxonomyUserContentCreatorPlatformContentCreatorPlatformIdPutMutation,
  useCreateDemoTypeApiSystemAdminTaxonomyDemoTypePostMutation,
  useGetDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdGetQuery,
  useUpdateDemoTypeApiSystemAdminTaxonomyDemoTypeDemoTypeIdPutMutation,
  useCreateDemoModeApiSystemAdminTaxonomyDemoModePostMutation,
  useGetDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdGetQuery,
  useUpdateDemoModeApiSystemAdminTaxonomyDemoModeDemoModeIdPutMutation,
  useCreateUserMatchRoleApiSystemAdminTaxonomyUserMatchRolePostMutation,
  useGetUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdGetQuery,
  useUpdateUserMatchRoleApiSystemAdminTaxonomyUserMatchRoleUserMatchRoleIdPutMutation,
  useCreateEventPublicityApiSystemAdminTaxonomyEventPublicityPostMutation,
  useGetEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdGetQuery,
  useUpdateEventPublicityApiSystemAdminTaxonomyEventPublicityEventPublicityIdPutMutation,
  useCreateEventTypeApiSystemAdminTaxonomyEventTypePostMutation,
  useGetEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdGetQuery,
  useUpdateEventTypeApiSystemAdminTaxonomyEventTypeEventTypeIdPutMutation,
  useCreateTicketStatusApiSystemAdminTaxonomyTicketStatusPostMutation,
  useGetTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdGetQuery,
  useUpdateTicketStatusApiSystemAdminTaxonomyTicketStatusTicketStatusIdPutMutation,
  useCreateTicketPriorityApiSystemAdminTaxonomyTicketPriorityPostMutation,
  useGetTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdGetQuery,
  useUpdateTicketPriorityApiSystemAdminTaxonomyTicketPriorityTicketPriorityIdPutMutation,
  useCreateTicketCategoryApiSystemAdminTaxonomyTicketCategoryPostMutation,
  useGetTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdGetQuery,
  useUpdateTicketCategoryApiSystemAdminTaxonomyTicketCategoryTicketCategoryIdPutMutation,
  useGetUploadQueueTaskListApiSystemAdminQueueUploadListGetQuery,
  useGetDownloadQueueTaskListApiSystemAdminQueueDownloadListGetQuery,
  useGetHighlightQueueTaskListApiSystemAdminQueueHighlightListGetQuery,
  useGetQueueDashboardApiSystemAdminQueueDashboardGetQuery,
  useMoveQueueTaskApiSystemAdminQueueMoveTaskPostMutation,
  useListUserTicketsApiTicketsAllGetQuery,
  useCreateTicketApiTicketsCreatePostMutation,
  useCreateTicketMessageApiTicketsTicketIdAddUserMessagePostMutation,
  useGetTicketApiTicketsTicketIdGetQuery,
  useUpdateTicketMessageRatingApiTicketsMessageTicketMessageIdRatingPutMutation,
  useGetTicketMessageAttachmentsApiTicketsmessageTicketMessageIdAttachmentsGetQuery,
  useGetTicketMessageFileApiTicketsfileTicketMessageFileIdAttachmentsGetQuery,
  useGetCountriesApiUserCountriesGetQuery,
  useHomeGetQuery,
} = injectedRtkApi;
