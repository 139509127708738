import { m } from 'framer-motion';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useResponsive } from '@rankacy/hooks';
import { useLocales } from '@rankacy/intl';
import { Box, Grid } from '@mui/material';
import { pxToRem } from '@rankacy/theme';
import { SvgColor, varFade } from '@rankacy/components';
import { HEADER } from '@rankacy/layouts';
import { HeroSectionForm } from './hero-section-form';

export const HeroSection = () => {
  const mdUp = useResponsive('up', 'md');
  const lgUp = useResponsive('up', 'lg');

  const { t } = useLocales();

  const renderText = (
    <Stack
      sx={{
        maxWidth: { xs: '100%', sm: 450, md: 550, lg: 660 },
        gap: mdUp ? 6 : 3,
        textAlign: 'left',
        justifyContent: { xs: 'flex-start', md: 'center' },
        alignItems: { xs: 'flex-start', md: 'flex-start' },
        zIndex: 1,
      }}
    >
      <Stack sx={{ gap: { xs: 1, md: 3 } }}>
        <Typography
          component={m.div}
          sx={{
            whiteSpace: 'pre-line',
            fontSize: { xs: pxToRem(32), md: pxToRem(48), lg: pxToRem(64) },
            fontWeight: 500,
          }}
          variant="h1"
          variants={varFade().in}
        >
          {t('landing_page.hero_title')}
        </Typography>

        <Typography
          component={m.div}
          sx={{
            color: 'primary.main',
            fontSize: {
              xs: pxToRem(14),
              sm: pxToRem(17),
              md: pxToRem(20),
              lg: pxToRem(24),
            },
            fontWeight: 300,
            fontFamily: 'Unbounded',
          }}
          variants={varFade().in}
        >
          {t('landing_page.hero_text')}
        </Typography>
        {!lgUp && (
          <Box
            autoPlay
            disablePictureInPicture
            loop
            muted
            playsInline
            component={'video'}
            src="/assets/background/2d-map-bg-video.webm"
            sx={{
              width: '100%',
              height: '100%',
              opacity: 0.5,
              alignSelf: 'center',
              mr: { xs: 8, sm: 12, md: 16 },
            }}
          />
        )}
      </Stack>

      <Stack component={m.div} sx={{ width: 1, zIndex: 1 }} variants={varFade().in}>
        <HeroSectionForm />
      </Stack>

      {lgUp && (
        <Stack sx={{ gap: 1.5 }}>
          <Typography color="text.secondary" variant="body2">
            {t('landing_page.supporting_platforms')}
          </Typography>

          <Stack sx={{ flexDirection: 'row', gap: 5, alignItems: 'center' }}>
            <SvgColor
              src="/assets/icons/steam_text_logo.svg"
              sx={{
                width: 121,
                height: 40,
                bgcolor: 'grey.300',
              }}
            />

            <SvgColor
              src="/assets/icons/faceit_text_logo.svg"
              sx={{
                width: 141,
                height: 30,
                bgcolor: 'grey.300',
              }}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );

  return (
    <Box
      sx={{
        position: 'relative',
        height: { xs: 'auto', lg: '100vh' },
        maxHeight: { xs: 'auto', lg: '1110px' },
        overflow: 'hidden',
        bgcolor: 'background.default',
        boxShadow: ({ palette }) => `0px 0px 40px 50px ${palette.grey[900]}`,
      }}
    >
      <Container sx={{ position: 'relative', width: 1, height: 1 }}>
        {lgUp && (
          <Box
            sx={{
              width: { xs: '55%', xl: '65%' },
              height: '100%',
              position: 'absolute',
              right: { xs: 0, xl: -60 },
              top: 0,
            }}
          >
            <Box
              autoPlay
              disablePictureInPicture
              loop
              muted
              playsInline
              component={'video'}
              src="/assets/background/2d-map-bg-video.webm"
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                right: 0,
                top: 0,
                opacity: 0.5,
              }}
            />
          </Box>
        )}

        <Box
          component={m.div}
          sx={{
            width: 1,
            height: 1,
            mt: { xs: pxToRem(HEADER.H_MOBILE + 60), lg: pxToRem(HEADER.H_MOBILE) },
          }}
        >
          <Grid container sx={{ height: 1 }}>
            <Grid item md={12} sx={{ display: 'flex', justifyContent: 'flex-start' }} xs={12}>
              {renderText}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
