import { z } from 'zod';

export interface FeatureResult {
  off: boolean;
  on: boolean;
  value: string | boolean | number;
}

// Any new feature flag has to be added here
export const featureFlagsSchema = z.object({
  VITE_FEATURE_REALTIME_COMMUNICATION: z.string().transform((str) => str === 'true'),
  VITE_FEATURE_SOCIALS: z.string().transform((str) => str === 'true'),
  VITE_FEATURE_STRATEGY_BOARD: z.string().transform((str) => str === 'true'),
  VITE_FEATURE_NOTIFICATIONS: z.string().transform((str) => str === 'true'),
  VITE_FEATURE_STREETVIEW: z.string().transform((str) => str === 'true'),
  VITE_FEATURE_GLOBAL_ANNOUNCEMENT: z.string().transform((str) => str === 'true'),
  VITE_FEATURE_GIVEAWAY: z.string().transform((str) => str === 'true'),
  VITE_FEATURE_MAINTENANCE: z.string().transform((str) => str === 'true'),
  VITE_FEATURE_HIGHLIGHTS_ISSUES_DIALOG: z.string().transform((str) => str === 'true'),
});

export type FeatureFlagsType = z.infer<typeof featureFlagsSchema>;
