import { PropsWithChildren } from 'react';
import { FeatureEnabledWrapper, useFeature } from '@rankacy/feature-flags';
import { useBoolean, useResponsive } from '@rankacy/hooks';
import { useSettingsContext } from '@rankacy/settings';
import { Box, Stack } from '@mui/material';
import { useLocation } from 'react-router';
import { useNavData } from './config-navigation';
import { BackgroundPattern } from '../_common';
import Main from './main';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import Header from './header';
import { GlobalAnnouncement } from '../_common/global-announcement';

type Props = {
  fullSizeRoutes?: string[];
};

const DashboardLayout = ({ children, fullSizeRoutes }: PropsWithChildren<Props>) => {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const navData = useNavData();

  const isMini = settings.themeLayout === 'mini';

  const location = useLocation();

  const isFullSize = fullSizeRoutes?.some((route) => location.pathname.includes(route));

  const renderNavMini = <NavMini navData={navData} />;

  const renderNavVertical = (
    <NavVertical navData={navData} openNav={nav.value} onCloseNav={nav.onFalse} />
  );

  const renderOpenDrawerIcon = <Header onOpenNav={nav.onTrue} />;

  const renderAnnouncement = (
    <FeatureEnabledWrapper feature="VITE_FEATURE_GLOBAL_ANNOUNCEMENT">
      <GlobalAnnouncement />
    </FeatureEnabledWrapper>
  );

  const globalAnnouncementEnabled = useFeature('VITE_FEATURE_GLOBAL_ANNOUNCEMENT').on;

  if (isMini) {
    return (
      <Stack sx={{ height: globalAnnouncementEnabled ? 'calc(100% - 40px)' : 1 }}>
        {renderAnnouncement}
        <Stack sx={{ height: 1 }}>
          <BackgroundPattern />

          {!lgUp && renderOpenDrawerIcon}

          <Box
            sx={{
              display: { lg: 'flex' },
              minHeight: 1,
              mt: globalAnnouncementEnabled ? 5 : 0,
            }}
          >
            {lgUp ? renderNavMini : renderNavVertical}

            <Main disablePadding={isFullSize}>{children}</Main>
          </Box>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={{ height: '100vh' }}>
      {renderAnnouncement}

      <Stack sx={{ height: globalAnnouncementEnabled ? 'calc(100% - 40px)' : 1 }}>
        <BackgroundPattern />

        {!lgUp && renderOpenDrawerIcon}

        <Box
          sx={{
            minHeight: 1,
            display: { lg: 'flex' },
            mt: globalAnnouncementEnabled ? 5 : 0,
          }}
        >
          {renderNavVertical}

          <Main disablePadding={isFullSize}>{children}</Main>
        </Box>
      </Stack>
    </Stack>
  );
};

export default DashboardLayout;
