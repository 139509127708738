import { m } from 'framer-motion';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { CustomAreaChart, CustomPiePercentageChart } from '@rankacy/charts';
import { Image } from '@rankacy/components';
import { useLocales } from '@rankacy/intl';
import { pxToRem } from '@rankacy/theme';
import { Trans } from 'react-i18next';
import { MatchSingleStatisticWidget } from '../match/detail/match-single-statistic-widget';
import { useResponsive } from '@rankacy/hooks';

export const StatsSection = () => {
  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  return (
    <Stack gap={7.5} sx={{ pb: { xs: 10, md: 10, lg: 15 }, position: 'relative' }}>
      <Stack gap={2} sx={{ pl: { xs: 6, sm: 10 }, zIndex: 1 }}>
        <Typography color="primary.main" fontWeight="500" variant="h3">
          {t('landing_page.stats_title')}
        </Typography>

        <Typography
          color="text.secondary"
          fontWeight="500"
          sx={{ maxWidth: { xs: '100%', lg: pxToRem(600) } }}
          variant="h4"
        >
          <Trans
            components={{
              span: (
                <Typography
                  color="text.primary"
                  component="span"
                  fontWeight="inherit"
                  variant="h4"
                />
              ),
            }}
            i18nKey={'landing_page.stats_description'}
          />
        </Typography>
      </Stack>
      <Stack sx={{ position: 'relative', gap: 7.5 }}>
        <Box sx={{ position: 'relative' }}>
          {mdUp && (
            <Box
              sx={{
                width: 70,
                height: 250,
                position: 'absolute',
                top: 0,
                left: 0,
                boxShadow: (theme) => `-30px -100px 40px 60px ${theme.palette.grey[800]}`,
              }}
            />
          )}
          <Image
            src={'/assets/images/home/match_report.webp'}
            sx={{
              width: 1,
            }}
          />
          {mdUp && (
            <Box
              sx={{
                width: 100,
                height: 250,
                position: 'absolute',
                bottom: 0,
                left: 0,
                boxShadow: (theme) => `0px 200px 40px 20px ${theme.palette.grey[800]} `,
              }}
            />
          )}
        </Box>

        <Stack
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            width: { xs: 1, lg: 'calc(100% - 20rem)' },
            mx: 'auto',
            gap: { xs: 2, md: 3 },
            position: 'relative',
          }}
        >
          {mdUp && (
            <Image
              disabledEffect
              src={'/assets/images/home/map_skeleton_overlay.webp'}
              sx={{
                width: pxToRem(900),
                position: 'absolute',
                top: pxToRem(-50),
                left: pxToRem(-300),
                zIndex: 0,
              }}
            />
          )}

          <Box sx={{ flex: 1 }}>
            <Card sx={{ width: 1 }} variant="outlined">
              <CardContent sx={{ p: 0 }}>
                <Typography color="text.secondary" sx={{ px: 4.5, py: 3 }} variant="h4">
                  <Trans
                    components={{
                      span: (
                        <Typography
                          color="text.primary"
                          component="span"
                          fontWeight="inherit"
                          variant="h4"
                        />
                      ),
                    }}
                    i18nKey={'landing_page.find_all_analysis'}
                  />
                </Typography>

                <Stack sx={{ mx: 'auto', alignItems: 'center' }}>
                  <Image
                    disabledEffect
                    src={'/assets/images/home/stats_rounds.webp'}
                    sx={{ width: { xs: 1, sm: pxToRem(400) }, mt: 2, userSelect: 'none' }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Box>

          <Stack gap={{ xs: 2, md: 3 }} sx={{ flex: 1 }}>
            <MatchSingleStatisticWidget
              averageScore={80}
              comparison={'bigger'}
              newScore={89}
              renderEndComponent={
                <CustomAreaChart
                  showTooltip
                  data={[
                    { dmg: 20 },
                    { dmg: 199 },
                    { dmg: 100 },
                    { dmg: 122 },
                    { dmg: 200 },
                    { dmg: 150 },
                    { dmg: 234 },
                    { dmg: 123 },
                    { dmg: 190 },
                    { dmg: 232 },
                  ]}
                  dataKey="dmg"
                  height={100}
                  tooltipTitle={t('avg_dmg')}
                />
              }
              title={t('avg_dmg')}
            />

            <Stack
              sx={{
                flexDirection: 'row',
                gap: { xs: 2, md: 3 },
                alignItems: 'start',
              }}
            >
              <Card
                animate={{ opacity: 1 }}
                component={m.div}
                initial={{ opacity: 0 }}
                sx={{ width: 1 }}
                variant="outlined"
              >
                <CustomPiePercentageChart
                  average={78}
                  height={140}
                  label={t('headshot_rate_percent')}
                  percentage={29}
                />
              </Card>

              <MatchSingleStatisticWidget
                averageScore={160}
                comparison={'bigger'}
                height={140}
                newScore={151}
                title={t('kd_ratio')}
              />
            </Stack>
          </Stack>
        </Stack>
        {!mdUp && (
          <Box
            sx={{
              backgroundColor: ({ palette }) => palette.background.default,
              position: 'absolute',
              left: -10,
              top: 0,
              width: '110%',
              height: 1,
              zIndex: -1,
              boxShadow: (theme) => `0px 0px 40px 50px ${theme.palette.grey[900]}`,
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
