import { allLangs, defaultLang } from '@rankacy/intl';
import { localStorageAvailable } from '@rankacy/utils';
import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { config } from '../config';

export const getBaseQuery =
  (endpoint: () => string) =>
  async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: object) => {
    // const state = api.getState();

    // e.g. expiration could be extraced from state

    const result = await baseQuery(endpoint())(args, api, extraOptions);

    return result;
  };

export const baseQuery = (endpoint: string) =>
  fetchBaseQuery({
    baseUrl: endpoint,
    prepareHeaders: (headers) => {
      const storageAvailable = localStorageAvailable();
      const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';
      const { value } = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

      headers.set('Content-Language', value);

      const accessToken = Cookies.get(config.values.accessTokenKey);

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  });
