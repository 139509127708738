import { Suspense, lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { AuthGuard } from '../../auth/guard';
import DashboardLayout from '../../layouts/dashboard';
import { RealTimeCommunicationWrapper } from '../../services/real-time/real-time-communication-handler';
import { addItemToArrayConditionally } from '@rankacy/utils';
import { useFeature } from '@rankacy/feature-flags';
import { BackgroundTasks } from '../../sections/background-tasks';
import { paths } from '../paths';
import { LoadingScreen } from '@rankacy/components';
import { HighlightsIssueInfoDialog } from '../../components/highlights-issue-info-dialog';

// DASHBOARD
const DashboardPage = lazy(() => import('../../pages/dashboard/dashboard'));
// PLAYER DASHBOARD
const PlayerDashboardPage = lazy(() => import('../../pages/dashboard/player-dashboard'));
// SOCIALS
const SocialsAccountPage = lazy(() => import('../../pages/dashboard/socials/socials-account'));
// MATCH
const MatchListPage = lazy(() => import('../../pages/dashboard/match/match-list'));
const MatchDetailPage = lazy(() => import('../../pages/dashboard/match/match-detail'));
// OTHERS
const FeedPage = lazy(() => import('../../pages/dashboard/feed'));
const BoardPage = lazy(() => import('../../pages/dashboard/board'));
// STREETVIEW
const StreetViewPage = lazy(() => import('../../pages/dashboard/streetview/streetview'));
// HIGHLIGHTS
const HighlightsListPage = lazy(() => import('../../pages/dashboard/highlights/highlights-list'));
const HighlightsEditorPage = lazy(
  () => import('../../pages/dashboard/highlights/highlights-editor')
);
// RANKINGS
const RankingsPage = lazy(() => import('../../pages/dashboard/rankings/rankings'));
// STEAM CONNECTION
const SteamConnectCallback = lazy(() => import('../../sections/steam/steam-connect-callback'));
const FirstLoginChecker = lazy(() => import('../../sections/steam/guard/first-login-checker'));

export const useDashboardRoutes = () => {
  const strategyBoardPageEnabled = useFeature('VITE_FEATURE_STRATEGY_BOARD').on;
  const streetviewEnabled = useFeature('VITE_FEATURE_STREETVIEW').on;

  return [
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RealTimeCommunicationWrapper>
            <DashboardLayout fullSizeRoutes={[paths.dashboard.highlightsEditor.root]}>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>

              <HighlightsIssueInfoDialog />
              <BackgroundTasks />
              <FirstLoginChecker />
              <SteamConnectCallback />
            </DashboardLayout>
          </RealTimeCommunicationWrapper>
        </AuthGuard>
      ),
      children: [
        {
          element: <DashboardPage />,
          index: true,
        },
        {
          path: 'player-dashboard',
          children: [
            { element: <PlayerDashboardPage />, index: true },
            { path: ':id', element: <PlayerDashboardPage /> },
          ],
        },
        {
          path: 'feed',
          element: <FeedPage />,
        },
        {
          path: 'highlights',
          element: <HighlightsListPage />,
        },
        {
          path: 'highlights-editor',
          children: [
            { element: <HighlightsEditorPage />, index: true },
            { path: ':id', element: <HighlightsEditorPage /> },
          ],
        },
        {
          path: 'rankings',
          element: <RankingsPage />,
        },
        {
          path: 'match',
          children: [
            { element: <MatchListPage />, index: true },
            { path: 'list', element: <MatchListPage /> },
            { path: ':id', element: <MatchDetailPage /> },
          ],
        },
        ...addItemToArrayConditionally<RouteObject>(strategyBoardPageEnabled, {
          path: 'board',
          element: <BoardPage />,
        }),
        {
          path: 'socials',
          children: [
            { element: <SocialsAccountPage />, index: true },
            { path: 'account', element: <SocialsAccountPage /> },
          ],
        },
        ...addItemToArrayConditionally<RouteObject>(streetviewEnabled, {
          path: 'streetview',
          element: <StreetViewPage />,
        }),
      ],
    },
  ];
};
