import { Box, Stack, Typography } from '@mui/material';
import { Image } from '@rankacy/components';
import { useLocales } from '@rankacy/intl';
import { pxToRem } from '@rankacy/theme';
import React from 'react';
import { GenerateHighlightTable } from './generate-highlight-table';
import { useResponsive } from '@rankacy/hooks';
import { Trans } from 'react-i18next';

export const GenerateHighlightSection = () => {
  const { t } = useLocales();

  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  return (
    <Stack gap={7.5} sx={{ position: 'relative' }}>
      <Stack gap={2} sx={{ pl: { xs: 6, sm: 10 }, zIndex: 1 }}>
        <Typography color="primary.main" fontWeight="500" variant="h3">
          {t('landing_page.lets_create_highlight_title')}
        </Typography>

        <Typography
          fontWeight="500"
          sx={{ maxWidth: { xs: '100%', lg: pxToRem(600) } }}
          variant="h4"
        >
          <Trans
            components={{
              span: (
                <Typography
                  color="text.secondary"
                  component="span"
                  fontWeight="inherit"
                  variant="h4"
                />
              ),
            }}
            i18nKey={'landing_page.lets_create_highlight_description'}
          />
        </Typography>
      </Stack>

      <Stack sx={{ position: 'relative' }}>
        {mdUp && (
          <Box
            sx={{
              width: 65,
              height: 200,
              position: 'absolute',
              top: 10,
              left: 0,
              boxShadow: (theme) => `-30px -100px 40px 60px ${theme.palette.grey[800]}`,
            }}
          />
        )}
        {mdUp ? (
          <Image
            src={'/assets/images/home/library-bg.webp'}
            sx={{
              width: 1,
              zIndex: 1,
            }}
          />
        ) : (
          <Image
            disabledEffect
            src={'/assets/background/box_match_results.webp'}
            sx={{
              width: 375,
              zIndex: 1,
              alignSelf: 'center',
            }}
          />
        )}

        {mdUp && (
          <Box
            sx={{
              width: 100,
              height: 250,
              position: 'absolute',
              bottom: 0,
              left: 0,
              boxShadow: (theme) => `0px 200px 40px 20px ${theme.palette.grey[800]} `,
            }}
          />
        )}

        {smUp && (
          <Image
            disabledEffect
            src={'/assets/images/home/generate_highlights_overlay.webp'}
            sx={{
              width: pxToRem(700),
              position: 'absolute',
              right: pxToRem(-10),
              bottom: pxToRem(-320),
              zIndex: -1,
            }}
          />
        )}
      </Stack>

      <Stack sx={{ textAlign: 'center' }}>
        <Typography color="primary.main" sx={{ mx: 'auto' }} variant="h4">
          {t('landing_page.ready_to_generate')}
        </Typography>
        <Typography
          fontWeight="500"
          sx={{ maxWidth: { xs: '80%', md: pxToRem(500), lg: pxToRem(600) }, mx: 'auto' }}
          variant="h5"
        >
          <Trans
            components={{
              span: (
                <Typography
                  color="text.secondary"
                  component="span"
                  fontWeight="inherit"
                  variant="h5"
                />
              ),
            }}
            i18nKey={'landing_page.ready_to_generate_description'}
          />
        </Typography>
      </Stack>

      {!mdUp && (
        <Box
          sx={{
            backgroundColor: ({ palette }) => palette.background.default,
            position: 'absolute',
            left: -10,
            top: 0,
            width: '110%',
            height: 1,
            zIndex: -1,
            boxShadow: (theme) => `0px 0px 40px 50px ${theme.palette.grey[900]}`,
          }}
        />
      )}

      <GenerateHighlightTable />
    </Stack>
  );
};
