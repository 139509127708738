import './sentry-instrument';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'regenerator-runtime';
import { HelmetProvider } from 'react-helmet-async';
import App from './app';
import { initIntl, Namespaces } from '@rankacy/intl';
import { initializeGoogleTagManager } from '@rankacy/analytics';
import { CacheBuster } from '@piplup/cache-buster';
import { config } from './config';

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

initializeGoogleTagManager({ gtmId: config.GTM_ID });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

initIntl(Namespaces.Rankacy);

root.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <CacheBuster enabled={config.PROD}>
            <App />
          </CacheBuster>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>
);
