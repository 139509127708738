import { paths } from '../../routes/paths';

export const CAROUSEL_CARDS = (mdDown = false) => [
  {
    image: '/assets/background/level-up.webp',
    title: 'landing_page.join_community_title',
    action: {
      label: 'sign_up_today',
      href: paths.register,
    },
  },
  {
    image: '/assets/background/landing-page-all-in-one.webp',
    title: 'landing_page.all_in_one_title',
    subTitle: 'landing_page.all_in_one_subtitle',
    color: mdDown ? 'text.primary' : 'grey.800',
  },
  {
    image: '/assets/background/landing-page-in-depth-intel.webp',
    title: 'landing_page.in_depth_title',
    subTitle: 'landing_page.in_depth_subtitle',
    color: 'text.primary',
  },
  {
    image: '/assets/background/landing-page-real-time-coaching.webp',
    title: 'landing_page.real_time_title',
    subTitle: 'landing_page.real_time_subtitle',
    color: 'text.primary',
  },
];
