import { z } from 'zod';

/**
 * EnvParser is a class that provides an easy way to validate and access environment variables.
 * It uses a provided Zod schema to ensure that all environment variables are validated correctly.
 */
export class EnvParser<T extends z.ZodObject<any>> {
  private parsedEnv: z.infer<T>;

  /**
   * Creates an instance of EnvParser and parses the environment variables using the provided schema.
   *
   * @param schema - The Zod schema to validate and parse environment variables against
   * @throws Error if validation fails or required variables are missing
   */
  constructor(private schema: T) {
    this.parsedEnv = this.parseEnvironmentVariables();
  }

  /**
   * Get a list of missing environment variables based on the provided schema.
   *
   * @returns A list of missing environment variables
   */
  private getMissingVariables(): string[] {
    const schemaKeys = Object.keys(this.schema.shape) as Array<keyof typeof this.schema.shape>;

    return schemaKeys
      .filter((key) => {
        const schemaKey = key as keyof typeof this.schema.shape;

        return this.schema.shape[schemaKey]._def.typeName !== 'ZodOptional';
      })
      .filter((key) => !import.meta.env[key as string]) as string[];
  }

  /**
   * Parse and validate the environment variables against the provided schema.
   *
   * @returns Parsed environment variables if validation succeeds
   * @throws Error if validation fails or required variables are missing
   */
  private parseEnvironmentVariables(): z.infer<T> {
    try {
      return this.schema.parse(import.meta.env);
    } catch {
      const missingVariables = this.getMissingVariables();
      throw new Error(
        `
          Incorrect environment setup, refer to the documentation.
          
          This application requires the following environment variables: ${JSON.stringify(
            Object.keys(this.schema.keyof().Values)
          )}.

          Missing variables: ${missingVariables.join(', ')}
        `
      );
    }
  }

  /**
   * Get a specific environment variable safely.
   *
   * @param key - The key of the environment variable to retrieve
   * @returns The value of the environment variable
   */
  public get<K extends keyof z.infer<T>>(key: K): z.infer<T>[K] {
    return this.parsedEnv[key];
  }
}
