import { alpha, Theme } from '@mui/material/styles';

const Backdrop = (theme: Theme) => ({
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.grey[800], 0.4),
        backdropFilter: 'blur(10px)',
      },
      invisible: {
        background: 'transparent',
        backdropFilter: 'none',
      },
    },
  },
});

export default Backdrop;
