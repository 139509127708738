import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUserDemoProcessingApiDemosProcessingGetQuery } from '@rankacy/services';

const _10_SECONDS = 10_000;

/**
 * useGetTmpDemos - Custom hook to get the temporary demos from the API.
 * It will also check for changes in the demos status and invalidate the cache when needed.
 *
 * @param isSuccessDemoUpload - A boolean to determine if the demo upload was successful.
 * @returns The temporary demos and their status.
 */
export const useGetTmpDemos = (isSuccessDemoUpload: boolean) => {
  const [checkingForChanges, setCheckingForChanges] = useState(false);

  const dispatch = useDispatch();

  const { data, isFetching } = useUserDemoProcessingApiDemosProcessingGetQuery(undefined, {
    pollingInterval: checkingForChanges ? _10_SECONDS : undefined,
  });

  const [lastProcessingCount, setLastProcessingCount] = useState(0);

  const processingDemos = useMemo(
    () => data?.filter(({ status }) => status === 'PROCESSING') || [],
    [data]
  );
  const failedDemos = useMemo(
    () => data?.filter(({ status }) => status === 'FAILED') || [],
    [data]
  );
  const queuedDemos = useMemo(
    () => data?.filter(({ status }) => status === 'QUEUED') || [],
    [data]
  );

  // TODO: refactor this to use a better approach
  useEffect(() => {
    if (isSuccessDemoUpload || processingDemos.length > 0 || queuedDemos.length > 0) {
      setCheckingForChanges(true);
    } else if (processingDemos.length < lastProcessingCount || queuedDemos.length < 1) {
      if (checkingForChanges) {
        dispatch({
          type: `api/invalidateTags`,
          payload: ['demos'],
        });
      }

      setLastProcessingCount(processingDemos.length);

      setCheckingForChanges(false);
    }
  }, [
    isSuccessDemoUpload,
    processingDemos,
    queuedDemos,
    checkingForChanges,
    dispatch,
    lastProcessingCount,
  ]);

  return {
    failedDemos,
    processingDemos,
    queuedDemos,
    //
    failedDemosCount: failedDemos.length,
    processingDemosCount: processingDemos.length,
    queuedDemosCount: queuedDemos.length,
    //
    isTmpDemosFetching: isFetching,
    isDoneProcessing: processingDemos.length === 0,
  };
};
