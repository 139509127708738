import { Theme } from '@mui/material/styles';
import { useResponsive } from '@rankacy/hooks';

const Card = (theme: Theme) => {
  const mdDown = useResponsive('down', 'md');

  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          boxShadow: theme.customShadows.card,
          borderRadius: theme.shape.borderRadius * 2,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          borderColor: theme.palette.border,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: mdDown ? theme.spacing(2) : theme.spacing(3, 4),
          '&:last-child': {
            paddingBottom: mdDown ? theme.spacing(2) : theme.spacing(3),
          },
        },
      },
    },
  };
};

export default Card;
