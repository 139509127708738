import './globals.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { store } from './redux/store';
import { FeatureFlagsProvider } from '@rankacy/feature-flags';
import Router from './routes/sections';
import { useScrollToTop } from '@rankacy/hooks';
import { SettingsProvider } from '@rankacy/settings';
import { AuthConsumer, AuthProvider } from './auth';
import { ThemeProvider } from '@rankacy/theme';
import { MotionLazy, ProgressBar, SnackbarProvider } from '@rankacy/components';
import { useEffect } from 'react';
import { config } from './config';

const App = () => {
  useScrollToTop();

  useEffect(() => {
    if (window.location.hostname === 'www.rankacy.com') {
      const newURL = window.location.href.replace('www.rankacy.com', 'rankacy.com');
      window.location.replace(newURL);
    }
  }, []);

  return (
    <ReduxStoreProvider store={store}>
      <FeatureFlagsProvider features={config.FEATURE_FLAGS}>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider
              defaultSettings={{
                themeMode: 'dark',
                themeLayout: 'vertical',
              }}
            >
              <ThemeProvider>
                <MotionLazy>
                  <SnackbarProvider>
                    {/* <SettingsDrawer /> */}
                    <ProgressBar />
                    <AuthConsumer>
                      <Router />
                    </AuthConsumer>
                  </SnackbarProvider>
                </MotionLazy>
              </ThemeProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </AuthProvider>
      </FeatureFlagsProvider>
    </ReduxStoreProvider>
  );
};

export default App;
