import { Button, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useLocales } from '@rankacy/intl';
import { paths } from '../../routes/paths';
import { Logo } from '@rankacy/components';
import { RouterLink } from '@rankacy/routing';
import { SocialMediaIcons } from '../_common';

const PAGES = [
  {
    title: 'privacy_policy',
    href: paths.privacyPolicy,
  },
  {
    title: 'terms_conditions',
    href: paths.termsAndConditions,
  },
];

const Footer = () => {
  const { t } = useLocales();

  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const domain = 'rankacy.com';
    const user = 'info';

    const email = `${user}@${domain}`;

    e.preventDefault();
    window.location.href = `mailto:${email}`;
  };

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 7.5,
        mt: 7.5,
      }}
    >
      <Container>
        <Stack sx={{ gap: 4 }}>
          <Stack
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Logo logoHeight={56} sx={{ mb: { xs: 4, md: 0 } }} variant="white" />
            <Stack sx={{ flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 2, md: 5 } }}>
              {PAGES.map(({ href, title }, idx) => (
                <Button
                  component={RouterLink}
                  href={href}
                  key={idx}
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                  variant="text"
                >
                  {t(title)}
                </Button>
              ))}
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: { xs: 'column-reverse', md: 'row' },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: { xs: 2, md: 0 },
            }}
          >
            <Typography sx={{ color: 'text.secondary' }} variant="body2">
              {t('all_rights_reserved_year', { year: new Date().getFullYear() })} <br />
              Veletržní 1623/24, Holešovice, 170 00 Prague
              <br />
              tel: +420 608 397 460
              <br />
              <Typography
                component="a"
                href="#"
                sx={{ color: 'primary.main' }}
                variant="body2"
                onClick={handleEmailClick}
              >
                info@rankacy.com
              </Typography>
            </Typography>

            <SocialMediaIcons />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );

  return simpleFooter;
};

export default Footer;
