import { Card, CardContent, Typography } from '@mui/material';
import { fShortenNumber } from '@rankacy/utils';
import { TooltipProps } from 'recharts';

type Props = {
  title?: string;
} & TooltipProps<number, string>;

export const CustomTooltip = ({ title, active, payload }: Props) => (
  <Card variant="outlined">
    <CardContent
      sx={{
        p: 2,
        '&:last-child': {
          pb: 2,
        },
      }}
    >
      {title && <Typography variant="subtitle2">{title}</Typography>}
      {active &&
        payload &&
        payload.map(({ value }) => (
          <Typography color="text.secondary" key={value} variant="body2">
            {value ? fShortenNumber(value) : '-'}
          </Typography>
        ))}
    </CardContent>
  </Card>
);
