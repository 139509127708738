import { get } from 'lodash';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type UploadingProgressState = {
  uploadingDemos: {
    [hash: string]: { progress: number; name: string };
  };
};

const initialState: UploadingProgressState = {
  uploadingDemos: {},
};

export const name = 'demoUploading';

export const demoUploadingSlice = createSlice({
  name,
  initialState,
  reducers: {
    setUploadingDemosWithProgress: (
      state,
      action: PayloadAction<{
        hash: string;
        progress: number;
        name: string;
      }>
    ) => {
      state.uploadingDemos[action.payload.hash] = {
        progress: action.payload.progress,
        name: action.payload.name,
      };
    },
    removeUploadingDemoByHash: (state, action: PayloadAction<string>) => {
      delete state.uploadingDemos[action.payload];
    },
  },
});

export const { setUploadingDemosWithProgress, removeUploadingDemoByHash } =
  demoUploadingSlice.actions;

export { name as demoUploadingName };

export const { reducer: demoUploadingReducer } = demoUploadingSlice;

const selectUploadingDemosWithProgress = (state: UploadingProgressState): UploadingProgressState =>
  get(state, [name]);

export { selectUploadingDemosWithProgress };
