import Cookies from 'js-cookie';
import { config } from '../../../config';

export const createSocketURL = () => {
  try {
    const accessToken = Cookies.get(config.ACCESS_TOKEN_KEY);

    return `${config.WS_BASE_URL}/track/?token=${accessToken}`;
  } catch (error) {
    throw new Error(`Error while creating socket URL: ${error}`);
  }
};
