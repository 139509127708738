import { Area, AreaChart, Tooltip, YAxis } from 'recharts';
import { ChartWrapper } from './common/chart-wrapper';
import { ChartItem, ChartProps } from './common/types';
import { useTheme } from '@mui/material';
import { CustomTooltip } from './common/custom-tooltip';

type Props<T extends ChartItem> = { fillGradient?: [string, string] } & ChartProps<T>;

export const CustomAreaChart = <T extends ChartItem>({
  data,
  height,
  showAxis,
  dataKey,
  lineColor,
  fillGradient,
  disableEmptyContent,
  emptyContentLabel,
  showTooltip,
  tooltipTitle,
}: Props<T>) => {
  const { palette } = useTheme();

  return (
    <ChartWrapper
      dataLength={data.length}
      disableEmptyContent={disableEmptyContent}
      emptyContentLabel={emptyContentLabel}
      height={height}
    >
      <AreaChart data={data}>
        <defs>
          <linearGradient id="colorArea" x1="0" x2="0" y1="0" y2="1">
            <stop
              offset="5%"
              stopColor={fillGradient?.[0] ?? palette.primary.main}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={fillGradient?.[1] ?? palette.primary.main}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <YAxis
          axisLine={false}
          color={palette.grey[400]}
          domain={['auto', 'auto']}
          hide={!showAxis}
          stroke={palette.grey[600]}
          tick={showAxis}
        />
        <Area
          dataKey={dataKey}
          fill="url(#colorArea)"
          fillOpacity={1}
          stroke={lineColor ?? palette.primary.main}
          type="monotone"
        />
        {showTooltip && <Tooltip content={<CustomTooltip title={tooltipTitle} />} />}
      </AreaChart>
    </ChartWrapper>
  );
};
