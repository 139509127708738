import { SteamLoginDataRequest, UserResponse } from '@rankacy/services';

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthUserType = null | UserResponse;

export type AuthStateType = {
  status?: string;
  loading: boolean;
  user: AuthUserType;
};

export enum SteamActionType {
  Login = 'login',
  Connect = 'connect',
}

export type JWTContextType = {
  login?: (emailOrUsername: string, password: string) => Promise<void>;
  confirmRegistration?: (email: string, token: string) => Promise<void>;
  loginWithSteam?: (steamCredentials: SteamLoginDataRequest) => Promise<boolean | undefined>;
  disconnectFromSteam: () => Promise<void>;
  connectWithSteam: (steamCredentials: SteamLoginDataRequest) => Promise<void>;
  user: AuthUserType;
  method: string;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  logout: () => Promise<void>;
};
