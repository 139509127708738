import { Box, Typography, useTheme } from '@mui/material';
import { bgBlur } from '@rankacy/theme';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, YAxis } from 'recharts';
import { useLocales } from '@rankacy/intl';

type Props = {
  height?: number;
  label?: string;
};
export const EmptyChart = ({ height, label }: Props) => {
  const { t } = useLocales();
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: 1,
        minHeight: 50,
        height: height ?? '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: 1,
          height: height,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          ...bgBlur({ color: palette.grey[900], opacity: 0.2, blur: 5 }),
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',

          zIndex: 2,
        }}
      >
        <Typography sx={{ color: palette.text.secondary, fontWeight: 500 }} variant="body2">
          {label ? label : t('no_data')}
        </Typography>
      </Box>
      <ResponsiveContainer height="100%" width="100%">
        <LineChart data={MOCKED_DATA} height={height} width={400}>
          <CartesianGrid stroke={palette.grey[600]} strokeDasharray="4" vertical={false} />
          <YAxis hide axisLine={false} color={palette.grey[400]} domain={['auto', 'auto']} />
          <Line
            dataKey={MOCKED_DATA_KEY}
            dot={false}
            stroke={palette.primary.main}
            style={{
              maskImage:
                'linear-gradient(to right, transparent 0%, black 20%, black 80%, transparent 100%)',
            }}
            type="monotone"
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default EmptyChart;

const MOCKED_VALUES = Array.from({ length: 7 }, () =>
  Math.floor(Math.random() * (280 - 20 + 1) + 20)
);
const MOCKED_DATA_KEY = 'point';
const MOCKED_DATA = MOCKED_VALUES.map((value) => ({ [MOCKED_DATA_KEY]: value }));
