import { Box, Container } from '@mui/material';
import { useResponsive } from '@rankacy/hooks';
import { HomeCarouselCard } from '../home-carousel-card';
import { CAROUSEL_CARDS } from '../types';
import { HeroSection } from '../hero-section';
import { useRef } from 'react';
import { PlayerScroll } from '../../../components/player-scroll';
import { GenerateHighlightSection } from '../generate-highlight-section';
import { StatsSection } from '../stats-section';
import { WhatNextSection } from '../what-next-section';

const HomeView = () => {
  const smDown = useResponsive('down', 'sm');

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <HeroSection />

      <Container
        ref={containerRef}
        sx={{
          gap: { xs: 7.5, lg: 15 },
          display: 'flex',
          flexDirection: 'column',
          px: '0px !important',
          position: 'relative',
          mb: { xs: 12, md: 0 },
        }}
      >
        <PlayerScroll containerRef={containerRef} scrollLeftOffset={smDown ? 36 : 64} />

        <Container
          sx={{
            mt: { xs: 20, lg: 15 },
          }}
        >
          <GenerateHighlightSection />
        </Container>

        <Container>
          <StatsSection />
        </Container>

        <Container>
          <WhatNextSection />
        </Container>
      </Container>

      <Container sx={{ mt: { xs: 7.5, lg: 15 } }}>
        <HomeCarouselCard data={CAROUSEL_CARDS(smDown)} />
      </Container>
    </Box>
  );
};

export default HomeView;
