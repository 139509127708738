import { IconButton, Stack, StackProps } from '@mui/material';
import { Iconify } from '@rankacy/iconify';
import React from 'react';

const SOCIALS = [
  {
    title: 'email',
    // Create the email link dynamically using JavaScript to prevent scraping
    href: '',
    icon: 'mdi:email',
  },
  {
    title: 'X',
    href: 'https://x.com/rankacy',
    icon: 'ri:twitter-x-fill',
  },
  {
    title: 'discord',
    href: 'https://discord.gg/WUyQbwbHaJ',
    icon: 'mdi:discord',
  },
  {
    title: 'instagram',
    href: 'https://www.instagram.com/rankacyapp/',
    icon: 'mdi:instagram',
  },
  {
    title: 'facebook',
    href: 'https://www.facebook.com/profile.php?id=61563687810542',
    icon: 'mdi:facebook',
  },
  {
    title: 'tiktok',
    href: 'https://tiktok.com/@rankacy.com',
    icon: 'ic:baseline-tiktok',
  },
  {
    title: 'youtube',
    href: 'https://www.youtube.com/@rankacy',
    icon: 'mdi:youtube',
  },
];

type Props = StackProps;

export const SocialMediaIcons = ({ sx, ...props }: Props) => (
  <Stack sx={{ flexDirection: 'row', gap: { xs: 1, sm: 2, md: 3 }, ...sx }} {...props}>
    {SOCIALS.map(({ title, href, icon }, idx) => {
      // Create the email link dynamically using JavaScript to prevent scraping
      const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (title === 'email') {
          const domain = 'rankacy.com';
          const user = 'info';

          const email = `${user}@${domain}`;

          e.preventDefault();
          window.location.href = `mailto:${email}`;
        }
      };

      return (
        <IconButton
          aria-label={title}
          component="a"
          href={title === 'email' ? '#' : href}
          key={idx}
          rel="noopener noreferrer"
          sx={{
            color: 'text.secondary',
            '&:hover': {
              color: 'primary.main',
            },
          }}
          target="_blank"
          onClick={title === 'email' ? handleClick : undefined}
        >
          <Iconify height={24} icon={icon} width={24} />
        </IconButton>
      );
    })}
  </Stack>
);
