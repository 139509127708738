import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge, { badgeClasses } from '@mui/material/Badge';
import { useAuthContext } from '../../auth';
import { Box, Button, Stack } from '@mui/material';
import { useOffSetTop, useResponsive } from '@rankacy/hooks';
import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { RegisterButton, LoginButton } from '../_common';
import { Logo } from '@rankacy/components';
import { HeaderShadow } from '@rankacy/layouts';
import { useLocales } from '@rankacy/intl';
import { RouterLink } from '@rankacy/routing';
import { paths } from '../../routes/paths';

const Header = () => {
  const { t } = useLocales();

  const { authenticated } = useAuthContext();

  const navConfig = useNavData();

  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const renderHeaderActions = (
    <Stack sx={{ flexDirection: 'row', gap: 3 }}>
      {authenticated ? (
        <Button
          LinkComponent={RouterLink}
          href={paths.dashboard.root}
          size="large"
          sx={{ ml: 2 }}
          variant="contained"
        >
          {t('go_to_rankacy')}
        </Button>
      ) : (
        <>
          <LoginButton sx={{ ml: 2 }} />
          <RegisterButton />
        </>
      )}
    </Stack>
  );

  return (
    <AppBar>
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            top: 15,
            borderRadius: theme.shape.borderRadius * 0.4,
            boxShadow: theme.customShadows.z16,
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_DESKTOP,
            },
            transition: theme.transitions.create(['height'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            border: `1px solid ${theme.palette.border}`,
            bgcolor: theme.palette.grey[700],
            ...(offsetTop && {
              height: {
                xs: HEADER.H_MOBILE,
                md: HEADER.H_DESKTOP_OFFSET,
              },
            }),
          }}
        >
          <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
            <Badge
              sx={{
                [`& .${badgeClasses.badge}`]: {
                  top: 8,
                  right: -16,
                },
              }}
            >
              <Logo logoHeight={mdUp ? 48 : 38} />
            </Badge>

            {!mdUp && <NavMobile data={navConfig} offsetTop={offsetTop} />}

            {mdUp && <Box sx={{ flexGrow: 1 }} />}

            {mdUp && <NavDesktop data={navConfig} offsetTop={offsetTop} />}

            {mdUp && renderHeaderActions}
          </Container>
        </Toolbar>

        {offsetTop && <HeaderShadow />}
      </Container>
    </AppBar>
  );
};

export default Header;
