import { Button, IconButton, Link, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import React from 'react';
import { useLocales } from '@rankacy/intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import {
  handleErrorMessage,
  useUpdateSteamCodeApiUserSteamUpdateGamecodePutMutation,
} from '@rankacy/services';
import { Iconify } from '@rankacy/iconify';
import { FormProvider, RHFTextField } from '@rankacy/components';
import { RouterLink } from '@rankacy/routing';
import { paths } from '../../../routes/paths';

const STEAM_CONNECTION_URL =
  'https://help.steampowered.com/wizard/HelpWithGameIssue/?appid=730&issueid=128&transid=3301752102843561810&line_item=3301752102843561813';

const customTextFieldStyles = {
  '& label': {
    color: 'grey.200',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'grey.200',
    },
  },
};

type FormValuesProps = { match_token: string; auth_code: string };

type Props = { onClose?: VoidFunction; onBack?: VoidFunction; type?: 'dialog' | 'view' };

export const SteamAutoDownloadConnectionView = ({ onClose, onBack, type = 'dialog' }: Props) => {
  const { t } = useLocales();

  const [updateCodes, { isLoading }] = useUpdateSteamCodeApiUserSteamUpdateGamecodePutMutation();

  const AccountSteamSchema = Yup.object().shape({
    auth_code: Yup.string()
      .required(t('auth_code_validation'))
      .matches(/^[A-Za-z0-9]{4}-[A-Za-z0-9]{5}-[A-Za-z0-9]{4}$/, t('invalid_auth_code')),
    match_token: Yup.string()
      .required(t('match_token_validation'))
      .matches(
        /^CSGO-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5,6}$/,
        t('invalid_match_token')
      ),
  });

  const defaultValues = {
    auth_code: '',
    match_token: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(AccountSteamSchema) as any,
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async ({ auth_code, match_token }: FormValuesProps) => {
    try {
      await updateCodes({
        steamCodeUpdateRequest: { auth_code, match_code: match_token },
      }).unwrap();
      enqueueSnackbar(t('auto_download_enabled'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(handleErrorMessage(error), { variant: 'error' });
    } finally {
      reset();
      onClose?.();
    }
  };

  return (
    <Stack gap={2}>
      <Stack
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          p: 2,
          flexDirection: 'row',
          justifyContent: onBack ? 'space-between' : 'flex-end',
          width: 1,
        }}
      >
        {onBack && (
          <IconButton disableRipple sx={{ p: 0 }} onClick={onBack}>
            <Iconify icon="mdi:chevron-left" width={24} />
          </IconButton>
        )}
        {type === 'dialog' && (
          <IconButton disableRipple sx={{ p: 0 }} onClick={onClose}>
            <Iconify icon="mdi:close" width={24} />
          </IconButton>
        )}
      </Stack>

      <Typography textAlign="left" variant="subtitle1">{`${t('one_last_step')} ...`}</Typography>

      <Typography textAlign="left" variant="h6">
        {t('steam_connect_pro_title')}
      </Typography>

      <Typography sx={{ textAlign: 'left' }} variant="body2">
        <Typography component="span">
          {t('steam_connect_step_one')}{' '}
          <Link
            color="primary.main"
            component={RouterLink}
            fontWeight="bold"
            href={STEAM_CONNECTION_URL}
            target="_blank"
          >
            {t('here')}
          </Link>
        </Typography>
        <br />
        {t('steam_connect_step_two')}
        <br />
        {t('steam_connect_step_three')}
        <br />
        {t('steam_connect_step_four')}
      </Typography>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} sx={{}}>
          <Typography textAlign="left" variant="body2">{`${t('paste_codes')}:`}</Typography>
          <RHFTextField
            label={t('match_token')}
            name="match_token"
            size={type === 'dialog' ? 'medium' : 'small'}
            sx={{
              ...customTextFieldStyles,
            }}
          />
          <RHFTextField
            label={t('auth_code')}
            name="auth_code"
            size={type === 'dialog' ? 'medium' : 'small'}
            sx={{
              ...customTextFieldStyles,
            }}
          />

          <LoadingButton
            loading={isLoading}
            size="large"
            sx={{ width: 'fit-content', mx: 'auto', mt: 1 }}
            type="submit"
            variant="contained"
          >
            {t('connect_demos')}
          </LoadingButton>
        </Stack>
      </FormProvider>

      {onBack && (
        <Button
          LinkComponent={RouterLink}
          href={paths.dashboard.root}
          sx={{ width: 'fit-content', mx: 'auto' }}
          variant="text"
        >
          {t('continue_manually')}
        </Button>
      )}
    </Stack>
  );
};
