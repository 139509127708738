const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

export const paths = {
  // about: '/about-us',
  publicHighlight: (hash: string) => `/highlight/${hash}`,
  blog: { root: '/blog', article: (id: string) => `/blog/${id}` },
  page404: '/404',
  page403: '/403',
  page500: '/500',
  maintenance: '/maintenance',
  generalErrorPage: '/general-error',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',

  // AUTH
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  newPassword: '/new-password',
  registerCompletion: '/register-completion',

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    // PLAYER DASHBOARD
    playerDashboard: {
      root: `${ROOTS.DASHBOARD}/player-dashboard`,
      detail: (id: string) => `${ROOTS.DASHBOARD}/player-dashboard/${id}`,
    },
    // HIGHLIGHTS
    feed: `${ROOTS.DASHBOARD}/feed`,
    highlights: {
      root: `${ROOTS.DASHBOARD}/highlights`,
    },
    highlightsEditor: {
      root: `${ROOTS.DASHBOARD}/highlights-editor`,
      game: (id: string) => `${ROOTS.DASHBOARD}/highlights-editor/${id}`,
    },
    // HIGHLIGHTS
    rankings: {
      root: `${ROOTS.DASHBOARD}/rankings`,
    },

    match: {
      root: `${ROOTS.DASHBOARD}/match`,
      list: `${ROOTS.DASHBOARD}/match/list`,
      detail: (id: string) => `${ROOTS.DASHBOARD}/match/${id}`,
    },
    board: `${ROOTS.DASHBOARD}/board`,
    socials: {
      root: `${ROOTS.DASHBOARD}/socials`,
      account: `${ROOTS.DASHBOARD}/socials/account`,
    },
    streetview: `${ROOTS.DASHBOARD}/streetview`,
  },
};
