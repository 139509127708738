import { Button, Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useLocales } from '@rankacy/intl';
import { useResponsive } from '@rankacy/hooks';
import { RouterLink } from '@rankacy/routing';
import { pxToRem } from '@rankacy/theme';
import { Carousel, CarouselDots, ConditionalWrap, useCarousel } from '@rankacy/components';

type Props = {
  data: {
    image: string;
    title: string;
    subTitle?: string;
    color?: string;
    action?: {
      label: string;
      href: string;
    };
  }[];
  disableCarousel?: boolean;
};

export const HomeCarouselCard = ({ data, disableCarousel = false }: Props) => {
  const { t } = useLocales();

  const carousel = useCarousel({
    autoplay: true,
    fade: true,
    pauseOnHover: true,
    draggable: false,
    ...CarouselDots({
      sx: { position: 'absolute', bottom: 30, right: 30, color: 'text.primary' },
    }),
  });

  const mdUp = useResponsive('up', 'md');

  const { palette, typography } = useTheme();

  return (
    <Card>
      <ConditionalWrap
        condition={!disableCarousel}
        wrap={(children) => (
          <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
            {children}
          </Carousel>
        )}
      >
        {data.map(({ image, title, subTitle, action, color = 'grey.800' }) => (
          <CardContent
            key={title}
            sx={{
              display: 'flex',
              flexDirection: mdUp ? 'row' : 'column-reverse',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
              background: `url(${image})`,
              backgroundColor: '#EFFAEA',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: { xs: '75% 50%', md: 'top right' },
              backgroundSize: 'cover',
              maxHeight: '100%',
              pl: { xs: 4, md: 6, lg: 12 },
              '&.MuiCardContent-root:last-child': {
                pb: { xs: 4, md: 6, lg: 12 },
              },
              height: {
                xs: 500,
                md: 600,
              },
            }}
          >
            <Stack
              sx={{
                textAlign: 'left',
                mr: 'auto',
                height: 1,
                justifyContent: 'flex-end',
                maxWidth: { xs: '100%', sm: '80%', md: pxToRem(480) },
                color,
                gap: { xs: 1, md: 2 },
              }}
            >
              <Typography
                sx={{
                  fontWeight: typography.fontWeightMedium,
                }}
                variant="h2"
              >
                {t(title)}
              </Typography>

              {subTitle && (
                <Typography
                  sx={{
                    fontSize: { xs: pxToRem(14), md: pxToRem(22) },
                    fontFamily: typography.h1.fontFamily,
                    fontWeight: typography.fontWeightLight,
                  }}
                >
                  {t(subTitle)}
                </Typography>
              )}

              {action && (
                <Button
                  LinkComponent={RouterLink}
                  href={action.href}
                  size="large"
                  sx={{
                    width: 'fit-content',
                    border: `1px solid ${palette.grey[800]}`,
                    color: 'grey.800',
                    px: 4,
                  }}
                  variant="outlined"
                >
                  {t(action.label)}
                </Button>
              )}
            </Stack>
          </CardContent>
        ))}
      </ConditionalWrap>
    </Card>
  );
};
