import { IconButton, Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useLocales } from '@rankacy/intl';
import { useBoolean } from '@rankacy/hooks';
import { Indicator } from './styles';
import { BackgroundTaskItem } from './background-task-item';
import { Iconify } from '@rankacy/iconify';
import { RouterLink } from '@rankacy/routing';
import { paths } from '../../routes/paths';
import { Scrollbar } from '@rankacy/components';
import { pxToRem } from '@rankacy/theme';

type ITaskItem = {
  progress?: number;
  label: string;
};

type Props = {
  activeBackgroundTasks?: number;
  title?: string;
  failed?: ITaskItem;
  queued?: ITaskItem;
  tasksWithProgressComponent?: ReactElement | null;
  onClearFailed?: () => void;
  alert?: ReactElement | null;
};

export const BackgroundTasksIndicator = ({
  activeBackgroundTasks,
  queued,
  failed,
  title,
  tasksWithProgressComponent,
  alert,
  onClearFailed,
}: Props) => {
  const { t } = useLocales();

  const { value: opened, onToggle } = useBoolean(true);

  const someTasksWithoutFailed = queued || tasksWithProgressComponent;

  const someTaskIncludingFailed = someTasksWithoutFailed || failed;

  if (!someTaskIncludingFailed) return null;

  const renderHead = (
    <Stack
      sx={{ gap: 1, flexDirection: 'row', justifyContent: 'space-between', cursor: 'pointer' }}
      onClick={onToggle}
    >
      <Typography
        sx={{
          fontFamily: ({ typography }) => typography.button.fontFamily,
          flexGrow: 1,
          fontWeight: 400,
        }}
        variant="subtitle2"
      >
        {title || t('background_tasks', { count: activeBackgroundTasks })}
      </Typography>

      <IconButton
        disableRipple
        sx={{ p: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          onToggle();
        }}
      >
        <Iconify
          color="text.primary"
          icon={opened ? 'mdi:chevron-down' : 'mdi:chevron-up'}
          width={24}
        />
      </IconButton>
    </Stack>
  );

  return (
    <Indicator>
      {renderHead}

      <Scrollbar forceVisible sx={{ maxHeight: pxToRem(400) }}>
        <Stack sx={{ gap: 1, py: opened ? 1 : 0 }}>
          {opened && (
            <>
              {alert}

              {tasksWithProgressComponent}

              {queued && (
                <>
                  <Typography
                    color="text.secondary"
                    component={RouterLink}
                    href={paths.dashboard.match.list}
                    variant="caption"
                  >
                    {t('queued')}
                  </Typography>

                  <BackgroundTaskItem label={queued.label} type="queued" />
                </>
              )}

              {failed && (
                <>
                  <Typography
                    color="text.secondary"
                    component={RouterLink}
                    href={paths.dashboard.match.list}
                    variant="caption"
                  >
                    {t('failed')}
                  </Typography>

                  <BackgroundTaskItem
                    abortTask={onClearFailed}
                    label={failed.label}
                    type="failed"
                  />
                </>
              )}
            </>
          )}
        </Stack>
      </Scrollbar>
    </Indicator>
  );
};
