export class ApplicationSettings<T extends Record<string, unknown>> {
  private settings: T | null = null;

  private readonly name: string;

  constructor(name: string) {
    this.name = name;
  }

  get values(): T {
    if (!this.settings) {
      throw new Error(`Missing settings for ${this.name}`);
    }

    return this.settings;
  }

  public initialize(settings: T) {
    this.settings = settings;
  }
}
