import * as Yup from 'yup';
import { Stack, Typography } from '@mui/material';
import { useLocales } from '@rankacy/intl';
import React from 'react';
import {
  handleErrorMessage,
  useValidateUsernameApiUserUsernameValidationPostMutation,
} from '@rankacy/services';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '@rankacy/components';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { paths } from '../../routes/paths';

type FormValuesProps = {
  username?: string | null;
};

const defaultValues = {
  username: '',
};

export const HeroSectionForm = () => {
  const { t } = useLocales();

  const navigate = useNavigate();

  const [validateUsername] = useValidateUsernameApiUserUsernameValidationPostMutation();

  const FormSchema = Yup.object().shape({
    username: Yup.string()
      .matches(/^[\w.@+-]+$/, t('user_name_validation_regex'))
      .nullable()
      .transform((value) => (value === '' ? null : value))
      .optional(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async ({ username }: FormValuesProps) => {
    try {
      if (username) {
        await validateUsername({ username }).unwrap();
      }

      navigate({
        pathname: paths.register,
        search: username ? `?username=${username}` : undefined,
      });
    } catch (error) {
      setError('username', { message: handleErrorMessage(error) });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2} sx={{ width: 1 }}>
        <Typography variant="subtitle1">{t('landing_page.start_registration')}</Typography>

        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            width: 1,
            alignItems: 'flex-start',
          }}
        >
          <RHFTextField
            InputProps={{
              sx: {
                borderRadius: ({ shape }) => shape.borderRadius * 5,
                height: 50,
              },
            }}
            name="username"
            placeholder={t('landing_page.type_nickname')}
            sx={{ flexGrow: 1 }}
          />

          <LoadingButton
            loading={isSubmitting}
            size="large"
            sx={{ textWrap: 'nowrap', width: { xs: 1, sm: 'fit-content' } }}
            type="submit"
            variant="contained"
          >
            {t('join_alpha')}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
