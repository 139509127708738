import { Helmet } from 'react-helmet-async';
import { config } from '../../config';
import { FeatureEnabledWrapper } from '@rankacy/feature-flags';
import { GiveAwayDialog } from '../../layouts/_common/giveaway-dialog';
import { useLocales } from '@rankacy/intl';
import { HomeView } from '../../sections/home/view';

const HomePage = () => {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title>
          {t('home')} | {config.APP_NAME}
        </title>
      </Helmet>

      <HomeView />
      <FeatureEnabledWrapper feature="VITE_FEATURE_GIVEAWAY">
        <GiveAwayDialog />
      </FeatureEnabledWrapper>
    </>
  );
};

export default HomePage;
