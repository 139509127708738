import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Iconify } from '@rankacy/iconify';
import { Typography } from '@mui/material';

type Props = CircularProgressProps & {
  value?: number;
  unit?: string;
  size?: number;
  hoverFromOutside?: boolean;
  onClick?: VoidFunction;
};

export const CircularProgressWithDiscard = ({
  value,
  hoverFromOutside,
  onClick,
  unit = '%',
  size = 40,
  color = 'primary',
  ...props
}: Props) => (
  <Box sx={{ position: 'relative', display: 'inline-flex', width: size, height: size }}>
    <CircularProgress color={color} size={size} {...props} />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {onClick ? (
        <IconButton
          color={color}
          sx={{
            p: 0,
            width: size,
            height: size,
          }}
          onClick={onClick}
        >
          <Iconify icon="mdi:close" />
        </IconButton>
      ) : (
        value && (
          <Typography color="text.primary" component="div" variant="caption">{`${Math.round(
            value
          )} ${unit}`}</Typography>
        )
      )}
    </Box>
  </Box>
);
