import {
  RankingBaseModel,
  RankingMatchDetailModel,
  TeamRoundStatPlayer,
  UserDemoRoundResponse,
} from '@rankacy/services';
import i18next from 'i18next';
import { GAME_FACTORS_MAP, MATCH_HALF_TIME_ROUND, MatchFactorProps } from './types';
import { LinearProgressProps, Palette } from '@mui/material';

type RankingsWithoutNestedObjects = Omit<
  RankingMatchDetailModel,
  'ranking_current_average' | 'quantitative_score_ranking'
>;

/**
 * Prepare data for the match factors card.
 * @param ranking
 * @returns An array of match factor props.
 */
export const getGameFactors = (ranking?: RankingMatchDetailModel) =>
  Object.entries(GAME_FACTORS_MAP).map(
    ([key, { reverseScoreRating, scorePrefix, maxValue }]): MatchFactorProps => ({
      maxProgressValue: maxValue,
      title: i18next.t(key),
      newScore: ranking?.[key as keyof RankingsWithoutNestedObjects] || 0,
      averageScore: ranking?.ranking_current_average?.[key as keyof RankingBaseModel] || 0,
      reverseScoreRating,
      scorePrefix,
    })
  );

export const getMatchRounds = (rounds?: UserDemoRoundResponse, playerName?: string) => {
  const mapToTeam = (team: TeamRoundStatPlayer) => ({
    isCt: team.is_ct,
    isT: team.is_t,
    isWinner: team.is_winner,
    score: team.end_score,
    economy: team.economy_team_round_stat.money_after_buy,
    players: team.player_round_stats.map((player) => ({
      isAlive: player.is_alive,
      name: player.player_name,
      weapon: player.player_round_inventory_stats?.find((inv) => inv.is_main)?.alias || '',
      isSelectedPlayer: player.player_name === playerName,
    })),
  });

  const matchRounds = rounds?.round_stats.map((round) => {
    const team1 = mapToTeam(
      round.team_round_stats[round.number > MATCH_HALF_TIME_ROUND - 1 ? 0 : 1]
    );
    const team2 = mapToTeam(
      round.team_round_stats[round.number > MATCH_HALF_TIME_ROUND - 1 ? 1 : 0]
    );

    return { roundNumber: round.number, roundEndType: round.round_end_type, team1, team2 };
  });

  return matchRounds;
};

export const getRoundEndIcon = (roundEndType: string | undefined | null) => {
  if (roundEndType === 'BOMB_DETONATED') {
    return 'mdi:bomb';
  }
  if (roundEndType === 'BOMB_DEFUSED') {
    return 'mdi:content-cut';
  }
  if (roundEndType === 'TARGET_SAVED') {
    return 'mdi:timer-cancel';
  }

  return 'mdi:skull-crossbones';
};

export const getComparisonIcon = (comparison?: 'less' | 'bigger' | 'equal') => {
  switch (comparison) {
    case 'less':
      return 'mdi:chevron-down';
    case 'bigger':
      return 'mdi:chevron-up';
    default:
      return 'mdi:minus';
  }
};

export const getComparisonColor = (comparison?: 'less' | 'bigger' | 'equal') => {
  switch (comparison) {
    case 'less':
      return 'error.main';
    case 'bigger':
      return 'primary.main';
    default:
      return 'text.primary';
  }
};

export const getComparisonProgressColor = (
  comparison: 'less' | 'bigger' | 'equal'
): LinearProgressProps['color'] => {
  switch (comparison) {
    case 'less':
      return 'error';
    case 'bigger':
      return 'primary';
    default:
      return 'inherit';
  }
};

export const getScoreComparison = (
  newScore: number,
  averageScore: number,
  reverseScoreRating?: boolean
): 'less' | 'bigger' | 'equal' => {
  if (reverseScoreRating) {
    if (newScore < averageScore) return 'bigger';
    if (newScore > averageScore) return 'less';
  } else {
    if (newScore < averageScore) return 'less';
    if (newScore > averageScore) return 'bigger';
  }

  return 'equal';
};

export const normalize = (value: number, max: number, reverseScoreRating = false) =>
  ((reverseScoreRating ? 100 - value : value) / max) * 100;

export const getResultColor = (result: string, palette?: Palette) => {
  switch (result) {
    case 'VICTORY':
      return palette ? palette.primary.main : 'primary.main';
    case 'LOSS':
      return palette ? palette.error.main : 'error.main';
    default:
      return palette ? palette.text.primary : 'text.primary';
  }
};
