import { Box } from '@mui/material';
import { ResponsiveContainer } from 'recharts';
import EmptyChart from './empty-chart';

type Props = {
  height: number;
  children: JSX.Element;
  minDataLength?: number;
  dataLength?: number;
  disableEmptyContent?: boolean;
  emptyContentLabel?: string;
};

export const ChartWrapper = ({
  children,
  height,
  dataLength,
  minDataLength = 2,
  disableEmptyContent = false,
  emptyContentLabel,
}: Props) => {
  const hasEnoughDataPoints = dataLength && dataLength >= minDataLength;

  if (!hasEnoughDataPoints) {
    return !disableEmptyContent && <EmptyChart height={height} label={emptyContentLabel} />;
  }

  return (
    <Box
      sx={{
        width: 1,
        minHeight: 50,
        height: height ?? '100%',
      }}
    >
      <ResponsiveContainer height="100%" width="100%">
        {children}
      </ResponsiveContainer>
    </Box>
  );
};
