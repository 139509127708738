import { Stack, Typography } from '@mui/material';
import { useLocales } from '@rankacy/intl';
import { pxToRem } from '@rankacy/theme';
import React from 'react';
import { Trans } from 'react-i18next';

export const WhatNextSection = () => {
  const { t } = useLocales();

  return (
    <Stack gap={2} sx={{ pl: { xs: 6, sm: 10 } }}>
      <Typography fontWeight="500" variant="h6">
        {t('landing_page.next')}
      </Typography>

      <Typography
        color="text.secondary"
        fontWeight="500"
        sx={{ maxWidth: { xs: '100%', lg: pxToRem(600) } }}
        variant="h4"
      >
        <Trans
          components={{
            span: (
              <Typography color="primary.main" component="span" fontWeight="inherit" variant="h4" />
            ),
          }}
          i18nKey={'landing_page.coming_soon_description'}
        />
      </Typography>
    </Stack>
  );
};
