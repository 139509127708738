import {
  useGetSteamCodeApiUserSteamGetCodesGetQuery,
  useUserMatchCountApiDemosUserMatchCountGetQuery,
} from '@rankacy/services';

export const useSteamConnectionAndGamesStatus = () => {
  const {
    data,
    status: gamesStatus,
    isLoading: isLoadingGames,
  } = useUserMatchCountApiDemosUserMatchCountGetQuery();

  const {
    data: steamConnectionInfo,
    status: steamConnectionInfoStatus,
    isLoading: isLoadingSteamConnectionInfo,
  } = useGetSteamCodeApiUserSteamGetCodesGetQuery();

  const anyGamesCount = data?.all_games;
  const ownGamesCount = data?.my_games;

  return {
    isConnectedToSteam: !!steamConnectionInfo?.steam_id,
    isConnectedToSteamWithCodes:
      !!steamConnectionInfo?.steam_id &&
      !!steamConnectionInfo?.match_code &&
      !!steamConnectionInfo?.auth_code,
    hasAnyGames: !!anyGamesCount,
    hasOwnGames: !!ownGamesCount,
    statuses: [steamConnectionInfoStatus, gamesStatus],
    isLoading: isLoadingGames || isLoadingSteamConnectionInfo,
    autoDownloadCodesProvided:
      !!steamConnectionInfo?.match_code && !!steamConnectionInfo?.auth_code,
  };
};
