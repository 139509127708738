import { Dialog, DialogContent, DialogProps } from '@mui/material';
import React from 'react';

import { SteamAutoDownloadConnectionView } from './view/steam-autodownload-connection-view';

type Props = { onClose: VoidFunction; onBack?: VoidFunction } & DialogProps;

export const SteamAutoDownloadConnectionDialog = ({ open, onClose, onBack, ...other }: Props) => (
  <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} {...other}>
    <DialogContent
      sx={{
        background: 'url(/assets/background/steam_connect_pro_dialog_overlay.webp)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
        textAlign: 'center',
        px: { xs: 2, lg: 8 },
        py: { xs: 4, lg: 6 },
      }}
    >
      <SteamAutoDownloadConnectionView onClose={onClose} />
    </DialogContent>
  </Dialog>
);
