import {
  alpha,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useLocales } from '@rankacy/intl';
import { RouterLink } from '@rankacy/routing';
import { pxToRem } from '@rankacy/theme';
import React from 'react';
import { paths } from '../../routes/paths';

const MOCKED_EVENTS = [
  { round: 3, time: '/01:14', type: 'single_kill', score: 15, victim: 'ruzzzz', checked: true },
  { round: 4, time: '/1:16', type: 'single_kill', score: 13, victim: 'ruzzzz', checked: true },
  {
    round: 9,
    time: '/00:52',
    type: 'single_kill',
    score: 16,
    victim: 'el pana eduardo',
    checked: true,
  },
  { round: 2, time: '/1:12', type: 'single_kill', score: 13, victim: 'ruzzzz', checked: false },
  {
    round: 2,
    time: '/1:44',
    type: 'single_kill',
    score: 13,
    victim: 'nickname 99',
    checked: false,
  },
];

const cellStyleOverrides = { sx: { px: 0.5, py: 0.2 } };

const MOCKED_HIGHLIGHT = '4ce3d40b0b10534e6d7bf2aef7e8a90d2c4f112109cb3c4840aefc14f85f3625';

export const GenerateHighlightTable = () => {
  const { t } = useLocales();

  return (
    <Card sx={{ maxWidth: { xs: 1, sm: pxToRem(410) }, mx: 'auto', width: 1 }} variant="outlined">
      <CardContent sx={{ px: { xs: 2, sm: 3 }, py: 3 }}>
        <Typography variant="h6">match_inferno.dem</Typography>
        <Typography color="text.secondary" fontWeight={500}>
          {t('min_ago', { count: 5 })}
        </Typography>

        <TableContainer>
          <Table sx={{ mt: 1 }}>
            <TableBody>
              <TableRow />
              {React.Children.toArray(
                MOCKED_EVENTS.map(({ round, type, victim, score, time, checked }) => (
                  <TableRow>
                    <TableCell sx={{ ...cellStyleOverrides.sx, pl: 0 }}>
                      <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Checkbox defaultChecked={checked} size="small" />
                        <Typography fontWeight={'bold'}>{round}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell {...cellStyleOverrides}>{time}</TableCell>
                    <TableCell {...cellStyleOverrides}>
                      <Chip
                        color="success"
                        label={t(type)}
                        sx={{
                          height: 25,
                          bgcolor: ({ palette }) => alpha(palette.primary.main, 0.24),
                          color: 'primary.main',
                        }}
                        variant="soft"
                      />
                    </TableCell>
                    <TableCell {...cellStyleOverrides}>
                      <Typography fontWeight="bold">{score}</Typography>
                    </TableCell>
                    <TableCell {...cellStyleOverrides}>
                      <Typography color="text.secondary" sx={{ textWrap: 'wrap' }}>
                        {victim}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack sx={{ mt: 2 }}>
          <Button
            LinkComponent={RouterLink}
            href={paths.publicHighlight(MOCKED_HIGHLIGHT)}
            sx={{ mx: 'auto' }}
            variant="contained"
          >
            {t('generate')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
