import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { MainLayout } from '../../layouts/main';
import { CompactLayout } from '@rankacy/layouts';

const Page404 = lazy(() => import('../../pages/404'));
const Page403 = lazy(() => import('../../pages/403'));
const Page500 = lazy(() => import('../../pages/500'));
const GeneralErrorPage = lazy(() => import('../../pages/general-error'));

const HomePage = lazy(() => import('../../pages/main/home'));
// const AboutUsPage = lazy(() => import('../../pages/main/about-us'));

const PrivacyPolicyPage = lazy(() => import('../../pages/main/privacy-policy'));
const TermsAndConditionsPage = lazy(() => import('../../pages/main/terms-and-conditions'));

const HighlightPage = lazy(() => import('../../pages/main/highlight-detail'));

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      { element: <HomePage />, index: true },
      // { path: 'about-us', element: <AboutUsPage /> },
      { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
      { path: 'terms-and-conditions', element: <TermsAndConditionsPage /> },
      {
        path: 'highlight/:hash',
        element: <HighlightPage />,
      },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      { path: '500', element: <Page500 /> },
      { path: 'general-error', element: <GeneralErrorPage /> },
    ],
  },
];
