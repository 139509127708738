import { m } from 'framer-motion';
import { Box, Link, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useLocales } from '@rankacy/intl';
import { HEADER } from '../config-layout';
import { useFeature } from '@rankacy/feature-flags';
import { useSteamConnectionAndGamesStatus } from '../../sections/steam/hooks';
import { varFade } from '@rankacy/components';

export const GlobalAnnouncement = () => {
  const { t } = useLocales();

  const isGiveawayRunning = useFeature('VITE_FEATURE_GIVEAWAY').on;

  const { isConnectedToSteamWithCodes, isLoading } = useSteamConnectionAndGamesStatus();

  const renderGiveawayText = (
    <AnnouncementText>
      {`You're in alpha testing – bugs ahead! 🐞 BIG SKINS GIVEAWAY IS LIVE, WORTH €850 - `}
      <Link
        color="grey.800"
        href="https://x.com/rankacy"
        rel="noopener noreferr"
        sx={{ textDecoration: 'underline' }}
        target="_blank"
      >
        GET DETAILS!
      </Link>{' '}
      🎯
    </AnnouncementText>
  );

  const renderGenericText = (
    <AnnouncementText>
      {/* TMP */}
      {t('global_announcement.faceit_auto')}
      {/* TMP */}
      {/* {t('global_announcement.before_link')}
      <Link
        color="grey.800"
        href="https://discord.gg/WUyQbwbHaJ"
        rel="noopener noreferr"
        sx={{ textDecoration: 'underline', fontWeight: '900' }}
        target="_blank"
      >
        Discord
      </Link>

      {t('global_announcement.after_link')} */}
    </AnnouncementText>
  );

  const renderSteamConnectionText = (
    <AnnouncementText>{t('global_announcement.steam_connection')}</AnnouncementText>
  );

  if (isLoading) {
    return null;
  }

  return (
    <Box
      {...varFade().in}
      component={m.div}
      sx={{
        width: 1,
        height: HEADER.H_ANNOUNCEMENT,
        bgcolor: isConnectedToSteamWithCodes ? 'primary.main' : 'warning.main',
        textAlign: 'center',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
      }}
    >
      {!isConnectedToSteamWithCodes && renderSteamConnectionText}

      {isConnectedToSteamWithCodes && isGiveawayRunning && renderGiveawayText}

      {isConnectedToSteamWithCodes && !isGiveawayRunning && renderGenericText}
    </Box>
  );
};

const AnnouncementText = ({ children }: PropsWithChildren) => (
  <Typography color="grey.800" sx={{ fontSize: { xs: 11, md: 13 } }} variant="overline">
    {children}
  </Typography>
);
